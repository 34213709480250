(function ($) {
    //Create Odd Movie Copyright
    $('.add-series-movie-copyright .btn-create-movie').click(function (e){
        e.preventDefault();
        let trailerUrl = $('.add-series-movie-copyright .upload-video-step-2 input[name="trailerUrl"]').val();
        if (trailerUrl != '')
        {
            if($('.form-create-movie').valid() == true) {
                $('.btn-create-movie').attr('disabled', 'disabled');
                $('.form-create-movie').submit();
            }
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Trailer chưa được chọn hoặc Đang tải Trailer.',
            });
        }
    });
    $('.add-series-movie .form-create-movie').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('.btn-create-movie').removeAttr('disabled');
            $('.btn-edit-movie').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.page-add-movie input[name="originUrl"]').val('');
                $('.page-add-movie input[name="previewUrl"]').val('');
                $('.page-add-movie input[name="uploadId"]').val('');
                $('.page-add-movie input[name="duration"]').val('');
                $('.page-add-movie input[name="nameFile"]').val('');
                $('.page-add-movie input[name="trailerUrl"]').val('');
                $('.page-add-movie input[name="durationTrailer"]').val('');
                $('.page-add-movie input[name="nameFileTrailer"]').val('');
                $('.page-add-movie input[name="uploadIdTrailer"]').val('');
                $('#addnew_cast').modal('hide');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
})(jQuery);
