(function ($) {
    $(document).on('change', '.upload-review-movie #numReview',function () {
        let $this = $(this);
        let num = $this.val();
        if (num == 1)
        {
            $('.ajax__search-casing-review').css('display', 'none');
            $('.btn--add-review').css('display', 'none');
            $('.ajax-review__search-movie').css('display', 'block');
            $('.list--add-video').html('');
            $('.box-list--add-film').css('display', 'block');
            $('.icon__delete_box').css('display', 'none');
            $('.icon__add_form-film-review').css('display', 'none');
            $('.box-clone-add-film .row').clone().appendTo('.list--add-video');
            $('input[name="movie_ID"]').val('');
            $('input[name="reviewId"]').val('');
            $('.list--add-video .thumb-upload').html('');
            $('.list--add-video .thumb-upload').html('<input name="add_img" type="file" class="video-thumb-upload" data-allowed-file-extensions="png jpg gif tiff jpeg bmp" data-show-remove="true" required data-msg="Chưa chọn ảnh.">');
            $('.video-thumb-upload').dropify({
                messages: {
                    'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                    'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                    'remove':  'Hủy',
                    'error':   'Xin lỗi có lỗi đã xảy ra'
                },
                error: {
                    'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                    'minWidth': 'The image width is too small ({{ value }}}px).',
                    'maxWidth': 'The image width is too big ({{ value }}}px).',
                    'minHeight': 'The image height is too small ({{ value }}}px).',
                    'maxHeight': 'The image height is too big ({{ value }}px).',
                    'imageFormat': 'Định dạng không đúng ({{ value }}).',
                    'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
                },
            });
        }else{
            $('.ajax-review__search-movie').css('display', 'none');
            $('.ajax__search-casing-review').css('display', 'block');
            $('.btn--add-review').css('display', 'inline-block');
            $('.box-list--add-film').css('display', 'block');
            $('.icon__delete_box').css('display', 'block');
            $('.icon__add_form-film-review').css('display', 'block');
            $('input[name="movie_ID"]').val('');
            $('input[name="reviewId"]').val('');
        }
    });
    $(document).on('change', '.upload-review-movie #review_movieID',function () {
        let $this = $(this);
        let movie = $this.val();
        if (movie && movie.length > 0)
        {
            $('.list--add-video').html('');
            $('.box-clone-add-film .row').clone().appendTo('.list--add-video');
            $('.box-list--add-film').css('display', 'block');
            // $('.icon__delete_box').css('display', 'none');
            // $('.icon__add_form-film-review').css('display', 'none');
            $('input[name="movie_ID"]').val(movie);
            $('input[name="reviewId"]').val('');
            $('.list--add-video .thumb-upload').html('');
            $('.list--add-video .thumb-upload').html('<input name="add_img" type="file" class="video-thumb-upload" data-allowed-file-extensions="png jpg gif tiff jpeg bmp" data-show-remove="true" required data-msg="Chưa chọn ảnh.">');
            $('.video-thumb-upload').dropify({
                messages: {
                    'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                    'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                    'remove':  'Hủy',
                    'error':   'Xin lỗi có lỗi đã xảy ra'
                },
                error: {
                    'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                    'minWidth': 'The image width is too small ({{ value }}}px).',
                    'maxWidth': 'The image width is too big ({{ value }}}px).',
                    'minHeight': 'The image height is too small ({{ value }}}px).',
                    'maxHeight': 'The image height is too big ({{ value }}px).',
                    'imageFormat': 'Định dạng không đúng ({{ value }}).',
                    'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
                },
            });
        }else{
            // $('.box-list--add-film').css('display', 'none');
            // $('.icon__delete_box').css('display', 'none');
            $('.icon__add_form-film-review').css('display', 'none');
            $('input[name="movie_ID"]').val('');
            $('input[name="reviewId"]').val('');
        }
    });
    $(document).on('change', '.upload-review-movie .select-movie__add-review .js-data-review-casing',function () {
        let $this = $(this);
        let review = $this.val();
        if (review && review.length > 0)
        {
            $('.list--add-video').html('');
            $('.box-clone-add-film .row').clone().appendTo('.list--add-video');
            $('.box-list--add-film').css('display', 'block');
            $('.icon__delete_box').css('display', 'block');
            $('.icon__add_form-film-review').css('display', 'block');
            $('input[name="reviewId"]').val(review);
            $('input[name="movie_ID"]').val('');
            $('.list--add-video .thumb-upload').html('');
            $('.list--add-video .thumb-upload').html('<input name="add_img" type="file" class="video-thumb-upload" data-allowed-file-extensions="png jpg gif tiff jpeg bmp" data-show-remove="true" required data-msg="Chưa chọn ảnh.">');
            $('.video-thumb-upload').dropify({
                messages: {
                    'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                    'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                    'remove':  'Hủy',
                    'error':   'Xin lỗi có lỗi đã xảy ra'
                },
                error: {
                    'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                    'minWidth': 'The image width is too small ({{ value }}}px).',
                    'maxWidth': 'The image width is too big ({{ value }}}px).',
                    'minHeight': 'The image height is too small ({{ value }}}px).',
                    'maxHeight': 'The image height is too big ({{ value }}px).',
                    'imageFormat': 'Định dạng không đúng ({{ value }}).',
                    'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
                },
            });
        }else{
            $('.box-list--add-film').css('display', 'none');
            $('.icon__delete_box').css('display', 'none');
            $('.icon__add_form-film-review').css('display', 'none');
            $('input[name="reviewId"]').val('');
            $('input[name="movie_ID"]').val('');
        }
    });
    $(document).on('click', '.icon__add_form-film-review', function (e) {
        e.preventDefault();
        $('.box-clone-add-film .thumb-upload').html('');
        $('.box-clone-add-film .thumb-upload').html('<input name="add_img" type="file" class="video-thumb-upload" data-allowed-file-extensions="png jpg gif tiff jpeg bmp" data-show-remove="true" required data-msg="Chưa chọn ảnh.">');
        $('.box-clone-add-film .row').clone().appendTo('.list--add-video');
        $('.video-thumb-upload').dropify({
            messages: {
                'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                'remove':  'Hủy',
                'error':   'Xin lỗi có lỗi đã xảy ra'
            },
            error: {
                'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                'minWidth': 'The image width is too small ({{ value }}}px).',
                'maxWidth': 'The image width is too big ({{ value }}}px).',
                'minHeight': 'The image height is too small ({{ value }}}px).',
                'maxHeight': 'The image height is too big ({{ value }}px).',
                'imageFormat': 'Định dạng không đúng ({{ value }}).',
                'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
            },
        });
    });
    // Upload Review JS thuần sử dụng axios
    const CancelToken = axios.CancelToken;
    let cancelUploadReviewMovie;
    $(document).on('change', '.upload-review-movie .upload-video-step-1 .video_file',function (e) {
        let $this = $(this);
        $this.attr('disabled', 'disabled');
        $this.parents('.form__upload').find('.name--file').html($this[0].files[0]['name']);
        $this.parents('.form__upload').find('input[name="uploadId"]').val('');
        $this.parents('.form__upload').find('input[name="originUrl"]').val('');
        $this.parents('.form__upload').find('input[name="duration"]').val('');
        $this.parents('.form__upload').find('input[name=nameFile]').val('');

        // Get Duration Video
        document.getElementById('audio').setAttribute('src', '');
        let file = e.currentTarget.files[0];
        let obUrl = URL.createObjectURL(file);
        document.getElementById('audio').setAttribute('src', obUrl);
        console.log('file');

        let f_duration = 0;
        document.getElementById('audio').addEventListener('canplaythrough', function(f){
            f_duration = Math.round(f.currentTarget.duration);
            console.log(f_duration);
            $this.parents('.form__upload').find('input[name=duration]').val(f_duration);
        });
        //End

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/upload-review-movie/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if(res.success) {
                    $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                    $('.btn-create-review-movie').addClass('loading');

                    console.log($this[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $this[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.form__upload').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.form__upload').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.form__upload').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.form__upload').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelUploadReviewMovie = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('Upload SUCCESS!!');
                        console.log(data.data);
                        $('.btn-create-review-movie').removeClass('loading');
                        let item = data.data;
                        if (item.code == 200){
                            $this.removeAttr('disabled');
                            $this.parents('.form__upload').find('.progress .progress-bar').css('width','100%');
                            $this.parents('.form__upload').find('.progress .progress-bar').text('Done!');

                            $this.parents('.form__upload').find('input[name="uploadId"]').val(item.data.contentId);
                            $this.parents('.form__upload').find('input[name="originUrl"]').val(item.data.url);
                            // $this.parents('.form__upload').find('input[name="duration"]').val(item.data.duration);
                            $this.parents('.form__upload').find('input[name=nameFile]').val(item.data.fileName);
                            $this.parents('.form__upload').find('.btn-upload-show-video').attr('data-originalUrl', item.data.url);
                            $this.parents('.form__upload').find('.btn-upload-show-video').css('display', 'block');
                            $this.removeClass('cancel');

                        }else {
                            $this.removeAttr('disabled');
                            $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                            $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                            $this.parents('.form__upload').find('.name--file').html('');
                            $this.parents('.form__upload .upload-video-step-1').find('form input[type="file"]').val('');
                            $this.removeClass('cancel');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $('.btn-create-review-movie').removeClass('loading');
                        $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                        $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                        $this.parents('.form__upload').find('.name--file').html('');
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                        $this.val('');
                        $this.removeClass('cancel');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                    $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                    $this.parents('.form__upload').find('.name--file').html('');
                    $this.val('');
                    $this.removeClass('cancel');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    $(document).on('click','.upload-review-movie .upload--cancel',function () {
        let $this = $(this);
        let contentId = $this.parents('.form__upload').find('input[name=uploadId]').val();
        let fileName = $this.parents('.form__upload').find('input[name=nameFile]').val();
        console.log(contentId);
        console.log(fileName);
        if (fileName && fileName.length > 0){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/odd-movie/delete-file',{
                type: 'POST',
                dataType: 'json',
                data: {
                    contentId: contentId,
                    fileName: fileName,
                },
                success:function (data){
                    console.log(data);
                    $('.footer .overlay').removeClass('overlaing');
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    }else{
                        $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').addClass('cancel');
                        cancelUploadReviewMovie();
                        $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                        $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                        $this.parents('.form__upload').find('.name--file').html('');
                        $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').val('');

                        $this.parents('.form__upload').find(' input[name="originUrl"]').val('');
                        $this.parents('.form__upload').find(' input[name="uploadId"]').val('');
                        $this.parents('.form__upload').find(' input[name="duration"]').val('');
                        $this.parents('.form__upload').find(' input[name="nameFile"]').val('');

                        $this.parents('.form__upload').find('.btn-upload-show-video').attr('data-originalUrl', '');
                        $this.parents('.form__upload').find('.btn-upload-show-video').css('display', 'none');
                        Swal.fire({
                            type: 'warning',
                            text: 'Luồng upload review đã bị hủy. Vui lòng tạo luồng mới!',
                        });
                    }
                }
            });
        }else{
            $('.footer .overlay').removeClass('overlaing');
            $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').addClass('cancel');
            cancelUploadReviewMovie();
            $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
            $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
            $this.parents('.form__upload').find('.name--file').html('');
            $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').val('');

            $this.parents('.form__upload').find(' input[name="originUrl"]').val('');
            $this.parents('.form__upload').find(' input[name="uploadId"]').val('');
            $this.parents('.form__upload').find(' input[name="duration"]').val('');
            $this.parents('.form__upload').find(' input[name="nameFile"]').val('');

            $this.parents('.form__upload').find('.btn-upload-show-video').attr('data-originalUrl', '');
            $this.parents('.form__upload').find('.btn-upload-show-video').css('display', 'none');
            Swal.fire({
                type: 'warning',
                text: 'Luồng upload review đã bị hủy. Vui lòng tạo luồng mới!',
            });
        }
    });

    // Delete file video uploaded when load
    window.onbeforeunload = function(){
        let hasClass = document.getElementsByClassName("unload--delEpisodeMovie");
        if(hasClass.length){
            $('.box-list--add-film .list--add-video .form__upload').each(function( index ) {
                let $this = $(this);
                let contentId = $this.find('.upload-video-step-2 input[name="uploadId"]').val();
                let fileName = $this.find('.upload-video-step-2 input[name="nameFile"]').val();
                console.log(contentId);
                console.log(fileName);
                if (fileName && fileName.length > 0){
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/odd-movie/delete-file',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            contentId: contentId,
                            fileName: fileName,
                        },
                        success:function (data){
                            console.log(data);
                            $('.footer .overlay').removeClass('overlaing');
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                });
                            }else{
                                $this.find('.upload-video-step-1 form input[type="file"]').addClass('cancel');
                                $this.find('.progress .progress-bar').css('width','0%');
                                $this.find('.progress .progress-bar').html('0%');
                                $this.find('.name--file').html('');
                                $this.find('.upload-video-step-2 input[name=originUrl]').val('');
                                $this.find('.upload-video-step-2 input[name=previewUrl]').val('');
                                $this.find('.upload-video-step-2 input[name=duration]').val('');
                                $this.find('.upload-video-step-2 input[name=uploadId]').val('');
                                $this.find('.upload-video-step-2 input[name=nameFile]').val('');

                                $this.find('.btn-upload-show-video').attr('data-originalUrl', '');
                                $this.find('.btn-upload-show-video').css('display', 'none');
                                $this.find('.upload-video-step-1 form input[type="file"]').val('');
                                Swal.fire({
                                    type: 'warning',
                                    text: 'Luồng upload review đã bị hủy. Vui lòng tạo luồng mới!',
                                });
                            }
                        }
                    });
                }
            });
        }
    };

    // Form Ajax Add file review
    $('.upload-review-movie .form-add-review').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('.form-btn-add-review').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    // Form Ajax Create Review Casing
    $('#form-create-review-casing').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('.form-btn-add-review').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    //Search ajax load more
    function formatRepo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.title + "</div></div></div>";
        return markup;
    }
    function formatRepoSelectionMovie (repo) {
        return repo.title || repo.text;
    }
    $('.js-data-movie-review').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/upload-review-movie/get-movie-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.content;
                        $.each(result, function( index, value ) {
                            result['name'] = value['title'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.data.totalElements
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn phim',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionMovie
    });
    function formatRepoSelectionReviewCasing (repo) {
        return repo.title || repo.text;
    }
    $('.js-data-review-casing').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/upload-review-movie/get-review-casing-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    let result = '';
                    let check = false;
                    if (data.data){
                        result = data.data;
                        $.each(result, function( index, value ) {
                            result['name'] = value['title'];
                        });
                        if(result.length == 20){
                            check = true;
                        }
                    }
                    return {
                        results: result,
                        pagination: {
                            more: check
                            // more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn vỏ review',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionReviewCasing
    });
    //Click btn submit form
    $(document).on('click', '.upload-review-movie .btn-create-review-movie', function (e) {
        e.preventDefault();
        $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        let hClass = $(this).hasClass('loading');
        let movieId =  $this.parents('.form__upload').find('input[name="movie_ID"]').val();
        let uploadId =  $this.parents('.form__upload').find('input[name="uploadId"]').val();
        console.log(uploadId.length);
        if (movieId.length == 0)
        {
            $('.footer .overlay').removeClass('overlaing');
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn phim.',
            });
        }
        else if(hClass){
            $('.footer .overlay').removeClass('overlaing');
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Đang tải file.',
            });
        }
        else if(uploadId.length == 0){
            $('.footer .overlay').removeClass('overlaing');
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn tệp review.',
            });
        }
        else{
            $('.footer .overlay').removeClass('overlaing');
            if($this.parents('.form__upload').find('.form-create-review-movie').valid() == true) {
                $('.footer .overlay').addClass('overlaing');
                $this.attr('disabled', 'disabled');
                $this.addClass('operation');
                $this.parents('.form__upload').find('.icon__delete_box').addClass('operation');
                $this.parents('.form__upload').find('.form-create-review-movie').submit();
            }
        }
    });
    $(document).ready(function () {
        $(document).on('submit', '.upload-review-movie .form-create-review-movie', function (e) {
            e.preventDefault();
            let $this = $(this);

            $this.ajaxSubmit({
                success: function(res, statusText, xhr, $form) {
                    console.log(res);
                    let href = window.location.href;
                    if (res.error){
                        $('.footer .overlay').removeClass('overlaing');
                        $('.list--add-video .btn-create-review-movie').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeAttr('disabled');
                            }
                        });
                        $('.list--add-video .icon__delete_box').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeClass('operation');
                            }
                        });
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: res.error,
                        });
                    }
                    else if (res.success) {
                        $('.footer .overlay').removeClass('overlaing');
                        $('.page-add-movie input[name="originUrl"]').val('');
                        $('.page-add-movie input[name="previewUrl"]').val('');
                        $('.page-add-movie input[name="uploadId"]').val('');
                        $('.page-add-movie input[name="duration"]').val('');
                        $('.page-add-movie input[name="nameFile"]').val('');
                        $('.list--add-video .btn-create-review-movie').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).remove();
                            }
                        });
                        $('.list--add-video .icon__delete_box').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).remove();
                            }
                        });
                        Swal.fire(
                            'Thành công!',
                            res.success,
                            'success'
                        );
                    }else {
                        $('.footer .overlay').removeClass('overlaing');
                        $('.list--add-video .btn-create-review-movie').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeAttr('disabled');
                            }
                        });
                        $('.list--add-video .icon__delete_box').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeClass('operation');
                            }
                        });
                        Swal.fire(
                            'Error!',
                            res.logged,
                            'error'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL);
                        });
                    }
                }
            });
        });
    });

})(jQuery);
