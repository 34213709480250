(function ($) {
    $(document).on('click', '.delete_blackWord', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Xóa',
            text: 'Bạn muốn xóa black word?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/black-word/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    $(document).on('change', '#form_upload_black_word .video_file', function (e) {
        e.preventDefault();
        let $this = $(this);
        let href = window.location.href;
        let file = $this[0].files[0];
        $('#add_black_word .name--file').html('');
        $('#add_black_word .error').html('');
        $('#add_black_word .name--file').html(file['name']);
        console.log($this[0].files[0]);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/black-word/token', {
            type: 'GET',
            dataType: 'json',
            data: {},
            success: function (res) {
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                $('#add_black_word .name--file').html('');
                $this.val('');
                if (res.error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                } else {
                    let formData = new FormData();
                    formData.append("file", file);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': res.success
                            },
                        }
                    ).then(function(data){
                        console.log(data);
                        let item = data.data;
                        $('#add_black_word .name--file').html('');
                        $this.val('');
                        if (item.code == 200){
                            Swal.fire(
                                'Thành công!',
                                'Upload file thành công.',
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.val('');
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Lỗi kết nối server',
                        });
                    });
                }
            }
        });
    });
    $('#form-edit-black-word').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-edit-black-word').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Click button edit
    $(document).on('click', '.edit_blackWord', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let key = $(this).attr('data-key');
        let des = $(this).attr('data-des');

        $('#edit_black_word input[name="id"]').val(id);
        $('#edit_black_word input[name="edit_key"]').val(key);
        $('#edit_black_word input[name="edit_des"]').val(des);

        $('#edit_black_word').modal('show');
    });

})(jQuery);
