(function ($) {
    $('.datetimepicker').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
        scrollMonth : false,
        scrollInput : false
    });
    $('.datetimepicker-now').datetimepicker({
        timepicker:false,
        format:'Y/m/d',
        scrollMonth : false,
        scrollInput : false,
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
        minDate:'-1d'
    });

    $('.datetimepicker-datetime').datetimepicker({
        format:'H:i d/m/Y',
        defaultTime:'00:00',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        },
        minDate:'-1d'
    });
})(jQuery);
