(function ($) {
    $(document).on('change', '.add-odd-movie #numReview',function () {
        let num = $(this).val();
        if (num == 1)
        {
            $('.box-upload-file--review').css('display', 'block');
            $('.noti__file_reviews').css('display', 'none');
        }else{
            $('.box-upload-file--review').css('display', 'none');
            $('.noti__file_reviews').css('display', 'block');
        }
    });
    // Upload phim JS thuần sử dụng axios
    const CancelToken = axios.CancelToken;
    let cancelOddMovie;
    let cancelOddMovieT;
    $(document).on('change', '.add-odd-movie .box-upload-file--movie .upload-video-step-1 .video_file',function (e) {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);


        $('.box-upload-file--movie .name--file').html($(".box-upload-file--movie .upload-video-step-1 .video_file")[0].files[0]['name']);
        $this.attr('disabled', 'disabled');
        $('.page-add-movie .upload-video-step-2 input[name=originUrl]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=token_trans]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=previewUrl]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=duration]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=uploadId]').val('');

        // Get Duration Video
        let file = e.currentTarget.files[0];
        let obUrl = URL.createObjectURL(file);
        document.getElementById('audio').setAttribute('src', obUrl);
        console.log('file');

        let f_duration = 0;
        document.getElementById('audio').addEventListener('canplaythrough', function(f){
            f_duration = Math.round(f.currentTarget.duration);
            console.log(f_duration);
            $('.page-add-movie .upload-video-step-2 input[name=duration]').val(f_duration);
        });
        //End

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $('.page-add-movie .upload-video-step-2 input[name=duration]').val('');
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                    // $('#addnew_video').modal('hide');
                }
                else if(res.api) {
                    // let token = res.success;
                    $('.add-odd-movie .btn-create-movie').addClass('uploading');
                    $('.box-upload-file--movie .progress .progress-bar').css('width','0%');
                    console.log($(".box-upload-file--movie .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box-upload-file--movie .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                // 'token': token
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box-upload-file--movie').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box-upload-file--movie').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box-upload-file--movie').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box-upload-file--movie').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelOddMovie = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.add-odd-movie .btn-create-movie').removeClass('uploading');
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $('.box-upload-file--movie .progress .progress-bar').css('width','100%');
                            $('.box-upload-file--movie .progress .progress-bar').text('Done!');
                            $('.page-add-movie .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            // $('.page-add-movie .upload-video-step-2 input[name=token_trans]').val(token);
                            // $('.page-add-movie .upload-video-step-2 input[name=originUrl]').val(item.data.originUrl);
                            // $('.page-add-movie .upload-video-step-2 input[name=previewUrl]').val(item.data.previewUrl);
                            // $('.page-add-movie .upload-video-step-2 input[name=duration]').val(item.data.duration);
                            // $('.page-add-movie .upload-video-step-2 input[name=uploadId]').val(item.data.uploadId);
                            // $('.box-upload-file--movie .btn-upload-show-video').attr('data-originalUrl', item.data.originUrl);
                            $('.page-add-movie .upload-video-step-2 input[name=originUrl]').val(item.data.url);
                            $('.page-add-movie .upload-video-step-2 input[name=previewUrl]').val(item.data.url);
                            $('.page-add-movie .upload-video-step-2 input[name=uploadId]').val(item.data.contentId);
                            $('.page-add-movie .upload-video-step-2 input[name=nameFile]').val(item.data.fileName);
                            $('.box-upload-file--movie .btn-upload-show-video').attr('data-originalUrl', item.data.url);
                            $('.box-upload-file--movie .btn-upload-show-video').css('display', 'block');
                        }else {
                            $('.page-add-movie .upload-video-step-2 input[name=duration]').val('');
                            $this.removeAttr('disabled');
                            $('.box-upload-file--movie .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--movie .progress .progress-bar').html('0%');
                            $('.box-upload-file--movie .name--file').html('');
                            $('.box-upload-file--movie form input[type="file"]').remove();
                            $('.box-upload-file--movie form').append('<input name="file_film" class="video_file" type="file">');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $('.page-add-movie .upload-video-step-2 input[name=duration]').val('');
                        $this.removeAttr('disabled');
                        $('.add-odd-movie .btn-create-movie').removeClass('uploading');
                        $('.box-upload-file--movie .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--movie .progress .progress-bar').html('0%');
                        $('.box-upload-file--movie .name--file').html('');
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                        $('.box-upload-file--movie form input[type="file"]').remove();
                        $('.box-upload-file--movie form').append('<input name="file_film" class="video_file" type="file">');
                    });
                }
                else {
                    $('.page-add-movie .upload-video-step-2 input[name=duration]').val('');
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $('.box-upload-file--movie .progress .progress-bar').css('width','0%');
                    $('.box-upload-file--movie .progress .progress-bar').html('0%');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    // Upload Trailer JS thuần sử dụng axios
    $(document).on('change', '.add-odd-movie .box-upload-file--trailer .upload-video-step-1 .video_file',function (e) {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $('.box-upload-file--trailer .name--file').html($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]['name']);
        $this.attr('disabled', 'disabled');
        $('.page-add-movie input[name="trailerUrl"]').val('');
        $('.page-add-movie input[name="durationTrailer"]').val('');
        $('.page-add-movie input[name="nameFileTrailer"]').val('');
        $('.page-add-movie input[name="uploadIdTrailer"]').val('');

        // Get Duration Video
        let file = e.currentTarget.files[0];
        let obUrl = URL.createObjectURL(file);
        document.getElementById('audioTrailer').setAttribute('src', obUrl);
        console.log('file');

        let f_duration = 0;
        document.getElementById('audioTrailer').addEventListener('canplaythrough', function(f){
            f_duration = Math.round(f.currentTarget.duration);
            console.log(f_duration);
            $('.page-add-movie .upload-video-step-2 input[name=durationTrailer]').val(f_duration);
        });
        //End

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if(res.success) {
                    $('.add-odd-movie .btn-create-movie').addClass('uploading');
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    console.log($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelOddMovieT = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.add-odd-movie .btn-create-movie').removeClass('uploading');
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            // $this.removeAttr('disabled');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','100%');
                            $('.box-upload-file--trailer .progress .progress-bar').text('Done!');
                            $('.page-add-movie .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.page-add-movie .upload-video-step-2 input[name=trailerUrl]').val(item.data.url);
                            $('.page-add-movie .upload-video-step-2 input[name=nameFileTrailer]').val(item.data.fileName);
                            $('.page-add-movie .upload-video-step-2 input[name=uploadIdTrailer]').val(item.data.contentId);
                            $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', item.data.url);
                            $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'block');
                        }else {
                            $this.removeAttr('disabled');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                            $('.box-upload-file--trailer .name--file').html('');
                            $('.box-upload-file--trailer form input').remove();
                            $('.box-upload-file--trailer form').html('<input name="file_trailer" class="video_file" type="file">');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $('.add-odd-movie .btn-create-movie').removeClass('uploading');
                        $this.removeAttr('disabled');
                        $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                        $('.box-upload-file--trailer .name--file').html('');
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                        $('.box-upload-file--trailer form input[type="file"]').remove();
                        $('.box-upload-file--trailer form').append('<input name="file_trailer" class="video_file" type="file">');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    // Upload Review JS thuần sử dụng axios
    $(document).on('change', '.add-odd-movie .box-upload-file--review .upload-video-step-1 .video_file',function () {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $('.box-upload-file--review .name--file').html($(".box-upload-file--review .upload-video-step-1 .video_file")[0].files[0]['name']);
        $this.attr('disabled', 'disabled');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else {
                    $('.box-upload-file--review .progress .progress-bar').css('width','0%');
                    console.log($(".box-upload-file--review .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box-upload-file--review .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box-upload-file--review').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box-upload-file--review').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box-upload-file--review').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box-upload-file--review').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            }
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $this.removeAttr('disabled');
                            $('.box-upload-file--review .progress .progress-bar').css('width','100%');
                            $('.box-upload-file--review .progress .progress-bar').text('Done!');
                            $('.page-add-movie .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.page-add-movie .upload-video-step-2 input[name=reviewUrl]').val(item.data.originUrl);
                            $('.box-upload-file--review .btn-upload-show-video').attr('data-originalUrl', item.data.originUrl);
                            $('.box-upload-file--review .btn-upload-show-video').css('display', 'block');
                        }else {
                            $this.removeAttr('disabled');
                            $('.box-upload-file--review .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--review .progress .progress-bar').html('0%');
                            $('.box-upload-file--review .name--file').html('');
                            $('.box-upload-file--review form input').remove();
                            $('.box-upload-file--review form').html('<input name="file_review" class="video_file" type="file">');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $('.box-upload-file--review .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--review .progress .progress-bar').html('0%');
                        $('.box-upload-file--review .name--file').html('');
                        $('.box-upload-file--review form input').remove();
                        $('.box-upload-file--review form').html('<input name="file_review" class="video_file" type="file">');
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Lỗi kết lối server!',
                        });
                        console.log('FAILURE!!');
                    });
                }
            }
        });
    });

    function resetFormMovie(){
        $('.box-upload-file--movie form input[type="file"]').addClass('cancel');
        cancelOddMovie();
        $('.box-upload-file--movie .progress .progress-bar').css('width','0%');
        $('.box-upload-file--movie .progress .progress-bar').html('0%');
        $('.box-upload-file--movie .name--file').html('');
        $('.box-upload-file--movie form input[type="file"]').remove();
        $('.box-upload-file--movie form').append('<input name="file_video" class="video_file" type="file">');

        $('.page-add-movie input[name="originUrl"]').val('');
        $('.page-add-movie input[name="previewUrl"]').val('');
        $('.page-add-movie input[name="uploadId"]').val('');
        $('.page-add-movie input[name="duration"]').val('');
        $('.page-add-movie input[name="nameFile"]').val('');
        $('.box-upload-file--movie .btn-upload-show-video').attr('data-originalUrl', '');
        $('.box-upload-file--movie .btn-upload-show-video').css('display', 'none');
        Swal.fire({
            type: 'warning',
            text: 'Luồng upload đã bị hủy. Vui lòng tạo luồng mới!',
        });
    }
    function resetFormTrailer(){
        $('.box-upload-file--trailer form input[type="file"]').addClass('cancel');
        cancelOddMovieT();
        $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
        $('.box-upload-file--trailer .progress .progress-bar').html('0%');
        $('.box-upload-file--trailer .name--file').html('');
        $('.box-upload-file--trailer form input[type="file"]').remove();
        $('.box-upload-file--trailer form').append('<input name="file_video" class="video_file" type="file">');

        $('.page-add-movie input[name="trailerUrl"]').val('');
        $('.page-add-movie input[name="durationTrailer"]').val('');
        $('.page-add-movie input[name="nameFileTrailer"]').val('');
        $('.page-add-movie input[name="uploadIdTrailer"]').val('');

        $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', '');
        $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'none');

        Swal.fire({
            type: 'warning',
            text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
        });
    }
    function ajaxDeleteMovie(contentId, fileName, resetForm){
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/delete-file',{
            type: 'POST',
            dataType: 'json',
            data: {
                contentId: contentId,
                fileName: fileName,
            },
            success:function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }else{
                    resetForm();
                }
            }
        });
    }
    $(document).on('click','.add-odd-movie .box-upload-file--movie .upload--cancel',function () {
        $('.footer .overlay').addClass('overlaing');
        let contentId = $('.add-odd-movie .upload-video-step-2 input[name="uploadId"]').val();
        let fileName = $('.add-odd-movie .upload-video-step-2 input[name="nameFile"]').val();
        console.log(contentId);
        console.log(fileName);
        if (fileName && fileName.length > 0){
            ajaxDeleteMovie(contentId, fileName, resetFormMovie);
        }else{
            $('.footer .overlay').removeClass('overlaing');
            resetFormMovie();
        }
    });
    $(document).on('click','.add-odd-movie .box-upload-file--trailer .upload--cancel',function () {
        $('.footer .overlay').addClass('overlaing');
        let contentId = $('.add-odd-movie .upload-video-step-2 input[name="uploadIdTrailer"]').val();
        let fileName = $('.add-odd-movie .upload-video-step-2 input[name="nameFileTrailer"]').val();
        console.log(contentId);
        console.log(fileName);
        if (fileName && fileName.length > 0){
            ajaxDeleteMovie(contentId, fileName, resetFormTrailer);
        }else{
            $('.footer .overlay').removeClass('overlaing');
            resetFormTrailer();
        }
    });
    $(document).on('click','.add-odd-movie .box-upload-file--review .upload--cancel',function () {
        $('.box-upload-file--review .progress .progress-bar').css('width','0%');
        $('.box-upload-file--review .progress .progress-bar').html('0%');
        $('.box-upload-file--review .name--file').html('');
        $('.box-upload-file--review form input').remove();
        $('.page-add-movie input[name="reviewUrl"]').val('');
        $('.box-upload-file--review form').html('<input name="file_video" class="video_file" type="file">');

        $('.box-upload-file--review .btn-upload-show-video').attr('data-originalUrl', '');
        $('.box-upload-file--review .btn-upload-show-video').css('display', 'none');

        Swal.fire({
            type: 'warning',
            text: 'Luồng upload Review đã bị hủy. Vui lòng tạo luồng mới!',
        });
    });

    // Delete file video uploaded when load
    window.onbeforeunload = function(){
        let hasClass = document.getElementsByClassName("unload--delMovie");
        if(hasClass.length){
            let contentId = $('.add-odd-movie .upload-video-step-2 input[name="uploadId"]').val();
            let fileName = $('.add-odd-movie .upload-video-step-2 input[name="nameFile"]').val();
            let contentIdTrailer = $('.add-odd-movie .upload-video-step-2 input[name="uploadIdTrailer"]').val();
            let fileNameTrailer = $('.add-odd-movie .upload-video-step-2 input[name="nameFileTrailer"]').val();
            console.log(contentId);
            console.log(contentIdTrailer);
            if (fileName && fileName.length > 0){
                ajaxDeleteMovie(contentId, fileName, resetFormMovie);
            }
            if (fileNameTrailer && fileNameTrailer.length > 0){
                ajaxDeleteMovie(contentIdTrailer, fileNameTrailer, resetFormTrailer);
            }
        }
    };

    $(document).on('click', '.icon__add_form-file-track_odd', function (e) {
        e.preventDefault();
        $('.clone-box-upload-file--track .box-upload-file--track').clone().appendTo('.list_box-upload-file--track');
    });
    $(document).on('click', '.icon__delete_box_track_odd', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        console.log(id);
        if (id.length > 0)
        {
            $('.upload-video-step-2 .input-track input').each(function( index ) {
                let idT = $(this).attr('data-id');
                if (idT == id){
                    $(this).remove();
                }
            });
            // $(document).find('.upload-video-step-2 .input-track input[data-id="+id+"]').remove();
        }
        $(this).parents('.box-upload-file--track').remove();
    });
    // Upload Track JS thuần sử dụng axios
    $(document).on('change', '.add-odd-movie .box--track .upload-video-step-1 .video_file',function () {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $this.attr('disabled', 'disabled');
        $this.parents('.box-upload-file--track').find('.name--file').html($this[0].files[0]['name']);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/getTokenTrack',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else {
                    $this.parents('.box-upload-file--track').find('.progress .progress-bar').css('width','0%');
                    console.log($this[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $this[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $this.parents('.box-upload-file--track').find('input[name="urlTrack"]').val(item.data);
                            $this.parents('.box-upload-file--track').find('.btn-add-track').attr('data-id', item.data);
                            $this.parents('.box-upload-file--track').find('.icon__delete_box_track_odd').attr('data-id', item.data);
                        }else {
                            $this.removeAttr('disabled');
                            $this.parents('.box-upload-file--track').find('.name--file').html('');
                            $this.parents('.box-upload-file--track').find('form input[type="file"]').val('');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $this.parents('.box-upload-file--track').find('.name--file').html('');
                        $this.parents('.box-upload-file--track').find('form input[type="file"]').remove();
                        $this.parents('.box-upload-file--track').find('form').append('<input name="file_track" class="video_file" type="file">');
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Lỗi kết lối server!',
                        });
                        console.log('FAILURE!!');
                    });
                }
            }
        });
    });
    //Click btn submit form add Track
    $(document).on('click', '.box--track .btn-add-track', function (e) {
        e.preventDefault();
        let $this = $(this);
        let id = $this.attr('data-id');
        let url =  $this.parents('.box-upload-file--track').find('input[name="urlTrack"]').val();
        let title =  $this.parents('.box-upload-file--track').find('input[name="titleTrack"]').val();
        if (url.length == 0)
        {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Không tồn tại url file.',
            });
        }
        else if (title.length == 0)
        {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy nhập tiêu đề phụ đề.',
            });
        }
        else{
            let track = {
                'file': url,
                'label': title
            };
            let json = JSON.stringify(track);
            $this.parents('.box-upload-file--track').find('input[name="titleTrack"]').attr('disabled', 'disabled');
            $this.remove();
            // $('.upload-video-step-2 .input-track').append('<input type="hidden" name="tracks[]" value=\'' + json + '\' data-id="' + id + '">');

            $('.upload-video-step-2 .input-track').append('<input type="hidden" class="tracking" name="tracks[]" value="" data-id="' + id + '">');
            $('.upload-video-step-2 .input-track .tracking').val(json);
            $('.upload-video-step-2 .input-track input').removeClass('tracking');
            Swal.fire(
                'Thành công!',
                'Thêm thành công phụ đề.',
                'success'
            );
        }
    });

    //Create Odd Movie
    $('.add-odd-movie .btn-create-movie').click(function (e){
        e.preventDefault();
        // let file = $('.box-upload-file--movie .video_file').val();
        // let uploadId = $('.add-odd-movie .upload-video-step-2 input[name="uploadId"]').val();
        // let trailerUrl = $('.add-odd-movie .upload-video-step-2 input[name="trailerUrl"]').val();
        // if (uploadId != '' && trailerUrl != '')
        // {
        let uploading = $('.add-odd-movie .btn-create-movie').hasClass('uploading');
        if(uploading){
            Swal.fire({
                type: 'warning',
                title: 'Warning',
                text: 'Đang upload file. Vui lòng chờ upload xong hoặc hủy luồng upload.',
            });
        }else{
            if($('.form-create-movie').valid() == true) {
                $('.btn-create-movie').attr('disabled', 'disabled');
                $('.form-create-movie').submit();
            }
        }
        // }
        // else if(uploadId == ''){
        //     Swal.fire({
        //         type: 'error',
        //         title: 'Error',
        //         text: 'Phim chưa được chọn hoặc Đang tải phim.',
        //     });
        // }
        // else if(trailerUrl == ''){
        //     Swal.fire({
        //         type: 'error',
        //         title: 'Error',
        //         text: 'Trailer chưa được chọn hoặc Đang tải trailer.',
        //     });
        // }
    });
    //Edit Odd Movie
    $('.add-odd-movie .btn-edit-movie').click(function (e){
        e.preventDefault();
        if($('.form-create-movie').valid() == true) {
            $('.btn-edit-movie').attr('disabled', 'disabled');
            $('.form-create-movie').submit();
        }
    });
    $('.add-odd-movie .form-create-movie').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('.btn-create-movie').removeAttr('disabled');
            $('.btn-edit-movie').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.page-add-movie input[name="originUrl"]').val('');
                $('.page-add-movie input[name="previewUrl"]').val('');
                $('.page-add-movie input[name="uploadId"]').val('');
                $('.page-add-movie input[name="duration"]').val('');
                $('.page-add-movie input[name="nameFile"]').val('');
                $('.page-add-movie input[name="trailerUrl"]').val('');
                $('.page-add-movie input[name="durationTrailer"]').val('');
                $('.page-add-movie input[name="nameFileTrailer"]').val('');
                $('.page-add-movie input[name="uploadIdTrailer"]').val('');
                $('#addnew_cast').modal('hide');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

    //Search ajax load more
    function formatRepo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.name + "</div></div></div>";
        return markup;
    }
    function formatRepoSelectionCast (repo) {
        return repo.name || repo.text;
    }
    function formatRepoSelectionDirectors (repo) {
        return repo.name || repo.text;
    }
    $('.js-data-cast').select2({
        width: '100%',
        // allowClear: true,
        ajax: {
            url: "/odd-movie/get-cast-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn diễn viên',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionCast
    });
    $('.js-data-directors').select2({
        width: '100%',
        // allowClear: true,
        ajax: {
            url: "/odd-movie/get-directors-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn đạo diễn',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionDirectors
    });

    //Create Cast
    $('#btn-add-cast').click(function (e){
        e.preventDefault();
        if($('#addMovie-form-add-cast').valid() == true) {
            $('#btn-add-cast').attr('disabled', 'disabled');
            $('#addMovie-form-add-cast').submit();
        }
    });
    $('#addMovie-form-add-cast').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#btn-add-cast').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                document.getElementById('addMovie-form-add-cast').reset();
                $('#addnew_cast').modal('hide');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                );
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    //Create Directors
    $('#btn-add-directors').click(function (e){
        e.preventDefault();
        if($('#addMovie-form-add-directors').valid() == true) {
            $('#btn-add-directors').attr('disabled', 'disabled');
            $('#addMovie-form-add-directors').submit();
        }
    });
    $('#addMovie-form-add-directors').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#btn-add-directors').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                document.getElementById('addMovie-form-add-directors').reset();
                $('#addnew_directors').modal('hide');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                );
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

    // Box Price
    $(document).on('click', '.icon__add-price', function (e) {
        $('.box--price-clone .box-price').clone().appendTo('.list--box-price');
    });
    $(document).on('click', '.icon__delete-price', function (e) {
        e.preventDefault();
        $(this).parents('.box-price').remove();
    });
    // Checkbox package
    $(document).on('click', '.contentPackageBasic', function (e) {
        let standard = $('.contentPackageStandard').is(':checked');
        if (standard){
            $('.contentPackageStandard').prop("checked", false);
        }
    });
    $(document).on('click', '.contentPackageStandard', function (e) {
        let basic = $('.contentPackageBasic').is(':checked');
        if (basic){
            $('.contentPackageBasic').prop("checked", false);
        }
    });

})(jQuery);
