(function ($) {
    $('[data-toggle="popover"]').popover();
    function formatRepo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.name + "</div></div></div>";
        return markup;
    }
    function formatRepoSelectionUser (repo) {
        return repo.name || repo.text;
    }
    $('.js-data-users').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/album/get-ajax-user",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                        $.each(result, function( index, value ) {
                            // value['id'] = value['userId'];
                            value['id'] = value['displayName'];
                            value['name'] = value['displayName'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Chọn tài khoản người sử dụng',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionUser
    });

    $(document).on('click', '.btn-show-img', function (e) {
        e.preventDefault();
        $('#show_img .modal-title').html('');
        $('#show_img .modal-body img').attr('src', '');
        let title = $(this).attr('data-title');
        let src = $(this).attr('data-src');
        $('#show_img .modal-title').html(title);
        $('#show_img .modal-body img').attr('src', src);
        $('#show_img').modal('show');
    });
    $(document).on('click', '.approve-album', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');
        let status = $(this).attr('data-status');

        Swal.fire({
            title: 'Phê duyệt',
            text: 'Bạn muốn phê duyệt album?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Duyệt',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/album/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        status: status,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    $(document).on('click', '.reject-album', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');
        let status = $(this).attr('data-status');

        Swal.fire({
            title: 'Từ chối duyệt',
            text: 'Bạn muốn từ chối duyệt album?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/album/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        status: status,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });


///////////////// History Upload FTP
    // Search video
    $(document).on('click', '.btn-form-search-history-upload-ftp', function (e) {
        e.preventDefault();
        let $this = $(this);
        let from = $('#form-search-history-upload input[name=dateFrom]').val();
        let to = $('#form-search-history-upload input[name=dateTo]').val();
        if (from != '' && to != ''){
            let dateFrom = new Date(from);
            let dateTo = new Date(to);
            if (dateTo.getTime() >= dateFrom.getTime()){
                $('#form-search-history-upload').submit();
            }else{
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Ngày kết thúc phải lớn hơn ngày bắt đầu.',
                })
            }
        }else{
            $('#form-search-history-upload').submit();
        }
    });
})(jQuery);
