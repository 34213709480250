(function ($) {
    //Click btn add video
    $(document).on('click', '.btn-add-video', function (e) {
        e.preventDefault();
        $('.footer .overlay').addClass('overlaing');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                    $('#addnew_video').modal('hide');
                }
                else if(data.success) {
                    $('.box .upload-video-step-1').show();
                    $('.box .upload-video-step-2').hide();
                    // $('.box .upload-video-step-1 input[name="token_upload"]').val(data.success);
                    $('.upload-video-step-1 input[name="file_video"]').attr('data-token', data.success);
                    // $('.upload-video-step-1 input[name="token_upload"]').val(data.success);
                    $('#addnew_video').modal('show');
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    // Search video account CP (Create video)
    $(document).on('change', '#video_list_cp', function (e) {
        e.preventDefault();
        $('.video-account-cp div').remove();
        $('#video_create_channel_cp option').remove();
        var $this = $(this);
        var id = $('#video_list_cp').val();
        console.log(id);
        $('.video-account-cp').html('<select name="staffId_acc_cp" id="video_list_acc_cp" required data-msg="Chưa chọn tài khoản" class="form-control" data-live-search="true" title="Chọn tài khoản CP"></select>');

        //AJAX get info CP
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/search-info-cp',{
            type: 'POST',
            dataType: 'json',
            data: {
                id: id,
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    $('#video_list_acc_cp').removeAttr('disabled');
                    $('#video_list_acc_cp').attr('title', data.error);
                    $('#video_list_acc_cp').selectpicker();
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.success) {
                    $('#video_list_acc_cp').attr('title', 'Chọn tài khoản CP');
                    $('#video_list_acc_cp').removeAttr('disabled');
                    $('#video_list_acc_cp').html(data.success.account);
                    $('#video_list_acc_cp').selectpicker();
                    $('#video_create_channel_cp').html(data.success.channel);
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    // Search video change type
    $(document).on('change', '#search-video-type', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#search-video-type').val();
        console.log(type);
        if(type == 0)
        {
            $('.box-search-acc-cp').removeClass('d-none');
            $('.box-search-acc-user').addClass('d-none');
            $('#staffId_cp').removeAttr('disabled', 'disabled');
            $('.box-search-acc-cp button.dropdown-toggle').removeClass('disabled');
            $('#staffId_user').attr('disabled', 'disabled');
        }
        else if (type == 1)
        {
            $('.box-search-acc-user').removeClass('d-none');
            $('.box-search-acc-cp').addClass('d-none');
            $('.box-search-acc-user .dropdown.bootstrap-select').removeClass('disabled');
            $('.box-search-acc-user .dropdown.bootstrap-select button.dropdown-toggle').removeClass('disabled');
            $('#staffId_user').removeAttr('disabled', 'disabled');
            $('#staffId_cp').attr('disabled', 'disabled');
        }
    });

    $(document).on('change', '.list-video-search #staffId_cp', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#search-video-type').val();
        let id = $('.list-video-search #staffId_cp').val();

        $('.list-video-search #video_search_channel').attr('data-type', type);
        $('.list-video-search #video_search_channel').attr('data-id', id);
    });
    $(document).on('change', '.list-video-search #staffId_user', function (e) {
        e.preventDefault();
        let $this = $(this);
        let type = $('#search-video-type').val();
        let id = $('.list-video-search #staffId_user').val();

        $('.list-video-search #video_search_channel').attr('data-type', type);
        $('.list-video-search #video_search_channel').attr('data-id', id);
    });

    function formatRepo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.name + "</div></div></div>";
        return markup;
    }
    // Select ajax search channel
    $('.channel-js-data-user').select2({
        // matcher: matchCustom,
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/video-manager/get-channel-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                let type = $(this).attr('data-type');
                let id = $(this).attr('data-id');

                return {
                    q: params.term, // search term
                    type: type,
                    id: id,
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.content;
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.total
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Chọn kênh',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionChannel
    });

    function formatRepoSelectionChannel (repo) {
        return repo.name || repo.text;
    }

    // Click submit form create content video
    $('.box .btn-create-content-video').click(function (e){
        e.preventDefault();
        let uploadId = $('.box .upload-video-step-2 input[name=uploadId]').val();

        if (uploadId.length > 0)
        {
            if($('.box .form-create-video').valid() == true) {
                $('.box .btn-create-content-video').attr('disabled', 'disabled');
                $('.box .form-create-video').submit();
            }

        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Video chưa upload xong!',
            });
        }
    });
    $('.box .form-create-video').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            $('.box .btn-create-content-video').removeAttr('disabled');
            if (res.error){
                $('.box .btn-create-content-video').removeAttr('disabled');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.box .btn-create-content-video').removeAttr('disabled');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/video-manager');
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('.video-thumb-upload').dropify({
        messages: {
            'default': 'Hình ảnh thu nhỏ tùy chỉnh',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });

    // Search video
    $(document).on('click', '#video-search', function (e) {
        e.preventDefault();
        let $this = $(this);
        let from = $('#form-search-video input[name=dateFrom]').val();
        let to = $('#form-search-video input[name=dateTo]').val();
        if (from != '' && to != ''){
            let dateFrom = new Date(from);
            let dateTo = new Date(to);
            if (dateTo.getTime() >= dateFrom.getTime()){
                $('#form-search-video').submit();
            }else{
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Ngày kết thúc phải lớn hơn ngày bắt đầu.',
                })
            }
        }else{
            $('#form-search-video').submit();
        }
        // $this.closest('form').submit();
    });

    // Edit video
    $(document).ready(function () {
        let href = window.location.href;
        $('#form-edit-video').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    $('#btn-edit-content-video').removeAttr('disabled', 'disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else if (res.success) {
                    $('.footer .overlay').removeClass('overlaing');
                    $('#btn-edit-content-video').removeAttr('disabled', 'disabled');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $('#edit_video').modal('hide');
                        $(location).attr('href', href);
                    });
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    $('#btn-edit-content-video').removeAttr('disabled', 'disabled');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('#btn-edit-content-video').click(function (e){
            e.preventDefault();
            if($('#form-edit-video').valid() == true) {
                $('.footer .overlay').addClass('overlaing');
                $('#btn-edit-content-video').attr('disabled', 'disabled');
                $('#form-edit-video').submit();
            }
        });
    });
        // Click button edit
    $(document).on('click', '.edit-video', function (e) {
        e.preventDefault();
        $('input[name="edit_hashtag_video"]').tagsinput('removeAll');
        $('select[name="edit_video_thumb"] option').remove();

        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let kind = $(this).attr('data-kind');
        let channel = $(this).attr('data-channel');
        let channelName = $(this).attr('data-channelName');
        let hashtag = $(this).attr('data-hashtag');
        let des = $(this).attr('data-des');
        let originUrl = $(this).attr('data-originUrl');
        let previewUrl = $(this).attr('data-previewUrl');
        let uploadId = $(this).attr('data-uploadId');
        let duration = $(this).attr('data-duration');
        let thumb = $(this).attr('data-thumb');
        let thumb2 = $(this).attr('data-thumb2');
        let sortCategory = $(this).attr('data-sortCategory');
        let sortPopular = $(this).attr('data-sortPopular');
        let view = $(this).attr('data-view');
        let share = $(this).attr('data-share');
        let like = $(this).attr('data-like');

        $('input[name="edit_id_video"]').val(id);
        $('input[name="edit_title_video"]').val(title);
        $('select[name="edit_kind_video"] option[value="'+ kind +'"]').attr("selected", 'selected');
        $('#edit_channel_video').val(channelName);
        $('input[name="edit_channel_video"]').val(channel);
        $('input[name="edit_hashtag_video"]').tagsinput('add', hashtag);
        $('textarea[name="edit_des_video"]').val(des);
        $('input[name="edit_originUrl"]').val(originUrl);
        $('input[name="edit_previewUrl"]').val(previewUrl);
        $('input[name="edit_uploadId"]').val(uploadId);
        $('input[name="edit_duration"]').val(duration);
        $('input[name="edit_thumb"]').val(thumb);
        $('input[name="edit_thumb2"]').val(thumb2);
        $('input[name="edit_sortCategory"]').val(sortCategory);
        $('input[name="edit_sortPopular"]').val(sortPopular);
        $('input[name="edit_view"]').val(view);
        $('input[name="edit_share"]').val(share);
        $('input[name="edit_like"]').val(like);

        $('.form-edit-video .thumb').append('<option data-img-src="'+ thumb +'" class="img-1" value="1" selected>img 1</option>');
        $('.video__thumb .thumb').imagepicker();

        //add title hashtag
        $('#edit_video .bootstrap-tagsinput .badge').each(function( index ) {
            let id = $(this).attr('data-id');
            if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
                $(this).clone().appendTo('.playlist__videos');
            }
        });

        $('#edit_video').modal('show');
    });

    // Click button set sort video
    $(document).on('click', '.btn-set-sort-video', function (e) {
        e.preventDefault();
        $('#setSort_video input[name="setSortVideo_cate"]').val('');
        $('#setSort_video input[name="setSortVideo_popular"]').val('');

        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let cate = $(this).attr('data-cate');
        let popular = $(this).attr('data-popular');

        $('#setSort_video input[name="videoID"]').val(id);
        $('#setSort_video input[name="setSortVideo_cate"]').val(cate);
        $('#setSort_video input[name="setSortVideo_popular"]').val(popular);
        $('#setSort_video .modal-title').html(title);

        $('#setSort_video').modal('show');
    });
    // Click button Show Supper App video
    $(document).on('click', '.btn-show-video-supperApp', function (e) {
        e.preventDefault();
        $('#video_show_supperApp .custom-control-input').prop("checked", false);

        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let home = $(this).attr('data-home');
        let special = $(this).attr('data-special');
        let series = $(this).attr('data-series');
        let single = $(this).attr('data-single');
        let sort = $(this).attr('data-sort');
        let play = $(this).attr('data-play');
        //Home
        if (home == true){
            $('#video_show_supperApp #homeSupperApp1').prop("checked", true);
        }else{
            $('#video_show_supperApp #homeSupperApp2').prop("checked", true);
        }
        //Special
        if (special == true){
            $('#video_show_supperApp #specialSupperApp1').prop("checked", true);
        }else{
            $('#video_show_supperApp #specialSupperApp2').prop("checked", true);
        }
        //Series
        if (series == true){
            $('#video_show_supperApp #seriesSupperApp1').prop("checked", true);
        }else{
            $('#video_show_supperApp #seriesSupperApp2').prop("checked", true);
        }
        //Single
        if (single == true){
            $('#video_show_supperApp #singleSupperApp1').prop("checked", true);
        }else{
            $('#video_show_supperApp #singleSupperApp2').prop("checked", true);
        }
        //Play
        if (play == true){
            $('#video_show_supperApp #playSupperApp1').prop("checked", true);
        }else{
            $('#video_show_supperApp #playSupperApp2').prop("checked", true);
        }
        $('#video_show_supperApp input[name="videoID"]').val(id);
        $('#video_show_supperApp .modal-title').html(title);
        $('#video_show_supperApp select[name="sort"]').val(sort);

        $('#video_show_supperApp').modal('show');
    });
    // Submit Form Show Supper App
    $('.form-show-supper-app').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            let href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    // Click button Table Supper App
    $(document).on('click', '.btn-supperApp', function (e) {
        e.preventDefault();
        $('#show_table_supperApp .modal-title').html('');
        $('#show_table_supperApp .modal-body table tbody').html('');

        let title = $(this).attr('data-title');
        let home = $(this).attr('data-home');
        let special = $(this).attr('data-special');
        let series = $(this).attr('data-series');
        let single = $(this).attr('data-single');
        let play = $(this).attr('data-play');
        let html = '';
        //Home
        html += '<tr><td>Màn Home</td><td class="text-center">'+ home +'</td></tr>';
        //Special
        html += '<tr><td>Trang đặc sắc</td><td class="text-center">'+ special +'</td></tr>';
        //Seriese
        html += '<tr><td>Phim bộ</td><td class="text-center">'+ series +'</td></tr>';
        //Single
        html += '<tr><td>Phim lẻ</td><td class="text-center">'+ single +'</td></tr>';

        html += '<tr><td>Play</td><td class="text-center">'+ play +'</td></tr>';

        $('#show_table_supperApp .modal-title').html(title);
        $('#show_table_supperApp .modal-body table tbody').html(html);

        $('#show_table_supperApp').modal('show');
    });
    $(document).on('change', '.update__packages', function (e) {
        e.preventDefault();
        let $this = $(this);
        let id = $this.attr('data-id');
        let value = $this.attr('data-value');
        let packages = $this.val();
        let href = window.location.href;

        Swal.fire({
            title: 'Loại nội dung',
            text: 'Bạn muốn thay đổi loại nội dung của video này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/update-package',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        packages: packages,
                    },
                    success:function (data){
                        console.log(data);
                        console.log(value);
                        if (data.error){
                            $this.val(value);
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire({
                                type: 'success',
                                title: 'Success',
                                text: data.success,
                            });
                        }else {
                            $this.val(value);
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }else{
                $this.val(value);
            }
        });
    });
    // Submit Form Set Sort Video
    $('#form-set-sort-video').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/video-manager');
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

    // Reject video
    $('#form-reject-video').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-video').removeAttr('disabled', 'disabled');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-video').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-video').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $(document).on('click', '.form-btn-reject-video', function (e){
        e.preventDefault();
        if($('#form-reject-video').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.form-btn-reject-video').attr('disabled', 'disabled');
            $('#form-reject-video').submit();
        }
    });
    // Click button reject video
    $(document).on('click', '.btn-reject-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        $('#reject_video .modal-title').html('');

        $('#reject_video .modal-title').html(title);

        $('#reject_video input[name="id_video_reject"]').val(id);

        $('#reject_video').modal('show');
    });
    ////// Show video (<video>)
    // Click show video
    $(document).on('click', '.btn-show-video', function (e) {
        e.preventDefault();
        $('#show_video .modal-title').html('');
        $('#show_video video').attr('src', '');

        var title = $(this).attr('data-title');
        var url = $(this).attr('data-originalUrl');

        $('#show_video .modal-title').html(title);
        $('#show_video video').attr('src', url);

        $('#show_video').modal('show');
    });
        // Click dismiss modal pause video
    $(document).on('click', '#show_video .close', function (e) {
        e.preventDefault();
        $('#show_video .modal-title').html('');
        $('#show_video .modal-body div video').remove();
        $('#show_video .modal-body div').html('<video src="" controls class="w-100" style="max-height: 300px;"></video>');
    });
    $(document).on('click', '#show_video .modal-footer button', function (e) {
        e.preventDefault();
        $('#show_video .modal-title').html('');
        $('#show_video .modal-body div video').remove();
        $('#show_video .modal-body div').html('<video src="" controls class="w-100" style="max-height: 300px;"></video>');
    });
    ////// Show video (JWplayer)
    // Click show video
    $(document).on('click', '.btn-show-video-jwplayer', function (e) {
        e.preventDefault();
        let title = $(this).attr('data-title');
        let url = $(this).attr('data-originalUrl');

        $('#jwplayer_show_video .modal-title').html(title);
        if (url.length > 0){
            jwplayer("video-player").remove();
            jwplayer("video-player").setup({
                autostart:true,
                abouttext: "meMovie",
                aboutlink: BASE_URL,
                image : "",
                starttime: 0,
                title: title,
                file: url,
            });
        }else{
            $('#jwplayer_show_video .modal-body div').html('<p class="text-center color-red">Không tồn tại link video.</p>');
        }

        $('#jwplayer_show_video').modal('show');
    });
        // Click dismiss modal pause video
    $(document).on('click', '#jwplayer_show_video .close', function (e) {
        e.preventDefault();
        $('#jwplayer_show_video .modal-title').html('');
        $('#jwplayer_show_video .modal-body div').html('');
        $('#jwplayer_show_video .modal-body div').html('<div id="video-player"></div>');
    });
    $(document).on('click', '#jwplayer_show_video .modal-footer button', function (e) {
        e.preventDefault();
        $('#jwplayer_show_video .modal-title').html('');
        $('#jwplayer_show_video .modal-body div').html('');
        $('#jwplayer_show_video .modal-body div').html('<div id="video-player"></div>');
    });
    ////// HLS Video
    $(document).on('click', '.btn-show-video-hls', function (e) {
        e.preventDefault();
        $('#hls_show_video .modal-body').html('');
        let title = $(this).attr('data-title');
        let url = $(this).attr('data-originalUrl');

        $('#hls_show_video .modal-title').html(title);
        if (url.length > 0){
            if (url.includes('m3u8')){
                $('#hls_show_video .modal-body').html('<video id="video" controls class="w-100"></video>');
                let video = document.getElementById('video');
                if (Hls.isSupported()) {
                    let hls = new Hls();
                    hls.loadSource(url);
                    hls.attachMedia(video);
                    hls.on(Hls.Events.MANIFEST_PARSED, function() {
                        video.play();
                    });
                }
            }else{
                $('#hls_show_video .modal-body').html('<video controls autoplay class="w-100" src="'+ url +'"></video>');
            }
        }else{
            $('#hls_show_video .modal-body').html('<p class="text-center color-red">Không tồn tại link video.</p>');
        }

        $('#hls_show_video').modal('show');
    });
        // Click dismiss modal pause video
    $(document).on('click', '#hls_show_video .close', function (e) {
        e.preventDefault();
        $('#hls_show_video .modal-title').html('');
        $('#hls_show_video .modal-body').html('');
    });
    $(document).on('click', '#hls_show_video .modal-footer button', function (e) {
        e.preventDefault();
        $('#hls_show_video .modal-title').html('');
        $('#hls_show_video .modal-body').html('');
    });
    //////
    //Check modal #show_video, #show_video_embed, #upload_show_video, #jwplayer_show_video có tồn tại class 'show' không.
    $('body').click(function(){
        if(!($( "#hls_show_video" ).hasClass( "show" )))
        {
            $('#hls_show_video .modal-body').html('');
        }
        if(!($( "#jwplayer_show_video" ).hasClass( "show" )))
        {
            $('#jwplayer_show_video .modal-body div').html('');
            $('#jwplayer_show_video .modal-body div').html('<div id="video-player"></div>');
        }
        if(!($( "#show_video" ).hasClass( "show" )))
        {
            $('#show_video .modal-body div video').remove();
            $('#show_video .modal-body div').html('<video src="" controls class="w-100" style="max-height: 300px;"></video>');
        }
        if(!($( "#show_video_embed" ).hasClass( "show" )))
        {
            $('#show_video_embed .modal-body div embed').remove();
            $('#show_video_embed .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
        }
        if(!($( "#upload_show_video" ).hasClass( "show" )))
        {
            $('#upload_show_video .modal-body div embed').remove();
            $('#upload_show_video .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
        }
    });
    ////// Show video Embed (<embed>)
    // Click show video
    $(document).on('click', '.btn-show-video-embed', function (e) {
        e.preventDefault();
        $('#show_video_embed .modal-title').html('');
        $('#show_video_embed embed').attr('src', '');

        var title = $(this).attr('data-title');
        var url = $(this).attr('data-originalUrl');

        $('#show_video_embed .modal-title').html(title);
        $('#show_video_embed embed').attr('src', url);

        $('#show_video_embed').modal('show');
    });
        // Click dismiss modal pause video
    $(document).on('click', '#show_video_embed .close', function (e) {
        e.preventDefault();
        $('#show_video_embed .modal-title').html('');
        $('#show_video_embed .modal-body div embed').remove();
        $('#show_video_embed .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
    });
    $(document).on('click', '#show_video_embed .modal-footer button', function (e) {
        e.preventDefault();
        $('#show_video_embed .modal-title').html('');
        $('#show_video_embed .modal-body div embed').remove();
        $('#show_video_embed .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
    });
    //////
    // Click show video after upload video
    $(document).on('click', '.btn-upload-show-video', function (e) {
        e.preventDefault();
        $('#upload_show_video embed').attr('src', '');

        var url = $(this).attr('data-originalUrl');

        $('#upload_show_video embed').attr('src', url);

        $('#upload_show_video').modal('show');
    });
        // Click dismiss modal pause video
    $(document).on('click', '#upload_show_video .close', function (e) {
        e.preventDefault();
        $('#upload_show_video embed').attr('src', '');
        $('#upload_show_video .modal-body div embed').remove();
        $('#upload_show_video .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
    });
    $(document).on('click', '#upload_show_video .modal-footer button', function (e) {
        e.preventDefault();
        $('#upload_show_video embed').attr('src', '');
        $('#upload_show_video .modal-body div embed').remove();
        $('#upload_show_video .modal-body div').html('<embed src="" style="width: 100%;height: 249px;"/>');
    });

    // Suggest Hidden video (Đề nghị ẩn)
    $(document).on('click', '.suggest-hide-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Suggest hide video',
            text: 'Bạn muốn đề nghị ẩn video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/suggest-hide',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // CP đăng ký lại
    $(document).on('click', '.re-register-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Re-Register video',
            text: 'Bạn muốn đăng ký lại video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/re-register',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Hidden video
    $(document).on('click', '.hidden-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden video',
            text: 'Bạn muốn ẩn video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show video
    $(document).on('click', '.show-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show video',
            text: 'Bạn muốn hiện video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Hide Comments video
    $(document).on('click', '.hide-comments-video', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let href = window.location.href;

        Swal.fire({
            title: 'Ẩn Bình Luận',
            text: 'Bạn muốn ẩn bình luận?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/hide-comments',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show comments video
    $(document).on('click', '.show-comments-video', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hiện Bình Luận',
            text: 'Bạn muốn hiện bình luận?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/show-comments',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Delete video
    $(document).on('click', '.btn-delete-video', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let strTitle = '';
        if (title.length > 0 ){
            strTitle += '"' + title + '"';
        }
        let href = window.location.href;

        Swal.fire({
            title: 'Xóa ' + strTitle,
            text: 'Bạn muốn xóa?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Set New video
    $(document).on('click', '.btn-setNew-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Set new',
            text: 'Bạn muốn set new video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/set-new',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Approve checkbox video
    $(document).on('click', '.approve-video', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Aprove video',
            text: 'Bạn muốn phê duyệt video?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Approve checkbox video
    $(document).on('change', '#approve-checkbox-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let item = $(this).val();
        let checkbox = [];
        $('input[name="checkbox_video[]"]:checked').each(function(){
            // checkbox = checkbox + ',' + $(this).val();
            checkbox.push($(this).val());
        });
        let title = '';
        let text = '';
        if (item == 2){
            title = 'Set New';
            text = 'Bạn muốn set new các video?';
        }else{
            title = 'Aprove video';
            text = 'Bạn muốn phê duyệt các video?';
        }

        if (checkbox.length)
        {
            Swal.fire({
                title: title,
                text: text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/video-manager/approve-checkbox',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            ids: checkbox,
                            item: item,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn video!',
            });
        }
        $('select[name="approve-checkbox-video"]').val('');
    });

    // Click button action history video
    $(document).on('click', '.btn-action-history-video', function (e) {
        e.preventDefault();
        $('#action_history_video-title').html('');
        $('.list-action-video-history tbody').html('');

        let title = $(this).attr('data-title');
        let history = $(this).attr('data-history');
        let his = JSON.parse(history);
        //Đảo ngược mảng
        his.reverse();
        let html = '';
        $.each( his, function( key, value ) {
            if (value['action'] ==  1){
                var action = 'Duyệt';
            }
            else if (value['action'] ==  2){
                var action = 'Hủy live';
            }
            else if (value['action'] == 3){
                var action = 'Dừng live';
            }
            else if (value['action'] == 4){
                var action = 'Chuyển live thành video';
            }
            else if (value['action'] == 5){
                var action = 'Lên lịch';
            }
            else if (value['action'] == 6){
                var action = 'Cập nhật';
            }
            else if (value['action'] == 7){
                var action = 'Ẩn';
            }
            else if (value['action'] == 8){
                var action = 'Từ chối duyệt';
            }
            else if (value['action'] == 9){
                var action = 'Yêu cầu ẩn kênh';
            }
            else if (value['action'] == 10){
                var action = 'Hiện';
            }
            else if (value['action'] == 12){
                var action = 'Xóa';
            }
            else if (value['action'] == 13){
                var action = 'Cập nhật trạng thái supper app';
            }
            else{
                var action = '';
            }
            let date = new Date(value['actiontime']);
            date = date.toLocaleString();
            html += '<tr><td>'+ value['username'] +'</td><td align="center">'+ action +'</td><td align="center">'+ date +'</td></tr>';
        });

        $('#action_history_video-title').html(title);
        $('.list-action-video-history tbody').html(html);

        $('#action_history_video').modal('show');
    });
    // Click button Categories video
    $(document).on('click', '.btn-show-cate', function (e) {
        e.preventDefault();
        $('#show_categories_video-title').html('');
        $('.list-category-movie tbody').html('');

        let cate = $(this).attr('data-cate');
        let title = $(this).attr('data-title');
        let categories = '';
        let html = '';
        if (cate.length > 0)
        {
            categories = JSON.parse(cate);
            $.each( categories, function( key, value ) {
                html += '<tr><td align="center">'+ (key + 1) +'</td><td>'+ value['name'] +'</td></tr>';
            });
        }

        $('#show_categories_video-title').html(title);
        $('.list-category-movie tbody').html(html);

        $('#show_categories_video').modal('show');
    });
    // Click button Price video
    $(document).on('click', '.btn-show-price', function (e) {
        e.preventDefault();
        $('#show_price_video-title').html('');
        $('.list-price-movie tbody').html('');

        let cate = $(this).attr('data-price');
        let title = $(this).attr('data-title');
        let price = '';
        let html = '';
        if (cate.length > 0)
        {
            price = JSON.parse(cate);
            $.each( price, function( key, value ) {
                html += '<tr><td class="text-center">'+ (key + 1) +'</td><td class="text-center">'+ value['price'] +'</td><td class="text-center">'+ value['cycle'] +'</td></tr>';
            });
        }

        $('#show_price_video-title').html(title);
        $('.list-price-movie tbody').html(html);

        $('#show_price_video').modal('show');
    });

    $('.form-btn-upload-test').click(function (e){
        e.preventDefault();
        if($('.upload__form--test').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.upload__form--test').submit();
        }
    });
    $('.upload__form--test').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'success',
                    title: 'Success',
                    text: res.success,
                });
                $('.detail-response').html(res.data);
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

    // Upload video JS thuần sử dụng axios
    $(document).on('change', '.box .upload-video-step-1 .video_file',function () {
        $('.footer .overlay').addClass('overlaing');
        let $this = $(this);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                    // $('#addnew_video').modal('hide');
                }
                else if(res.success) {
                    let token = res.success;
                    $('.box .upload-video-step-1').hide();
                    $('.box .upload-video-step-2').show();
                    // $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ BASE_URL +'/images/loader.gif" class="img-1" value="1" selected>img 1</option>');
                    // $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ BASE_URL +'/images/loader.gif" class="img-2" value="2">img 2</option>');
                    $('.video__thumb .thumb').imagepicker();
                    $('.box .image-picker.thumb option').attr('data-img-src', BASE_URL +'/images/loader.gif');
                    $('.box .image-select .thumbnails img').attr('src', BASE_URL +'/images/loader.gif');
                    $('.box .upload-video-step-2 .progress .progress-bar').css('width','0%');
                    console.log($(".box .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api_upload,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': token
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            }
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $('.box .upload-video-step-2 .progress .progress-bar').css('width','100%');
                            $('.box .upload-video-step-2 .progress .progress-bar').text('Done!');
                            $('.box .upload-video-step-2 .thumb').empty();
                            $('.box .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.box .upload-video-step-2 input[name=originUrl]').val(item.data.originUrl);
                            $('.box .upload-video-step-2 input[name=token_trans]').val(token);
                            $('.box .upload-video-step-2 input[name=previewUrl]').val(item.data.previewUrl);
                            $('.box .upload-video-step-2 input[name=duration]').val(item.data.duration);
                            $('.box .upload-video-step-2 input[name=uploadId]').val(item.data.uploadId);
                            $('.box .upload-video-step-2 input[name=thumbs1_1]').val(item.data.thumbs1[0]);
                            $('.box .upload-video-step-2 input[name=thumbs1_2]').val(item.data.thumbs1[1]);
                            $('.box .upload-video-step-2 input[name=thumbs2_1]').val(item.data.thumbs2[0]);
                            $('.box .upload-video-step-2 input[name=thumbs2_2]').val(item.data.thumbs2[1]);
                            $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ item.data.thumbs2[0] +'" class="img-1" value="1" selected>img 1</option>');
                            $('.box .upload-video-step-2 .thumb').append('<option data-img-src="'+ item.data.thumbs2[1] +'" class="img-2" value="2">img 2</option>');
                            $('.video__thumb .thumb').imagepicker();
                            $('.btn-upload-show-video').attr('data-originalUrl', item.data.originUrl);
                            $('.btn-upload-show-video').css('display', 'block');
                        }else {
                            $('.box .upload-video-step-1').show();
                            $('.box .upload-video-step-2').hide();
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $('.box .upload-video-step-1').show();
                        $('.box .upload-video-step-2').hide();
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Lỗi kết lối server!',
                        });
                        console.log('FAILURE!!');
                    });
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    ////Sort video
    $(document).on('click', '.btn-sort-video', function (e) {
        e.preventDefault();
        let sort = $(this).attr("data-sort");
        let limit = $(this).attr("data-limit");
        let page = $(this).attr("data-page");
        let url = window.location.href.slice(window.location.href.indexOf('?') + 1);
        console.log('url: ' + url);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/sort',{
            type: 'get',
            dataType: 'json',
            data: {
                sort: sort,
                url: url,
                limit: limit,
                page: page
            },
            success:function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.success) {
                    if (data.html != '')
                    {
                        $('table tbody').html(data.html);
                        $('.btn-sort-video').attr('data-page', data.page);
                    }
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    function formatRepoMovie (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.title + "</div></div></div>";
        return markup;
    }
    function formatRepoSelectionMovie (repo) {
        return repo.title || repo.text;
    }
    $('.js-data-movie').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/video-manager/get-movie-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.content;
                        $.each(result, function( index, value ) {
                            result['name'] = value['title'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.data.totalElements
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn phim',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepoMovie,
        templateSelection: formatRepoSelectionMovie
    });
    $(document).on('change', '.checkbox-all', function (e) {
        let status = this.checked;
        $('.item-checkbox').each(function(){
            this.checked = status;
        });
    });

    // Notification is Series Movie
    $(document).on('click', '.btn-noti-series-movie', function (e) {
        let title = $(this).attr('data-title');
        Swal.fire({
            type: 'warning',
            title: title,
            text: '',
        });
    });
    // Get link play, show link movie
    $(document).on('click', '.btn-get-link-play', function (e) {
        e.preventDefault();
        $('.footer .overlay').addClass('overlaing');
        $('#hls_show_video .modal-body').html('');
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        console.log(id);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/video-manager/link-play',{
            type: 'GET',
            dataType: 'json',
            data: {
                id: id
            },
            success: async function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    console.log('error');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.null) {
                    Swal.fire({
                        type: 'warning',
                        title: 'Warning',
                        text: data.null,
                    });
                }
                else if(data.success) {
                    $('#hls_show_video .modal-title').html(title);
                    let str = data.success;
                    if (str.includes('m3u8')){
                        $('#hls_show_video .modal-body').html('<video id="video" controls class="w-100"></video>');
                        let video = document.getElementById('video');
                        if (Hls.isSupported()) {
                            let hls = new Hls();
                            hls.loadSource(str);
                            hls.attachMedia(video);
                            hls.on(Hls.Events.MANIFEST_PARSED, function() {
                                video.play();
                            });
                        }
                    }else{
                        $('#hls_show_video .modal-body').html('<video controls autoplay class="w-100" src="'+ str +'"></video>');
                    }

                    $('#hls_show_video').modal('show');
                }
                else {
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    // Delete trailer video
    $(document).on('click', '.btn-delete-trailer-video', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let strTitle = '';
        if (title.length > 0 ){
            strTitle += '"' + title + '"';
        }
        let href = window.location.href;

        Swal.fire({
            title: 'Xóa trailer phim ' + strTitle,
            text: 'Bạn muốn xóa trailer?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/delete-trailer',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
