(function ($) {
    $(document).on('change', '.add-series-movie #numReview',function () {
        let num = $(this).val();
        if (num == 1)
        {
            $('.box-upload-file--review').css('display', 'block');
            $('.noti__file_reviews').css('display', 'none');
        }else{
            $('.box-upload-file--review').css('display', 'none');
            $('.noti__file_reviews').css('display', 'block');
        }
    });
    // Upload Trailer JS thuần sử dụng axios
    const CancelToken = axios.CancelToken;
    let cancelSeriesMovieCasing;
    $(document).on('change', '.add-series-movie .box-upload-file--trailer .upload-video-step-1 .video_file',function (e) {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $('.box-upload-file--trailer .name--file').html($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]['name']);
        $this.attr('disabled', 'disabled');
        $('.page-add-movie .upload-video-step-2 input[name=trailerUrl]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=duration]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=nameFile]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=uploadId]').val('');
        // Get Duration Video
        let file = e.currentTarget.files[0];
        let obUrl = URL.createObjectURL(file);
        document.getElementById('audio').setAttribute('src', obUrl);
        console.log('file');

        let f_duration = 0;
        document.getElementById('audio').addEventListener('canplaythrough', function(f){
            f_duration = Math.round(f.currentTarget.duration);
            console.log(f_duration);
            $('.page-add-movie .upload-video-step-2 input[name=duration]').val(f_duration);
        });
        //End

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/series-movie-casing/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if(res.success) {
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    console.log($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelSeriesMovieCasing = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $this.removeAttr('disabled');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','100%');
                            $('.box-upload-file--trailer .progress .progress-bar').text('Done!');
                            $('.page-add-movie .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.page-add-movie .upload-video-step-2 input[name=trailerUrl]').val(item.data.url);
                            $('.page-add-movie .upload-video-step-2 input[name=nameFile]').val(item.data.fileName);
                            $('.page-add-movie .upload-video-step-2 input[name=uploadId]').val(item.data.contentId);
                            $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', item.data.url);
                            $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'block');
                        }else {
                            $this.removeAttr('disabled');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                            $('.box-upload-file--trailer .name--file').html('');
                            $('.box-upload-file--trailer form input').remove();
                            $('.box-upload-file--trailer form').html('<input name="file_trailer" class="video_file" type="file">');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                        $('.box-upload-file--trailer .name--file').html('');
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                        $('.box-upload-file--trailer form input[type="file"]').remove();
                        $('.box-upload-file--trailer form').append('<input name="file_trailer" class="video_file" type="file">');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    // Upload Review JS thuần sử dụng axios
    $(document).on('change', '.add-series-movie .box-upload-file--review .upload-video-step-1 .video_file',function () {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $('.box-upload-file--review .name--file').html($(".box-upload-file--review .upload-video-step-1 .video_file")[0].files[0]['name']);
        $this.attr('disabled', 'disabled');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/series-movie-casing/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                    // $('#addnew_video').modal('hide');
                }
                else if(res.success) {
                    let token = res.success;
                    $('.box-upload-file--review .progress .progress-bar').css('width','0%');
                    console.log($(".box-upload-file--review .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box-upload-file--review .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api_upload,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': token
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box-upload-file--review').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box-upload-file--review').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box-upload-file--review').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box-upload-file--review').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            }
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $this.removeAttr('disabled');
                            $('.box-upload-file--review .progress .progress-bar').css('width','100%');
                            $('.box-upload-file--review .progress .progress-bar').text('Done!');
                            $('.page-add-movie .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.page-add-movie .upload-video-step-2 input[name=reviewUrl]').val(item.data.originUrl);
                            $('.box-upload-file--review .btn-upload-show-video').attr('data-originalUrl', item.data.originUrl);
                            $('.box-upload-file--review .btn-upload-show-video').css('display', 'block');
                        }else {
                            $this.removeAttr('disabled');
                            $('.box-upload-file--review .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--review .progress .progress-bar').html('0%');
                            $('.box-upload-file--review .name--file').html('');
                            $('.box-upload-file--review form input').remove();
                            $('.box-upload-file--review form').html('<input name="file_review" class="video_file" type="file">');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $('.box-upload-file--review .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--review .progress .progress-bar').html('0%');
                        $('.box-upload-file--review .name--file').html('');
                        $('.box-upload-file--review form input').remove();
                        $('.box-upload-file--review form').html('<input name="file_review" class="video_file" type="file">');
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Lỗi kết lối server!',
                        });
                        console.log('FAILURE!!');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $('.box-upload-file--review .progress .progress-bar').css('width','0%');
                    $('.box-upload-file--review .progress .progress-bar').html('0%');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    function resetFormTrailerSeries(){
        $('.box-upload-file--trailer form input[type="file"]').addClass('cancel');
        cancelSeriesMovieCasing();
        $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
        $('.box-upload-file--trailer .progress .progress-bar').html('0%');
        $('.box-upload-file--trailer .name--file').html('');
        $('.box-upload-file--trailer form input[type="file"]').remove();
        $('.box-upload-file--trailer form').append('<input name="file_video" class="video_file" type="file">');

        $('.page-add-movie .upload-video-step-2 input[name=trailerUrl]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=duration]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=nameFile]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=uploadId]').val('');

        $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', '');
        $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'none');

        Swal.fire({
            type: 'warning',
            text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
        });
    }
    function ajaxDeleteMovie(contentId, fileName, resetForm){
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/delete-file',{
            type: 'POST',
            dataType: 'json',
            data: {
                contentId: contentId,
                fileName: fileName,
            },
            success:function (data){
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }else{
                    resetForm();
                }
            }
        });
    }
    $(document).on('click','.add-series-movie .box-upload-file--trailer .upload--cancel',function () {
        $('.footer .overlay').addClass('overlaing');
        let contentId = $('.add-odd-movie .upload-video-step-2 input[name="uploadId"]').val();
        let fileName = $('.add-odd-movie .upload-video-step-2 input[name="nameFile"]').val();
        console.log(contentId);
        console.log(fileName);
        if (fileName && fileName.length > 0){
            ajaxDeleteMovie(contentId, fileName, resetFormTrailerSeries);
        }else{
            $('.footer .overlay').removeClass('overlaing');
            resetFormTrailerSeries();
        }
    });

    $(document).on('click','.add-series-movie .box-upload-file--review .upload--cancel',function () {
        $('.box-upload-file--review .progress .progress-bar').css('width','0%');
        $('.box-upload-file--review .progress .progress-bar').html('0%');
        $('.box-upload-file--review .name--file').html('');
        $('.page-add-movie .upload-video-step-2 input[name=reviewUrl]').val('');
        $('.box-upload-file--review form input').remove();
        $('.box-upload-file--review form').html('<input name="file_video" class="video_file" type="file">');

        $('.box-upload-file--review .btn-upload-show-video').attr('data-originalUrl', '');
        $('.box-upload-file--review .btn-upload-show-video').css('display', 'none');

        Swal.fire({
            type: 'warning',
            text: 'Luồng upload Review đã bị hủy. Vui lòng tạo luồng mới!',
        });
    });

    //Create Series Movie Casing
    $('.add-series-movie .btn-create-movie').click(function (e){
        e.preventDefault();
        // let file = $('.box-upload-file--trailer .video_file').val();
        let trailerUrl = $('.add-series-movie .upload-video-step-2 input[name="trailerUrl"]').val();
        if (trailerUrl != '')
        {
            if($('.form-create-movie').valid() == true) {
                $('.btn-create-movie').attr('disabled', 'disabled');
                $('.form-create-movie').submit();
            }
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Đang tải tệp Trailer hoặc Trailer chưa được chọn.',
            });
        }
    });
    //Edit Series Movie Casing
    $('.add-series-movie .btn-edit-movie').click(function (e){
        e.preventDefault();
        if($('.form-create-movie').valid() == true) {
            // $('.btn-edit-movie').attr('disabled', 'disabled');
            $('.form-create-movie').submit();
        }
    });

    $(document).on('click', '.radio-package .custom-control-label', function (e) {
        e.preventDefault();
        $('.radio-package input').removeAttr('checked');
        $(this).parents('.custom-radio').find('input').attr('checked', 'checked');
    });


    // Delete Track
    $(document).on('click', '.delete_track', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let track = $(this).attr('data-track');
        let file = $(this).attr('data-file');
        let href = window.location.href;
        console.log(file);

        Swal.fire({
            title: 'Xóa phụ đề',
            text: 'Bạn muốn xóa phụ đề?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/delete-track',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        file: file,
                        tracks: track,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.upload-video-step-2 .input-track input').each(function( index ) {
                                let idT = $(this).attr('data-id');
                                if (idT == file){
                                    $(this).remove();
                                }
                            });
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    $(document).on('change', '#update__cp', function (e) {
        e.preventDefault();
        let $this = $(this);
        let postId = $this.attr('data-id');
        let value = $this.attr('data-value');
        let cp = $this.val();
        let href = window.location.href;

        Swal.fire({
            title: 'Thay đổi CP',
            text: 'Bạn muốn thay đổi cp của video này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/update-cp',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        postId: postId,
                        cp: cp,
                    },
                    success:function (data){
                        console.log(data);
                        console.log(value);
                        if (data.error){
                            $this.selectpicker('val', value);
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire({
                                type: 'success',
                                title: 'Success',
                                text: data.success,
                            });
                        }else {
                            $this.selectpicker('val', value);
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }else{
                $this.selectpicker('val', value);
            }
        });
    });
})(jQuery);
