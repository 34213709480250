(function ($) {
    $(document).on('change', '.upload-series-movie #video_list_acc_cp',function () {
        let $this = $(this);
        let cp = $(this).val();
        if (cp && cp.length > 0)
        {
            $('.upload-series-movie .search-select-ajax_series-movie').css('display', 'block');
            $('input[name="cpId"]').val(cp);
        }else{
            $('.upload-series-movie .search-select-ajax_series-movie').css('display', 'none');
            $('.box-list--add-film').css('display', 'none');
            $('input[name="postId"]').val('');
        }
    });
    // Search video account CP (Create video)
    $(document).on('change', '.upload-series-movie #staffId_cp', function (e) {
        e.preventDefault();
        $('.upload-series-movie .search-select-ajax_series-movie').css('display', 'none');
        $('.box-list--add-film').css('display', 'none');
        $('input[name="postId"]').val('');
        $('.upload-series-movie .js-data-series-movie').attr('data-id', '');
        let cp = $(this).val();
        $('.video-account-cp div').remove();
        $('.upload-series-movie .js-data-series-movie').attr('data-id', cp);
        let $this = $(this);
        let id = $('#staffId_cp').val();
        console.log(id);
        $('.video-account-cp').html('<select name="staffId_acc_cp" id="video_list_acc_cp" required data-msg="Chưa chọn tài khoản" class="form-control" data-live-search="true" title="Chọn tài khoản CP"></select>');

        //AJAX get info CP
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/upload-series-movie/search-info-cp',{
            type: 'POST',
            dataType: 'json',
            data: {
                id: id,
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    $('#video_list_acc_cp').removeAttr('disabled');
                    $('#video_list_acc_cp').attr('title', data.error);
                    $('#video_list_acc_cp').selectpicker();
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else if(data.success) {
                    $('#video_list_acc_cp').attr('title', 'Chọn tài khoản CP');
                    $('#video_list_acc_cp').removeAttr('disabled');
                    $('#video_list_acc_cp').html(data.success.account);
                    $('#video_list_acc_cp').selectpicker();
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    $(document).on('change', '.upload-series-movie .js-data-series-movie',function () {
        let $this = $(this);
        let seriesID = $(this).val();
        if (seriesID && seriesID.length > 0)
        {
            $('.list--add-video').html('');
            $('.box-clone-add-film .row.form__upload').clone().appendTo('.list--add-video');
            $('.box-list--add-film').css('display', 'block');
            $('input[name="postId"]').val(seriesID);
            $('.list--add-video .thumb-upload').html('');
            $('.list--add-video .thumb-upload').html('<input name="video_thumb_upload" type="file" class="video-thumb-upload" data-allowed-file-extensions="png jpg gif tiff jpeg bmp" data-show-remove="true">');
            $('.video-thumb-upload').dropify({
                messages: {
                    'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                    'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                    'remove':  'Hủy',
                    'error':   'Xin lỗi có lỗi đã xảy ra'
                },
                error: {
                    'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                    'minWidth': 'The image width is too small ({{ value }}}px).',
                    'maxWidth': 'The image width is too big ({{ value }}}px).',
                    'minHeight': 'The image height is too small ({{ value }}}px).',
                    'maxHeight': 'The image height is too big ({{ value }}px).',
                    'imageFormat': 'Định dạng không đúng ({{ value }}).',
                    'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
                },
            });
            $('.list--add-video .review--hashtag .bootstrap-tagsinput').remove();
            $('.list--add-video .review--hashtag input').remove();
            $('.list--add-video .review--hashtag').append('<input class="form-control video-hashtag" placeholder="Hashtag*" type="text" name="hashtag" value="" required data-msg="Bạn nhập hashtag tập">');
            $('.video-hashtag').tagsinput({
                confirmKeys: [32, 13, 44]
            });
        }else{
            $('.box-list--add-film').css('display', 'none');
            $('input[name="postId"]').val('');
        }
    });
    $(document).on('click', '.icon__add_form-film', function (e) {
        e.preventDefault();
        $('.box-clone-add-film .thumb-upload').html('');
        $('.box-clone-add-film .thumb-upload').html('<input name="video_thumb_upload" type="file" class="video-thumb-upload" data-allowed-file-extensions="png jpg gif tiff jpeg bmp" data-show-remove="true">');
        $('.box-clone-add-film .row.form__upload').clone().appendTo('.list--add-video');
        $('.video-thumb-upload').dropify({
            messages: {
                'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                'remove':  'Hủy',
                'error':   'Xin lỗi có lỗi đã xảy ra'
            },
            error: {
                'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                'minWidth': 'The image width is too small ({{ value }}}px).',
                'maxWidth': 'The image width is too big ({{ value }}}px).',
                'minHeight': 'The image height is too small ({{ value }}}px).',
                'maxHeight': 'The image height is too big ({{ value }}px).',
                'imageFormat': 'Định dạng không đúng ({{ value }}).',
                'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
            },
        });
        $('.list--add-video .review--hashtag .bootstrap-tagsinput').remove();
        $('.list--add-video .review--hashtag input').remove();
        $('.list--add-video .review--hashtag').append('<input class="form-control video-hashtag" placeholder="Hashtag*" type="text" name="hashtag" value="" required data-msg="Bạn nhập hashtag tập">');
        $('.video-hashtag').tagsinput({
            confirmKeys: [32, 13, 44]
        });
    });
    $(document).on('click', '.icon__delete_box', function (e) {
        e.preventDefault();
        $(this).parents('.form__upload').remove();
    });
    // Upload Trailer JS thuần sử dụng axios
    const CancelToken = axios.CancelToken;
    let cancelUploadSeriesMovie;
    $(document).on('change', '.upload-series-movie .upload-video-step-1 .video_file',function (e) {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);

        $this.parents('.form__upload').find('.film--upload .name--file').html($this[0].files[0]['name']);
        $(this).parents('.form__upload').find('.upload-video-step-2 input[name=originUrl]').val('');
        $(this).parents('.form__upload').find('.upload-video-step-2 input[name=previewUrl]').val('');
        $(this).parents('.form__upload').find('.upload-video-step-2 input[name=duration]').val('');
        $(this).parents('.form__upload').find('.upload-video-step-2 input[name=uploadId]').val('');
        $(this).parents('.form__upload').find('.upload-video-step-2 input[name=nameFile]').val('');

        // Get Duration Video
        document.getElementById('audio').setAttribute('src', '');
        let file = e.currentTarget.files[0];
        let obUrl = URL.createObjectURL(file);
        document.getElementById('audio').setAttribute('src', obUrl);
        console.log('file');

        let f_duration = 0;
        document.getElementById('audio').addEventListener('canplaythrough', function(f){
            f_duration = Math.round(f.currentTarget.duration);
            console.log(f_duration);
            $this.parents('.form__upload').find('.upload-video-step-2 input[name=duration]').val(f_duration);
        });
        //End

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/upload-series-movie/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    // $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                    // $('#addnew_video').modal('hide');
                }
                else if(res.success) {
                    $this.attr('disabled', 'disabled');
                    $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');

                    console.log($this[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $this[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.form__upload').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.form__upload').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.form__upload').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.form__upload').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelUploadSeriesMovie = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $this.parents('.form__upload').find('.progress .progress-bar').css('width','100%');
                            $this.parents('.form__upload').find('.progress .progress-bar').text('Done!');
                            $this.parents('.form__upload').find('.upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $this.parents('.form__upload').find('.upload-video-step-2 input[name=originUrl]').val(item.data.url);
                            $this.parents('.form__upload').find('.upload-video-step-2 input[name=previewUrl]').val(item.data.url);
                            // $this.parents('.form__upload').find('.upload-video-step-2 input[name=duration]').val(item.data.duration);
                            $this.parents('.form__upload').find('.upload-video-step-2 input[name=uploadId]').val(item.data.contentId);
                            $this.parents('.form__upload').find('.upload-video-step-2 input[name=nameFile]').val(item.data.fileName);
                            $this.parents('.form__upload').find('.btn-upload-show-video').attr('data-originalUrl', item.data.url);
                            $this.parents('.form__upload').find('.btn-upload-show-video').css('display', 'block');
                            $this.removeClass('cancel');

                        }else {
                            console.log(123);
                            $this.removeAttr('disabled');
                            $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                            $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                            $this.parents('.form__upload').find('.film--upload .name--file').html('');
                            // $this.parents('.form__upload .upload-video-step-1').find('form input[type="file"]').val('');
                            $this.val('');
                            $this.removeClass('cancel');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                        $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                        $this.parents('.form__upload').find('.film--upload .name--file').html('');
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                        $this.val('');
                        $this.removeClass('cancel');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                    $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                    $this.parents('.form__upload').find('.film--upload .name--file').html('');
                    $this.val('');
                    $this.removeClass('cancel');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });

    $(document).on('click','.upload-series-movie .upload--cancel',function () {
        let $this = $(this);
        $('.footer .overlay').addClass('overlaing');
        let contentId = $this.parents('.form__upload').find('.upload-video-step-2 input[name=uploadId]').val();
        let fileName = $this.parents('.form__upload').find('.upload-video-step-2 input[name=nameFile]').val();
        console.log(contentId);
        console.log(fileName);
        if (fileName && fileName.length > 0){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/odd-movie/delete-file',{
                type: 'POST',
                dataType: 'json',
                data: {
                    contentId: contentId,
                    fileName: fileName,
                },
                success:function (data){
                    console.log(data);
                    $('.footer .overlay').removeClass('overlaing');
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    }else{
                        $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').addClass('cancel');
                        cancelUploadSeriesMovie();
                        $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
                        $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
                        $this.parents('.form__upload').find('.name--file').html('');
                        $this.parents('.form__upload').find('.upload-video-step-2 input[name=originUrl]').val('');
                        $this.parents('.form__upload').find('.upload-video-step-2 input[name=previewUrl]').val('');
                        $this.parents('.form__upload').find('.upload-video-step-2 input[name=duration]').val('');
                        $this.parents('.form__upload').find('.upload-video-step-2 input[name=uploadId]').val('');
                        $this.parents('.form__upload').find('.upload-video-step-2 input[name=nameFile]').val('');

                        $this.parents('.form__upload').find('.btn-upload-show-video').attr('data-originalUrl', '');
                        $this.parents('.form__upload').find('.btn-upload-show-video').css('display', 'none');
                        $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').val('');

                        Swal.fire({
                            type: 'warning',
                            text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
                        });
                    }
                }
            });
        }else{
            $('.footer .overlay').removeClass('overlaing');
            $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').addClass('cancel');
            cancelUploadSeriesMovie();
            $this.parents('.form__upload').find('.progress .progress-bar').css('width','0%');
            $this.parents('.form__upload').find('.progress .progress-bar').html('0%');
            $this.parents('.form__upload').find('.name--file').html('');
            $this.parents('.form__upload').find('.upload-video-step-2 input[name=originUrl]').val('');
            $this.parents('.form__upload').find('.upload-video-step-2 input[name=previewUrl]').val('');
            $this.parents('.form__upload').find('.upload-video-step-2 input[name=duration]').val('');
            $this.parents('.form__upload').find('.upload-video-step-2 input[name=uploadId]').val('');
            $this.parents('.form__upload').find('.upload-video-step-2 input[name=nameFile]').val('');

            $this.parents('.form__upload').find('.btn-upload-show-video').attr('data-originalUrl', '');
            $this.parents('.form__upload').find('.btn-upload-show-video').css('display', 'none');
            $this.parents('.form__upload').find('.upload-video-step-1 form input[type="file"]').val('');

            Swal.fire({
                type: 'warning',
                text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
            });
        }
    });

    // Delete file video uploaded when load
    window.onbeforeunload = function(){
        let hasClass = document.getElementsByClassName("unload--delEpisodeMovie");
        if(hasClass.length){
            $('.box-list--add-film .list--add-video .form__upload').each(function( index ) {
                let $this = $(this);
                let contentId = $this.find('.upload-video-step-2 input[name="uploadId"]').val();
                let fileName = $this.find('.upload-video-step-2 input[name="nameFile"]').val();
                console.log(contentId);
                console.log(fileName);
                if (fileName && fileName.length > 0){
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/odd-movie/delete-file',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            contentId: contentId,
                            fileName: fileName,
                        },
                        success:function (data){
                            console.log(data);
                            $('.footer .overlay').removeClass('overlaing');
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                });
                            }else{
                                $this.find('.upload-video-step-1 form input[type="file"]').addClass('cancel');
                                $this.find('.progress .progress-bar').css('width','0%');
                                $this.find('.progress .progress-bar').html('0%');
                                $this.find('.name--file').html('');
                                $this.find('.upload-video-step-2 input[name=originUrl]').val('');
                                $this.find('.upload-video-step-2 input[name=previewUrl]').val('');
                                $this.find('.upload-video-step-2 input[name=duration]').val('');
                                $this.find('.upload-video-step-2 input[name=uploadId]').val('');
                                $this.find('.upload-video-step-2 input[name=nameFile]').val('');

                                $this.find('.btn-upload-show-video').attr('data-originalUrl', '');
                                $this.find('.btn-upload-show-video').css('display', 'none');
                                $this.find('.upload-video-step-1 form input[type="file"]').val('');
                                Swal.fire({
                                    type: 'warning',
                                    text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
                                });
                            }
                        }
                    });
                }
            });
        }
    };

    $(document).on('click', '.upload-series-movie .btn-create-movie', function (e) {
        e.preventDefault();
        let $this = $(this);
        let postId =  $this.parents('.form__upload').find('input[name="postId"]').val();
        let uploadId =  $this.parents('.form__upload').find('input[name="uploadId"]').val();
        console.log(uploadId.length);
        // if (postId.length == 0)
        // {
        //     Swal.fire({
        //         type: 'error',
        //         title: 'Error',
        //         text: 'Hãy chọ phim bộ.',
        //     });
        // }
        // if(uploadId.length == 0){
        //     Swal.fire({
        //         type: 'error',
        //         title: 'Error',
        //         text: 'Đang tải phim hoặc phim chưa được chọn.',
        //     });
        // }
        // else{
        if($this.parents('.upload-video-step-2').find('.form-create-movie').valid() == true) {
            $this.attr('disabled', 'disabled');
            $this.addClass('operation');
            $this.parents('.upload-video-step-2').find('.icon__delete_box').addClass('operation');
            $this.parents('.upload-video-step-2').find('.form-create-movie').submit();
        }
        // }
    });
    //
    $(document).ready(function () {
        $(document).on('submit', '.upload-series-movie .form-create-movie', function (e) {
            e.preventDefault();
            let $this = $(this);
            $this.ajaxSubmit({
                success: function(res, statusText, xhr, $form) {
                    console.log(res);
                    let href = window.location.href;
                    if (res.error){
                        $('.footer .overlay').removeClass('overlaing');
                        $('.list--add-video .btn-create-movie').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeAttr('disabled');
                            }
                        });
                        $('.list--add-video .icon__delete_box').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeClass('operation');
                            }
                        });
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: res.error,
                        });
                    }
                    else if (res.success) {
                        $('.footer .overlay').removeClass('overlaing');
                        $('.page-add-movie input[name="originUrl"]').val('');
                        $('.page-add-movie input[name="previewUrl"]').val('');
                        $('.page-add-movie input[name="uploadId"]').val('');
                        // $('.page-add-movie input[name="duration"]').val('');
                        // $('.page-add-movie input[name="nameFile"]').val('');
                        $('.list--add-video .btn-create-movie').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).remove();
                            }
                        });
                        $('.list--add-video .icon__delete_box').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).remove();
                            }
                        });
                        Swal.fire(
                            'Thành công!',
                            res.success,
                            'success'
                        );
                    }else {
                        $('.footer .overlay').removeClass('overlaing');
                        $('.list--add-video .btn-create-movie').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeAttr('disabled');
                            }
                        });
                        $('.list--add-video .icon__delete_box').each(function( index ) {
                            let hClass = $(this).hasClass('operation');
                            if(hClass){
                                $(this).removeClass('operation');
                            }
                        });
                        Swal.fire(
                            'Error!',
                            res.logged,
                            'error'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL);
                        });
                    }
                }
            });
        });
    });
    //Search ajax load more
    function formatRepo (repo)
    {
        let markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.title + "</div></div></div>";
        return markup;
    }
    function formatRepoSelectionSeriesMovie (repo) {
        return repo.title || repo.text;
    }
    $('.js-data-series-movie').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/upload-series-movie/get-series-movie-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    cpid: $(this).attr('data-id'),
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data.content;
                        $.each(result, function( index, value ) {
                            result['name'] = value['title'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (params.page * 10) < data.data.data.totalElements
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Lựa chọn phim bộ',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionSeriesMovie
    });

    //Edit episode series movie
    $(document).on('click', '.btn_edit_episode_series_movie', function (e) {
        e.preventDefault();
        $('input[name="episodeId"]').val('');
        $('input[name="edit_title"]').val('');
        $('input[name="edit_titleEnglish"]').val('');
        $('input[name="edit_number"]').val('');
        $('input[name="edit_imdb_url"]').val('');
        $('input[name="edit_imdb_point"]').val('');
        $('#modal_edit_episode_series_movie .thumb-upload .dropify-wrapper').remove();
        $('#modal_edit_episode_series_movie .thumb-upload').html('<input name="edit_video_thumb_upload" type="file" class="video-thumb-upload-episode" data-show-remove="true" data-default-file="" data-allowed-file-extensions="png jpg gif tiff jpeg bmp">');
        $('textarea[name="edit_des"]').html('');
        $('textarea[name="edit_desEnglish"]').html('');
        $('input[name="edit_hashtag_video"]').tagsinput('removeAll');

        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let titleEnglish = $(this).attr('data-titleEnglish');
        let number = $(this).attr('data-number');
        let des = $(this).attr('data-des');
        let desEnglish = $(this).attr('data-desEnglish');
        let thumb = $(this).attr('data-thumb2');
        let imdbUrl = $(this).attr('data-imdbUrl');
        let imdbPoint = $(this).attr('data-imdbPoint');
        var hashtag = $(this).attr('data-hashtag');

        $('input[name="episodeId"]').val(id);
        $('input[name="edit_title"]').val(title);
        $('input[name="edit_titleEnglish"]').val(titleEnglish);
        $('input[name="edit_number"]').val(number);
        $('input[name="edit_imdb_url"]').val(imdbUrl);
        $('input[name="edit_imdb_point"]').val(imdbPoint);
        $('input[name="edit_hashtag"]').tagsinput('add', hashtag);
        $('input[name="edit_video_thumb_upload"]').attr('data-default-file', thumb);
        $('textarea[name="edit_des"]').html(des);
        $('textarea[name="edit_desEnglish"]').html(desEnglish);
        $('#modal_edit_episode_series_movie .video-thumb-upload-episode').dropify({
            messages: {
                'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                'remove':  'Hủy',
                'error':   'Xin lỗi có lỗi đã xảy ra'
            },
            error: {
                'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                'minWidth': 'The image width is too small ({{ value }}}px).',
                'maxWidth': 'The image width is too big ({{ value }}}px).',
                'minHeight': 'The image height is too small ({{ value }}}px).',
                'maxHeight': 'The image height is too big ({{ value }}px).',
                'imageFormat': 'Định dạng không đúng ({{ value }}).',
                'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
            },
        });

        $('#modal_edit_episode_series_movie').modal('show');
    });
    //Btn submit Edit episode series movie
    $(document).on('click', '#form_btn_edit_episode_series_movie', function (e) {
        e.preventDefault();
        if($('#form-edit-video').valid() == true) {
            $('#form-edit-video').submit();
        }
    });
    $('.video-thumb-upload-episode').dropify({
        messages: {
            'default': 'Hình ảnh thu nhỏ tùy chỉnh',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });

    // Upload Track
    $(document).on('click', '.box-track-upload-series-movie .icon__add_form-file-track_series', function (e) {
        e.preventDefault();
        let $this = $(this);
        // $('.clone-box-track-upload-series-movie .box-upload-file--track').clone().appendTo('.list--add-video .list-box-track-upload-series-movie');
        let clone = $('.clone-box-track-upload-series-movie .box-upload-file--track').clone();
        $(this).parents('.form__upload').find('.list-box-track-upload-series-movie').append(clone);

        if ($this.parents('.form__upload').find('.box-upload-file--track').length > 0)
        {
            $this.parents('.form__upload').find('.btn__addBoxTrack').css('display', 'none');
            let countBox = $this.parents('.form__upload').find('.box-upload-file--track').length;

            $this.parents('.form__upload').find('.box-upload-file--track').each(function( index ) {
                $(this).find('.icon__add_form-file-track_series').remove();
                let text = '<i class="fa fa-plus-circle pull-right cursor-pointer icon__add_form-file-track_series" style="color: #f79431;" title="Thêm box file track"></i>';
                if ((index + 1) == countBox)
                {
                    $(this).find('.manipulation').append(text);
                }
            });
        }
        else{
            $this.parents('.form__upload').find('.btn__addBoxTrack').css('display', 'block');
        }
    });
    $(document).on('click', '.list-box-track-upload-series-movie .icon__delete_box_track_series', function (e) {
        e.preventDefault();
        let $this = $(this);
        let id = $this.attr('data-id');
        if (id.length > 0)
        {
            // $(this).parents('.form__upload').find('.upload-video-step-2 .input-track input[data-id="'+id+'"]').remove();
            $this.parents('.form__upload').find('.upload-video-step-2 .input-track input').each(function( index ) {
                let idT = $this.attr('data-id');
                if (idT == id){
                    $this.remove();
                }
            });
        }
        if (($this.parents('.form__upload').find('.box-upload-file--track').length - 1) > 0)
        {
            $this.parents('.form__upload').find('.btn__addBoxTrack').css('display', 'none');
            let countBox = $this.parents('.form__upload').find('.box-upload-file--track').length - 1;

            $this.parents('.form__upload').find('.box-upload-file--track').each(function( index ) {
                $(this).find('.icon__add_form-file-track_series').remove();
                let text = '<i class="fa fa-plus-circle pull-right cursor-pointer icon__add_form-file-track_series" style="color: #f79431;" title="Thêm box file track"></i>';
                if (index == (countBox - 1))
                {
                    $(this).find('.manipulation').append(text);
                }
            });
        }
        else{
            $this.parents('.form__upload').find('.btn__addBoxTrack').css('display', 'block');
        }
        $this.parents('.box-upload-file--track').remove();
    });
    // Upload Track JS thuần sử dụng axios
    $(document).on('change', '.upload-series-movie .box-track-upload-series-movie .upload-video-step-1 .video_file_track',function () {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $this.attr('disabled', 'disabled');
        $this.parents('.box-upload-file--track').find('.name--file').html($this[0].files[0]['name']);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/getTokenTrack',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if(res.success) {
                    let token = res.success;
                    // $this.parents('.box-upload-file--track').find('.progress .progress-bar').css('width','0%');
                    console.log($this[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $this[0].files[0]);
                    axios.post( res.api_upload,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': token
                            },
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            console.log('SUCCESS!!');
                            $this.parents('.box-upload-file--track').find('input[name="urlTrack"]').val(item.data);
                            $this.parents('.box-upload-file--track').find('.btn-add-track').attr('data-id', item.data);
                            $this.parents('.box-upload-file--track').find('.icon__delete_box_track_series').attr('data-id', item.data);
                        }else {
                            $this.removeAttr('disabled');
                            $this.parents('.box-upload-file--track').find('.name--file').html('');
                            $this.parents('.box-upload-file--track').find('form input[type="file"]').val('');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $this.parents('.box-upload-file--track').find('.name--file').html('');
                        $this.parents('.box-upload-file--track').find('form input[type="file"]').remove();
                        $this.parents('.box-upload-file--track').find('form').append('<input name="file_track" class="video_file" type="file">');
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Lỗi kết lối server!',
                        });
                        console.log('FAILURE!!');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    //Click btn submit form add Track
    $(document).on('click', '.box-track-upload-series-movie .btn-add-track', function (e) {
        e.preventDefault();
        let $this = $(this);
        let id = $this.attr('data-id');
        let url =  $this.parents('.box-upload-file--track').find('input[name="urlTrack"]').val();
        let title =  $this.parents('.box-upload-file--track').find('input[name="titleTrack"]').val();
        if (url.length == 0)
        {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Không tồn tại url file. Vui lòng chọn lại file.',
            });
        }
        else if (title.length == 0)
        {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy nhập tiêu đề phụ đề.',
            });
        }
        else{
            let track = {
                'file': url,
                'label': title
            };
            let json = JSON.stringify(track);
            $this.parents('.box-upload-file--track').find('input[name="titleTrack"]').attr('disabled', 'disabled');
            // $(this).parents('.form__upload').find('.upload-video-step-2 .input-track').append('<input type="hidden" name="tracks[]" value=\'' + json + '\' data-id="' + id + '">');
            $(this).parents('.form__upload').find('.upload-video-step-2 .input-track').append('<input type="hidden" class="tracking" name="tracks[]" value="" data-id="' + id + '">');
            $(this).parents('.form__upload').find('.upload-video-step-2 .input-track .tracking').val(json);
            $(this).parents('.form__upload').find('.upload-video-step-2 .input-track input').removeClass('tracking');
            $this.remove();
            Swal.fire(
                'Thành công!',
                'Thêm thành công phụ đề.',
                'success'
            );
        }
    });
    $('.selectpicker').selectpicker();
})(jQuery);
