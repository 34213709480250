(function ($) {
    function importFileDropify(){
        $('.import-file-movie').dropify({
            messages: {
                'default': 'Chọn tệp import phim',
                'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                'remove':  'Hủy',
                'error':   'Xin lỗi có lỗi đã xảy ra'
            },
            error: {
                'fileSize': 'File quá lớn ({{ value }} max).',
                'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
            },
        });
    }
    importFileDropify();
    // Import Odd Movie JS thuần sử dụng axios
    $(document).on('click', '.import-movie .box-import-file-movie .btn-import-file-movie',function () {
        $('.footer .overlay').addClass('overlaing');
        let type = $('.import-movie .box-import-file-movie select[name=typeImport]').val();
        let file = $(".import-movie .box-import-file-movie .import-file-movie")[0].files[0];
        console.log('Import odd movie');
        console.log(type);
        if (type && type > 0){
            if (file){
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/import-movie/getToken-movie',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        type: type
                    },
                    success:function (res){
                        console.log(res);
                        if (res.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: res.error,
                            });
                        }
                        else {
                            let token = res.success;
                            console.log(file);
                            let formData = new FormData();
                            formData.append("file", file);
                            axios.post( res.api,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'token': token
                                    },
                                }
                            ).then(function(data){
                                $('.footer .overlay').removeClass('overlaing');
                                console.log(data);
                                $('.import-movie .box-import-file-movie .import__movie form .thumb-upload .dropify-wrapper').remove();
                                $('.import-movie .box-import-file-movie .import__movie form .thumb-upload').append('<input name="import_movie" type="file" class="import-file-movie" data-allowed-file-extensions="xls xlsx" data-show-remove="true" required data-msg="Chưa chọn file">');
                                importFileDropify();
                                if (data.data.code == 200){
                                    Swal.fire({
                                        type: 'success',
                                        title: 'Success',
                                        text: 'Import phim thành công',
                                    });
                                }else {
                                    Swal.fire({
                                        type: 'error',
                                        title: 'Error',
                                        text: data.data.msg,
                                    });
                                }
                            }).catch(function(){
                                $('.footer .overlay').removeClass('overlaing');
                                $('.import-movie .box-import-file-movie .import_movie form .thumb-upload .dropify-wrapper').remove();
                                $('.import-movie .box-import-file-movie .import_movie form .thumb-upload').append('<input name="import_movie" type="file" class="import-file-movie" data-allowed-file-extensions="xls xlsx" data-show-remove="true" required data-msg="Chưa chọn file">');
                                importFileDropify();
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: 'Lỗi kết lối server!',
                                });
                                console.log('FAILURE!!');
                            });
                        }
                    }
                });
            }else{
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Hãy chọn file import.',
                });
            }
        }else{
            $('.footer .overlay').removeClass('overlaing');
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn kiểu file import.',
            });
        }
    });

})(jQuery);
