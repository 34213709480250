(function ($) {
    $(document).ready(function(){
        $('#smartwizard').smartWizard({
            selected: 0,
            theme: 'dots',
            autoAdjustHeight:true,
            transitionEffect:'fade',
            showStepURLhash: false,
        });
    });
})(jQuery);
