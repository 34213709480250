(function ($) {
    // Create Kind
    $('#form-create-kind').ajaxForm({
        success: function (res, statusText, xhr, $form) {
            console.log(res);
            $('#btn-create-kind').removeAttr('disabled');
            if (res.error) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            } else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', BASE_URL + '/kind-manager');
                });
            } else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('#btn-create-kind').click(function (e) {
        e.preventDefault();
        if ($('#form-create-kind').valid() == true) {
            $('#btn-create-kind').attr('disabled', 'disabled');
            $('#form-create-kind').submit();
        }
    });

    // Delete kind
    $(document).on('click', '.btn-delete_kind', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Delete kind?',
            text: "Bạn muốn Xóa thể loại này?!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/delete', {
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success: function (data) {
                        console.log(data);
                        if (data.error) {
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        } else {
                            Swal.fire(
                                'Thành công!',
                                'Xóa thành công.',
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });

    // Hide kind
    $(document).on('click', '.btn-hide_kind', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        var msg = "Bạn muốn thực hiện hành động này?"

        Swal.fire({
            title: 'Thông báo',
            text: msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/show-hide', {
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id
                    },
                    success: function (data) {
                        console.log(data);
                        if (data.error) {
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        } else {
                            Swal.fire(
                                'Thành công!',
                                'Thành công.',
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });


    // Click input upload image create
    $('#btn-upload-img-create-kind').click(function (e) {
        e.preventDefault();
        $('#img_kind').click();
    });
    // Show Image create
    $('#img_kind').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('show-img-kind');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-create-kind').html(e.target.files[0].name);
        $('#show-img-kind').css('display', 'block');
    });

    // Edite kind
    $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-kind').ajaxForm({
            success: function (res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-edit-kind').removeAttr('disabled');
                if (res.error) {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                } else if (res.success) {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                } else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('#btn-edit-kind').click(function (e) {
            e.preventDefault();
            if ($('#form-edit-kind').valid() == true) {
                $('#btn-edit-kind').attr('disabled', 'disabled');
                $('#form-edit-kind').submit();
            }

        });
        // Parent
        $('#form-edit-kind-parent').ajaxForm({
            success: function (res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-edit-kind-parent').removeAttr('disabled');
                if (res.error) {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                } else if (res.success) {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                } else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('#btn-edit-kind-parent').click(function (e) {
            e.preventDefault();
            if ($('#form-edit-kind-parent').valid() == true) {
                $('#btn-edit-kind-parent').attr('disabled', 'disabled');
                $('#form-edit-kind-parent').submit();
            }

        });
    });

    // Click button edit
    $(document).on('click', '.btn-edit-kind', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let name = $(this).attr('data-name');
        let type = $(this).attr('data-type');
        // let img = $(this).attr('data-img');

        $('#edit_kind input[name="id_kind"]').val(id);
        $('#edit_kind input[name="edit_name_kind"]').val(name);
        $('#edit_kind select[name="edit_type_kind"]').val(type);
        // $('#edit_kind input[name="old_img"]').val(img);
        //
        // var output_o = document.getElementById('edit-show-img-kind');
        // output_o.src = img;

        $('#edit_kind').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-kind').click(function (e) {
        e.preventDefault();
        $('#edit_img_kind').click();
    });
    // Show Image edit
    $('#edit_img_kind').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('edit-show-img-kind');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-kind').html(e.target.files[0].name);
        $('#edit-show-img-kind').css('display', 'block');
    });
    ///// Parent
    // Click button edit
    $(document).on('click', '.btn-edit-kind-parent', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let name = $(this).attr('data-name');
        // let img = $(this).attr('data-img');

        $('#edit_kind_parent input[name="id_kind"]').val(id);
        $('#edit_kind_parent input[name="edit_name_kind"]').val(name);
        // $('#edit_kind_parent input[name="old_img"]').val(img);
        //
        // var output_o = document.getElementById('edit-show-img-kind-parent');
        // output_o.src = img;

        $('#edit_kind_parent').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-kind-parent').click(function (e) {
        e.preventDefault();
        $('#edit_img_kind_parent').click();
    });
    // Show Image edit
    $('#edit_img_kind_parent').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('edit-show-img-kind-parent');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-kind-parent').html(e.target.files[0].name);
        $('#edit-show-img-kind-parent').css('display', 'block');
    });

    // Hidden kind
    $(document).on('click', '.hidden-kind', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden category',
            text: 'Bạn muốn ẩn thể loại?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/hidden', {
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success: function (data) {
                        console.log(data);
                        if (data.error) {
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        } else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        } else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show kind
    $(document).on('click', '.show-kind', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show category',
            text: 'Bạn muốn hiện thể loại?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/kind-manager/show', {
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success: function (data) {
                        console.log(data);
                        if (data.error) {
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        } else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        } else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Click button Show Supper App video
    $(document).on('click', '.btn-show-kind-supperApp', function (e) {
        e.preventDefault();
        $('#kind_show_supperApp .custom-control-input').prop("checked", false);

        let id = $(this).attr('data-id');
        let type = $(this).attr('data-type');
        let name = $(this).attr('data-name');
        let special = $(this).attr('data-special');
        let series = $(this).attr('data-series');
        let single = $(this).attr('data-single');
        //Special
        if (special == true) {
            $('#kind_show_supperApp #specialSupperApp1').prop("checked", true);
        } else {
            $('#kind_show_supperApp #specialSupperApp2').prop("checked", true);
        }
        if (type == 1) {
            $('#kind_show_supperApp .config_single').removeClass('d-none');
            $('#kind_show_supperApp .config_series').addClass('d-none');
            //Single
            if (single == true) {
                $('#kind_show_supperApp #singleSupperApp1').prop("checked", true);
            } else {
                $('#kind_show_supperApp #singleSupperApp2').prop("checked", true);
            }
        } else if (type == 2) {
            $('#kind_show_supperApp .config_series').removeClass('d-none');
            $('#kind_show_supperApp .config_single').addClass('d-none');
            //Series
            if (series == true) {
                $('#kind_show_supperApp #seriesSupperApp1').prop("checked", true);
            } else {
                $('#kind_show_supperApp #seriesSupperApp2').prop("checked", true);
            }
        }
        $('#kind_show_supperApp input[name="kindId"]').val(id);
        $('#kind_show_supperApp input[name="type"]').val(type);
        $('#kind_show_supperApp .modal-title').html(name);

        $('#kind_show_supperApp').modal('show');
    });
})(jQuery);
