$(document).ready(function () {
        $('.change-pass').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL + '/home');
                    });
                }
            }
        });
        $('#btn-change-pass').click(function (e){
            e.preventDefault();
            if($('#btn-change-pass').valid() == true){
                $('#btn-change-pass').submit();
            }
        });
    });