(function ($) {
    $(document).ready(function() {
        if ($('textarea.summernote').length) {
            $('textarea.summernote').summernote({
                height: 150,
                popover: {
                    image: [],
                    link: [],
                    air: []
                }
            });
        }
    });
})(jQuery);
