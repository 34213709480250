(function ($) {

    $('.info-personal .avatar').dropify({
        messages: {
            'default': 'Hình ảnh avatar',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove': 'Hủy',
            'error': 'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });
    $('.playlist__thumb').dropify({
        messages: {
            'default': 'Hình ảnh thu nhỏ playlist',
            'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
            'remove':  'Hủy',
            'error':   'Xin lỗi có lỗi đã xảy ra'
        },
        error: {
            'fileSize': 'Ảnh quá lớn ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px).',
            'maxWidth': 'The image width is too big ({{ value }}}px).',
            'minHeight': 'The image height is too small ({{ value }}}px).',
            'maxHeight': 'The image height is too big ({{ value }}px).',
            'imageFormat': 'Định dạng không đúng ({{ value }}).',
            'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
        },
    });
})(jQuery);