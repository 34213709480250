(function ($) {
    // Create banner
    $(document).ready(function () {
        $('#form-create-banner').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-create-banner').removeAttr('disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL + '/banner-config');
                    });
                }
            }
        });
        $('#btn-create-banner').click(function (e){
            e.preventDefault();
            if($('#form-create-banner').valid() == true) {
                $('#btn-create-banner').attr('disabled', 'disabled');
                $('#form-create-banner').submit();
            }
        });
    });
    // Click input upload avatar create
    $('#btn-upload-img-create-banner').click(function (e) {
        e.preventDefault();
        $('#img_banner').click();
    });
    // Show Avatar create
    $('#img_banner').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('show-img-banner');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-create-banner').html(e.target.files[0].name);
        $('#show-img-banner').css('display', 'block');
    });

    // Edit banner
    $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-banner').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-edit-banner').removeAttr('disabled', 'disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }
            }
        });
        $('#btn-edit-banner').click(function (e){
            e.preventDefault();
            if($('#form-edit-banner').valid() == true){
                $('#btn-edit-banner').attr('disabled', 'disabled');
                $('#form-edit-banner').submit();
            }

        });
    });
    // Click button edit
    $(document).on('click', '.tbl-edit-banner', function (e) {
        e.preventDefault();

        let id = $(this).attr('data-id');
        let sort = $(this).attr('data-sort');

        $('input[name="id_banner"]').val(id);
        $('input[name="edit_sort"]').val(sort);

        $('#edit_banner').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-banner').click(function (e) {
        e.preventDefault();
        $('#edit_img_banner').click();
    });
    // Show Imge edit
    $('#edit_img_banner').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('edit-show-img-banner');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-banner').html(e.target.files[0].name);
        $('#edit-show-img-banner').css('display', 'block');
    });


    // Hidden banner
    $(document).on('click', '.hidden-banner', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let show = $(this).attr('data-show');
        let href = window.location.href;
        if (show > 2)
        {
            Swal.fire({
                title: 'Hidden banner',
                text: 'Bạn muốn ẩn banner?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/banner-config/hidden',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            id: id,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                            }
                        }
                    });
                }
            });
        }
        else{
            Swal.fire({
                type: 'warning',
                title: 'warning',
                text: 'Bạn không thể ẩn banner phim. Phải có ít nhất 2 banner phim hiển thị.',
            })
        }
    });
    // Show banner
    $(document).on('click', '.show-banner', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show banner',
            text: 'Bạn muốn hiện banner?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/banner-config/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Delete banner
    $(document).on('click', '.delete-banner', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Xóa banner',
            text: 'Bạn muốn xóa banner?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/banner-config/delete',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

})(jQuery);
