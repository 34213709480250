(function ($) {
    $.validator.setDefaults({
        success: "valid",
    });

    $('.upload-video').validate({
        rules:{
            video_name : "required",
            video_channel : "required",
            video_des : "required",
            video_type : "required",
            video_hashtag : "required",
        }
    });
    $('form.playlist__form').validate({
        rules:{
            playlistName : "required",
            playlistDescription : "required",
            playlist_channel : "required",
            playlist_type : "required"
        }
    });
    // Form change hashtag
    $('.config-add-video-hashtag').validate({
        rules:{
            hashtag : "required"
        },
        messages: {
            hashtag: 'Bắt buộc nhập.',
        },
    });
    // Form change password
    $('.change-pass').validate({
        rules:{
            new_pass : "required",
            change_repass :
            {
                required:true,
                equalTo: "#new_pass"
            }
        },
        messages: {
            new_pass: 'Bắt buộc nhập.',
            change_repass:{
                required: 'Bắt buộc nhập',
                equalTo: 'Nhập lại sai mật khẩu'
            }
        },
    });
    // Edit account CP
    $('#form-edit-acc-cp').validate({
        rules:{
            edit_displayname: "required",
            edit_pass: {
                minlength: 6,
            },
            edit_re_pass: {
                minlength: 6,
                equalTo: "#edit_pass"
            }
        },
        messages: {
            edit_displayname: "Bắt buộc nhập.",
            edit_pass:{
                minlength: 'Ít nhất 6 ký tự.'
            },
            edit_re_pass:{
                minlength: 'Ít nhất 6 ký tự.',
                equalTo: 'Phải trùng mật khẩu mới.'
            }
        },
    });
    // Form create banner
    $('#form-create-banner').validate({
        rules:{
            name_banner : "required",
            link_banner : {
                required: true,
                url: true
            },
            sort : {
                required: true,
                digits: true,
                min: 1,
            },
            img_banner : {
                required: true,
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            name_banner: 'Bắt buộc nhập.',
            link_banner: {
                required:'Bắt buộc nhập.',
                url: 'Không đúng định dạng link.'
            },
            sort: {
                required:'Bắt buộc nhập.',
                digits: 'Phải là số nguyên dương.',
                min: 'Phải lớn hơn 0.',
            },
            img_banner:
                {
                    required:"Chưa chọn image",
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                }
        },
    });
    // Form edit banner
    $('#form-edit-banner').validate({
        rules:{
            edit_name_banner : "required",
            edit_link_banner : {
                required: true,
                url: true
            },
            edit_sort : {
                required: true,
                digits: true,
                min: 1,
            },
            edit_img_banner : {
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            edit_name_banner: 'Bắt buộc nhập.',
            edit_link_banner: {
                required:'Bắt buộc nhập.',
                url: 'Không đúng định dạng link.'
            },
            edit_sort: {
                required:'Bắt buộc nhập.',
                digits: 'Phải là số nguyên dương.',
                min: 'Phải lớn hơn 0.',
            },
            edit_img_banner:
                {
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                }
        },
    });
    // Form create advertise
    $('#form-create-qc').validate({
        rules:{
            title: "required",
            link: {
                required: true,
                url: true
            },
            sort: {
                required: true,
                digits: true,
                min: 1,
            },
            img: {
                required: true,
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            title: 'Bắt buộc nhập.',
            link: {
                required:'Bắt buộc nhập.',
                url: 'Không đúng định dạng link.'
            },
            sort: {
                required:'Bắt buộc nhập.',
                digits: 'Phải là số nguyên dương.',
                min: 'Phải lớn hơn 0.',
            },
            img:
                {
                    required:"Chưa chọn image",
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                }
        },
    });
    // Form edit advertise
    $('#form-edit-qc').validate({
        rules:{
            edit_name: "required",
            edit_link: {
                required: true,
                url: true
            },
            edit_sort: {
                required: true,
                digits: true,
                min: 1,
            },
            edit_img_: {
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            edit_name: 'Bắt buộc nhập.',
            edit_link: {
                required:'Bắt buộc nhập.',
                url: 'Không đúng định dạng link.'
            },
            edit_sort: {
                required:'Bắt buộc nhập.',
                digits: 'Phải là số nguyên dương.',
                min: 'Phải lớn hơn 0.',
            },
            edit_img:
                {
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                }
        },
    });
    // Form create kind
    $('#form-create-kind').validate({
        rules:{
            name_kind : "required",
            sort : {
                // required: true,
                digits: true,
            },
            img_kind : {
                // required: true,
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            name_kind: 'Bắt buộc nhập.',
            sort: {
                // required:'Bắt buộc nhập.',
                digits: 'Phải là số nguyên dương.',
            },
            img_kind:
                {
                    // required:"Chưa chọn image",
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                }
        },
    });
    // Form edit kind
    $('#form-edit-kind').validate({
        rules:{
            edit_name_kind : "required",
            edit_sort : {
                required: true,
                digits: true,
            },
            edit_img_kind : {
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            edit_name_kind: 'Bắt buộc nhập.',
            edit_sort: {
                required:'Bắt buộc nhập.',
                digits: 'Phải là số nguyên dương.',
            },
            edit_img_kind:{
                extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
            }
        },
    });
    // Form create channel
    $('#form-create-chanel').validate({
        rules:{
            name_chanel : {
                required: true,
                maxlength: 25,
            },
            hashtag_chanel : "required",
            des_chanel : "required",
            kind_chanel : "required",
            avatar_chanel : {
                // required: true,
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            },
            cover_chanel : {
                // required: true,
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            name_chanel : {
                required: 'Bắt buộc nhập.',
                maxlength: 'Max là 25 ký tự.',
            },
            hashtag_chanel: 'Chưa thêm hashtag',
            avatar_chanel:
                {
                    // required:"Chưa chọn avatar",
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                },
            cover_chanel:
                {
                    // required:"Chưa chọn ảnh cover",
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                }
        },
    });
    // Form edit channel
    $('#form-edit-chanel').validate({
        rules:{
            edit_name_channel : {
                required: true,
                maxlength: 25,
            },
            edit_hashtag_channel : "required",
            edit_des_channel : "required",
            // edit_relate_channel : "required",
            edit_kind_channel : "required",
            edit_avatar_channel : {
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            },
            edit_cover_channel : {
                extension: "png|jpg|gif|tiff|jpeg|bmp"
            }
        },
        messages: {
            edit_name_channel : {
                required: 'Bắt buộc nhập.',
                maxlength: 'Max là 25 ký tự.',
            },
            edit_avatar_channel:
                {
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                },
            edit_cover_channel:
                {
                    extension: 'Không đúng định dạng ảnh(png, jpg, gif, tiff, jpeg, bmp)'
                }
        }
    });
    // Form create video
    $('.box .form-create-video').validate({
        rules:{
            sortCategory : "digits",
            sortPopular : "digits",
        },
        messages:{
            sortCategory : "Phải là số nguyên dương.",
            sortPopular : "Phải là số nguyên dương.",
        }
    });
    // // Form edit video
    $('#form-edit-video').validate({
        rules:{
            edit_sortCategory : "digits",
            edit_sortPopular : "digits",
            edit_view : {
                required:true,
                digits: true,
            },
            edit_share : {
                required:true,
                digits: true,
            },
            edit_like : {
                required:true,
                digits: true,
            },
        },
        messages:{
            edit_sortCategory : "Phải là số nguyên dương.",
            edit_sortPopular : "Phải là số nguyên dương.",
            edit_view : {
                required:"Bắt buộc nhập.",
                digits: "Phải là số nguyên dương.",
            },
            edit_share : {
                required:"Bắt buộc nhập.",
                digits: "Phải là số nguyên dương.",
            },
            edit_like : {
                required:"Bắt buộc nhập.",
                digits: "Phải là số nguyên dương.",
            },
        }
    });

    $('.playlist-search-url').validate({
        rules:{
            urlVideo : {
                required:true,
                url: true
            },
        },
        messages: {
            urlVideo:
                {
                    required:"Chưa có URL",
                    url: 'Không phải là URL'
                }
        },
    });
    // Form search báo cáo hiệu quả tương tác theo nội dung
    $('#form-report-content').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                // greaterThan: '#report-content-search-form'
                dateGreaterThan: '#report-content-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo hiệu quả tương tác theo người sử dụng
    $('#form-report-user').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-user-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo hiệu quả tương tác theo CP
    $('#form-report-cp').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-cp-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo doanh thu phân chia theo CP
    $('#form-report-revenue-cp').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-revenue-cp-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo doanh thu phân chia sản lượng theo gói
    $('#form-report-revenue-quantity').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-revenue-quantity-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo doanh thu phân gói theo gói
    $('#form-report-package').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-package-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo doanh thu phân gói theo cú pháp
    $('#form-report-package-syntax').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-package-syntax-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo doanh thu phân gói theo bậc thang
    $('#form-report-package-step').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-package-step-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo doanh thu phân gói tổng hợp
    $('#form-report-package-synthetic').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required:true,
                dateGreaterThan: '#report-package-synthetic-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                }
        },
    });
    // Form search báo cáo View
    $('#form-report-view').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required: true,
                dateGreaterThan: '#report-view-search-form'
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to: {
                required:"Bắt buộc chọn.",
            }
        },
    });
    $.validator.addMethod("dateGreaterThan",
        function(value, element, params) {
            if ($(params).val() <= value)
                return true;
            else
                return false;
                // return $(params).val() <= value;
    }, 'Phải lớn hơn hoặc bằng ngày bắt đầu.');


    // Form create cp
    $('#form-create-cp').validate({
        rules:{
            create_name_cp : 'required',
            create_name_cp_short : 'required',
            create_cp_mst : 'required',
            create_cp_address : 'required',
            create_cp_person : 'required',
            create_cp_kd : 'required',
            create_cp_email : {
                required:true,
                email: true,
            },
            create_cp_phone : {
                required:true,
                digits: true,
            },
            create_cp_revenueRate : {
                required:true,
                number: true,
                min: 0,
            },
        },
        messages: {
            create_name_cp: 'Bắt buộc nhập.',
            create_name_cp_short: 'Bắt buộc nhập.',
            create_cp_mst: 'Bắt buộc nhập.',
            create_cp_address: 'Bắt buộc nhập.',
            create_cp_person: 'Bắt buộc nhập.',
            create_cp_kd: 'Bắt buộc nhập.',
            create_cp_email: {
                required:"Bắt buộc nhập.",
                email: 'Không đúng định dạng email.',
            },
            create_cp_phone: {
                required:"Bắt buộc nhập.",
                digits: 'Không đúng định dạng.',
            },
            create_cp_revenueRate: {
                required:"Bắt buộc nhập.",
                number: 'Hãy nhập số.',
                min: 'Phải là số dương.'
            },
        },
    });
    // Form search CSKH
    $('.form-search-cskh').validate({
        rules:{
            phone : {
                required:true,
                digits: true,
                number: true,
            },
        },
        messages: {
            phone: {
                required:"Chưa nhập số thuê bao.",
                digits: 'Không đúng định dạng số điện thoại.',
                number: 'Không đúng định dạng số điện thoại.',
            },
        },
    });
    // Form TEST
    $('.upload__form--test').validate({
        rules:{
            api : 'required',
            token : 'required',
            file_video : 'required',
        },
        messages: {
            api: 'Bắt buộc nhập.',
            token: 'Bắt buộc nhập.',
            file_video: 'Bắt buộc Chọn.',
        },
    });
    // Form create FAQ
    $('#form-create-faq').validate({
        rules:{
            title: "required",
            contents: "required",
            order: "digits",
        },
        messages:{
            title: "Bắt buộc nhập.",
            contents: "Bắt buộc nhập.",
            order: "Phải là số nguyên dương.",
        }
    });
    // Form edit FAQ
    $('#form-edit-faq').validate({
        rules:{
            edit_title: "required",
            edit_content: "required",
            edit_order: "digits",
        },
        messages:{
            edit_title: "Bắt buộc nhập.",
            edit_content: "Bắt buộc nhập.",
            edit_order: "Phải là số nguyên dương.",
        }
    });
    // Form add thể loại trang chủ
    $('#form-add-configKind').validate({
        rules:{
            kindID: "required",
        },
        messages:{
            kindID: "Bắt buộc chọn.",
        }
    });
    // Form edit sort thể loại trang chủ
    $('#form-sort-configKind').validate({
        rules:{
            edit_sort: "digits",
        },
        messages:{
            edit_sort: "Phải là số nguyên dương.",
        }
    });
    // Form create banner
    // $('#form_upload_black_word').validate({
    //     rules:{
    //         file_bw : {
    //             extension: "xls | xlsx"
    //         }
    //     },
    //     messages: {
    //         file_bw: {
    //             extension: 'Không đúng định dạng file excel(xls|xlsx)v.'
    //         }
    //     },
    // });

    $('#form-report-revenue-cp-danet').validate({
        rules:{
            date_from : 'required',
            date_to : {
                required: true,
                dateGreaterThan: '#report-revenue-cp-danet-search-form'
            },
            num : {
                required: true,
                digits: true
            },
        },
        messages: {
            date_from: 'Bắt buộc chọn.',
            date_to:
                {
                    required:"Bắt buộc chọn.",
                },
            num: {
                required: "Bắt buộc nhập.",
                digits: "Phải là số nguyên dương."
            },
        },
    });
    $('#form-create-notification').validate({
        rules:{
            title : 'required',
            content : 'required',
        },
        messages: {
            title: 'Bắt buộc nhập.',
            content: 'Bắt buộc nhập.',
        },
    });
    $('#form-create-config').validate({
        rules:{
            version: {
                required:true,
                digits: true,
                number: true,
                min: 0
            },
            newversionios: {
                required:true,
                digits: true,
                number: true,
                min: 0
            },
            newversionandroid: {
                required:true,
                digits: true,
                number: true,
                min: 0
            },
        },
        messages: {
            version: {
                required:"Bắt buộc nhập.",
                digits: 'Phải là số nguyên dương.',
                number: 'Phải là số.',
                min: '>= 0'
            },
            newversionios: {
                required:"Bắt buộc nhập.",
                digits: 'Phải là số nguyên dương.',
                number: 'Phải là số.',
                min: '>= 0'
            },
            newversionandroid: {
                required:"Bắt buộc nhập.",
                digits: 'Phải là số nguyên dương.',
                number: 'Phải là số.',
                min: '>= 0'
            },
        },
    });
    $('#form-edit-config').validate({
        rules:{
            edit_version: {
                required:true,
                digits: true,
                number: true,
                min: 0
            },
            edit_newversionios: {
                required:true,
                digits: true,
                number: true,
                min: 0
            },
            edit_newversionandroid: {
                required:true,
                digits: true,
                number: true,
                min: 0
            },
        },
        messages: {
            edit_version: {
                required:"Bắt buộc nhập.",
                digits: 'Phải là số nguyên dương.',
                number: 'Phải là số.',
                min: '>= 0'
            },
            edit_newversionios: {
                required:"Bắt buộc nhập.",
                digits: 'Phải là số nguyên dương.',
                number: 'Phải là số.',
                min: '>= 0'
            },
            edit_newversionandroid: {
                required:"Bắt buộc nhập.",
                digits: 'Phải là số nguyên dương.',
                number: 'Phải là số.',
                min: '>= 0'
            },
        },
    });
})(jQuery);
