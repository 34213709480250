(function ($) {
    $('#form-btn-edit-music').click(function (e){
        e.preventDefault();
        if($('#form-edit-music').valid() == true){
            $('#form-btn-edit-music').attr('disabled', 'disabled');
            $('.footer .overlay').addClass('overlaing');
            $('#form-edit-music').submit();
        }
    });
    //Create music
    $('#form-btn-add-music').click(function (e){
        e.preventDefault();
        if($('#form-add-music').valid() == true) {
            $('#form-btn-add-music').attr('disabled', 'disabled');
            $('.footer .overlay').addClass('overlaing');
            $('#form-add-music').submit();
        }
    });
    $('#form-add-music').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-add-music').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                document.getElementById('form-add-music').reset();
                $('#addnew_music').modal('hide');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('#form-edit-music').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-edit-music').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                document.getElementById('form-edit-music').reset();
                $('#edit_music').modal('hide');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Click button edit
    $(document).on('click', '.btn-edit-music', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let web = $(this).attr('data-web');
        let android = $(this).attr('data-android');
        let ios = $(this).attr('data-ios');
        let artist = $(this).attr('data-artist');
        let album = $(this).attr('data-album');
        let time = $(this).attr('data-time');

        $('input[name="id_music"]').val(id);
        $('input[name="edit_music_title"]').val(title);
        $('input[name="edit_music_web"]').val(web);
        $('input[name="edit_music_android"]').val(android);
        $('input[name="edit_music_ios"]').val(ios);
        $('input[name="edit_music_artist"]').val(artist);
        $('input[name="edit_music_album"]').val(album);
        $('input[name="edit_music_time"]').val(time);

        $('#edit_music').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-music').click(function (e) {
        e.preventDefault();
        $('#edit_img_music').click();
    });
    // Show Image edit
    $('#edit_img_music').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('edit-show-img-music');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-music').html(e.target.files[0].name);
        $('#edit-show-img-music').css('display', 'block');
    });
    // Click btn xóa Country
    $(document).on('click', '.btn-delete-music', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let href = window.location.href;

        Swal.fire({
            title: 'Delete music',
            text: 'Bạn muốn xóa bài nhạc này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/music/delete',{
                    type: 'post',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

    // Approve music
    $(document).on('click', '.approve-music', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let id = $(this).attr('data-id');

        Swal.fire({
            title: 'Duyệt nhạc phim',
            text: 'Bạn muốn phê duyệt nhạc phim?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/music/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Reject music
    $('#form-reject-music').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            let href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-music').removeAttr('disabled', 'disabled');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-music').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-music').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $(document).on('click', '.form-btn-reject-music', function (e){
        e.preventDefault();
        if($('#form-reject-music').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.form-btn-reject-music').attr('disabled', 'disabled');
            $('#form-reject-music').submit();
        }
    });
    // Click button reject music
    $(document).on('click', '.btn-reject-music', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        $('#reject_music .modal-title').html('');

        $('#reject_music .modal-title').html(title);

        $('#reject_music input[name="id_music_reject"]').val(id);

        $('#reject_music').modal('show');
    });

    function checkLinkMusic() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            console.log('Android');
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            console.log('iPhone');
            // setTimeout(function () {
            //     window.location = "https://play.google.com/store/apps/details?id=com.dtsgaming.spacehero.shooter";
            // }, 1000);
            // window.location = "meclip://meclip.vn/lauch-android";
        }
    }
})(jQuery);
