/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// CommonJS
require('./bootstrap');
require('bootstrap-select/dist/js/bootstrap-select.min');
require('jquery/dist/jquery.min');
require('./validate');

require('./vendor/jwplayer');
require('./vendor/jwpsrv');
require('./vendor/dai');
require('./vendor/freewheel');
require('./vendor/gapro');
require('./vendor/jwplayer.core.controls.html5');
require('./jwplayer');

require('./custom');
require('./dropify');
require('./login');
require('./account_manager');
require('./account_user');
require('./role');
require('./kind');
require('./video');
require('./playlist');
require('./suggest');
require('./popular');
require('./banner');
require('./advertise');
require('./faq');
require('./changepass');
require('./modal');
require('./datetimepicker');
require('./summernote');
require('./config_kind');
require('./report');
require('./list_cp');
require('./cskh');
require('./tagsinput');
require('./odd-movie');
require('./odd-movie-copyright');
require('./series-movie-casing');
require('./movie-casing-copyright');
require('./upload-series-movie');
require('./cast');
require('./directors');
require('./smartWizard');
require('./upload-review-movie');
require('./country');
require('./review');
require('./coming_soon');
require('./import_movie');
require('./comments');
require('./music');
require('./album');
require('./black-word');
require('./supper-app');
require('./notification');
require('./config');
