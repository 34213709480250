(function ($) {
    $(document).on('click', '#btn-search-cskh', function(e){
        e.preventDefault();
        if ($('#form-search-cskh').valid() == true)
        {
            $('.footer .overlay').addClass('overlaing');
            $('#form-search-cskh').submit();
        }
    });

    // Hủy dịch vụ
    $(document).on('click', '#btn-cancel-service', function (e) {
        e.preventDefault();
        let phone = $(this).attr('data-phone');
        let href = window.location.href;

        Swal.fire({
            title: 'Hủy dịch vụ',
            text: 'Bạn muốn hủy dịch vụ của thuê bao?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Thoát',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/CSKH/cancel-service',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        phone: phone,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        } else if(data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        } else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
