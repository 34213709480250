(function ($) {
    // Create banner
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $('#form-create-qc').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            var href = window.location.href;
            $('#btn-create-qc').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    $(document).ready(function () {
        $('#btn-create-qc').click(function (e){
            e.preventDefault();
            if($('#form-create-qc').valid() == true) {
                // $('#btn-create-qc').attr('disabled', 'disabled');
                $('#form-create-qc').submit();
            }
        });
    });
    // Click input upload avatar create
    $('#btn-upload-img-create-qc').click(function (e) {
        e.preventDefault();
        $('#img_qc').click();
    });
    // Show Avatar create
    $('#img_qc').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('show-img-qc');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-create-qc').html(e.target.files[0].name);
        $('#show-img-qc').css('display', 'block');
    });

    // Edit qc
    $(document).ready(function () {
        var href = window.location.href;
        $('#form-edit-qc').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('#btn-edit-qc').removeAttr('disabled', 'disabled');
                if (res.error){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    })
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });
                }
            }
        });
        $('#btn-edit-qc').click(function (e){
            e.preventDefault();
            if($('#form-edit-qc').valid() == true){
                $('#btn-edit-qc').attr('disabled', 'disabled');
                $('#form-edit-qc').submit();
            }

        });
    });
    // Click button edit
    $(document).on('click', '.tbl-edit-qc', function (e) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var link = $(this).attr('data-link');
        var url = $(this).attr('data-url');
        var sort = $(this).attr('data-sort');

        $('input[name="id_qc"]').val(id);
        $('input[name="edit_name"]').val(name);
        $('input[name="edit_link"]').val(link);
        $('input[name="edit_sort"]').val(sort);
        $('input[name="old_image"]').val(url);

        var output_a = document.getElementById('edit-show-img-qc');
        output_a.src = url;
        $('#edit-show-img-qc').css('display', 'block');


        $('#edit_qc').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-qc').click(function (e) {
        e.preventDefault();
        $('#edit_img_qc').click();
    });
    // Show Imge edit
    $('#edit_img_qc').change(function (e) {
        e.preventDefault();
        var output = document.getElementById('edit-show-img-qc');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-qc').html(e.target.files[0].name);
        $('#edit-show-img-qc').css('display', 'block');
    });


    // Hidden banner
    $(document).on('click', '.hidden-qc', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden advertise',
            text: 'Bạn muốn ẩn quảng cáo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/advertise/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show banner
    $(document).on('click', '.show-qc', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show Advertise',
            text: 'Bạn muốn hiện quảng cáo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/advertise/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

})(jQuery);
