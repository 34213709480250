(function ($) {
    $('#form-btn-create-notification').click(function (e){
        e.preventDefault();
        let content = $('#form-create-notification textarea').val();
        console.log(content);
        if(content.length > 0){
            if($('#form-create-notification').valid() == true){
                $('#form-btn-create-notification').attr('disabled', 'disabled');
                $('#form-create-notification').submit();
            }
        }else{
            Swal.fire({
                type: 'warning',
                title: 'Warning',
                text: 'Hãy nhập nội dung thông báo.',
            });
        }
    });
    $('#form-create-notification').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-create-notification').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('#form-create-notification input').val('');
                $('#form-create-notification textarea').val('');
                $('#form-create-notification .note-editable').html('');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });

    // Edit notify
    $('#form-edit-notify').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $('#edit_video').modal('hide');
                    $(location).attr('href', href);
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

    $(document).on('click', '.btn-edit-notify', function (e) {
        e.preventDefault();
        $('input[name="id"]').val('');
        $('input[name="title"]').val('');
        $('input[name="comment"]').val('');
        $('input[name="publicDate"]').val('');
        $('input[name="status"]').val('');

        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let content = $(this).attr('data-content');
        let publicDate = $(this).attr('data-publicDate');
        let status = $(this).attr('data-status');

        $('input[name="edit_id_notify"]').val(id);
        $('textarea[name="edit_content"]').html(content);
        $('textarea[name="edit_title"]').html(title);
        $('input[name="edit_publicDate"]').val(publicDate);
        $('select[name="edit_status"]').val(status);

        $('#edit-notify').modal('show');
    });

    // Vô hiệu hóa
    $(document).on('click', '.btn-active-cancel', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        var msg = "Bạn muốn thực hiện hành động này?"

        Swal.fire({
            title: 'Thông báo',
            text: msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/notification/active-cancel', {
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id
                    },
                    success: function (data) {
                        console.log(data);
                        if (data.error) {
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        } else {
                            Swal.fire(
                                'Thành công!',
                                'Thành công.',
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                                // window.location= BASE_URL + "/admin/index";
                            });
                        }
                    }
                });
            }
        });
    });

})(jQuery);
