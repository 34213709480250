(function ($) {
    // Search playlist change type
    $(document).on('change', '#search-playlist-type', function (e) {
        e.preventDefault();
        var $this = $(this);
        var type = $('#search-playlist-type').val();
        console.log(type);
        if(type == 0)
        {
            $('.box-search-acc-cp').removeClass('d-none');
            $('.box-search-acc-user').addClass('d-none');
            $('#staffId_cp').removeAttr('disabled', 'disabled');
            $('#staffId_user').attr('disabled', 'disabled');
        }
        else if (type == 1)
        {
            $('.box-search-acc-user').removeClass('d-none');
            $('.box-search-acc-cp').addClass('d-none');
            $('.box-search-acc-user .dropdown.bootstrap-select').removeClass('disabled');
            $('.box-search-acc-user .dropdown.bootstrap-select button.dropdown-toggle').removeClass('disabled');
            $('#staffId_user').removeAttr('disabled', 'disabled');
            $('#staffId_cp').attr('disabled', 'disabled');
        }
    });

    $('.playlist__addVideo-modal').click(function () {
        $('.modal-playlist-add-video').modal('show');
        $('.playlist-search input[type=text]').val('');
        $('.playlist-search-result').empty();
        $('.playlist-search-url input[type=text]').val('');
        $('.playlist-url-result').html('<p>Nếu video tồn tại bạn có thể xem trước ở đây</p>');
    });

    // Search video -- playlist
    $('.playlist-search').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.success){
                $('.footer .overlay').removeClass('overlaing');
                $('.playlist-search-result').html('');
                // setTimeout(function () {
                    let btnMore =  $('.playlist-search-loadmore');
                    $('.playlist-search-result').html(res.success);

                    // Nếu video đã add thì search ko hiện
                    $('.playlist__videos .video.checked').each(function( index ) {
                        let id = $(this).attr('data-id');
                        if ($('.modal-playlist-add-video .playlist-search-result').find('.video[data-id='+id+']').length){
                            $('.modal-playlist-add-video .playlist-search-result').find('.video[data-id='+id+']').remove();
                        }
                    });
                    //
                    btnMore.attr('data-search',res.searchKey);
                    btnMore.attr('data-start',res.start);
                    if (!res.loadmore){
                        btnMore.removeClass('loading');
                    }else {
                        btnMore.addClass('loading');
                    }
                // },200)
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.playlist-search-result').html(res.error);
            }
        }
    });
    $(document).on('click','.modal-playlist-add-video .btn-search-key',function (e) {
        e.preventDefault();

        $('.footer .overlay').addClass('overlaing');
        $('.playlist-search').submit();
    });

    $('.playlist-search-url').ajaxForm({
    // Playlist Search URL
        beforeSubmit: function(arr, $form, options) {
            // The array of form data takes the following form:
            // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
            // return false to cancel submit
        },
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            $('.playlist-url-result').html('');
            if (res.success){
                $('.playlist-url-result').html(res.success);
                // Nếu video đã add thì thông báo added
                $('.playlist__videos .video.checked').each(function( index ) {
                    let id = $(this).attr('data-id');
                    if ($('.modal-playlist-add-video .playlist-url-result').find('.video[data-id='+id+']').length){
                        $('.modal-playlist-add-video .playlist-url-result').find('.video[data-id='+id+']').remove();
                    }
                });
                // $('.playlist-url-result').html('<p>(Video đã được thêm.)</p>');
            }
            else {
                $('.playlist-url-result').html('<p>('+res.error+')</p>');
            }
        }
    });
    // Btn add Video
    $(document).on('click','.modal-playlist-add-video .btn-pll-search',function (e) {
        e.preventDefault();
        $('.modal-playlist-add-video .playlist-search-result .video.checked').each(function( index ) {
            let id = $(this).attr('data-id');
            if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
                $(this).clone().appendTo('.playlist__videos');
            }
        });
        Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Đã chọn video!',
        })
    });
    $(document).on('click','.modal-playlist-add-video .btn-pll-url',function (e) {
        e.preventDefault();
        $('.modal-playlist-add-video .playlist-url-result .video').each(function( index ) {
            let id = $(this).attr('data-id');
            if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
                $(this).clone().appendTo('.playlist__videos');
            }
        });
        Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Chọn video thành công!',
        })
    });
    $(document).on('click','.modal-playlist-add-video .btn-pll-myVideo',function (e) {
        e.preventDefault();
        $('.modal-playlist-add-video .playlist-myvideo-result .video.checked').each(function( index ) {
            let id = $(this).attr('data-id');
            if (!$('.playlist__videos').find('.video[data-id='+id+']').length){
                $(this).clone().appendTo('.playlist__videos');
            }
        });
        Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Chọn video thành công!',
        })
    });
    //
    $(document).on('click','.playlist__videos .playlist__delete a',function(e){
        e.preventDefault();
        $(this).closest('.video').remove();
    });

    (function () {
        // Create information & video of Playlist
        $(document).on('click','.playlist__addVideo-submit',function (e) {
            e.preventDefault();

            var video = $('.playlist__videos input[type="checkbox"]').val();
            var item = video ? video : '';

            if (item.length > 0)
            {
                // $('.playlist__videos .video.checked').each(function( index ) {
                //     let img = $(this).find('img').attr('src');
                //     if (index==0){
                //         $('.playlist__form .playlist_thumb_create').val(img);
                //     }
                // });
                if ($('.playlist__form').valid() == true)
                {
                    $('.footer .overlay').addClass('overlaing');
                    $('.playlist__addVideo-submit').attr('disabled', 'disabled');
                    $('.playlist__form').submit();
                }
            }else{
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Hãy thêm video vào playlist!',
                })
            }
        });
        $('.playlist__form').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                var href = window.location.href;
                console.log(res);
                if (res.success){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        if ($('.playlist-edit').length)
                        {
                            $(location).attr('href', href);
                        }else{
                            $(location).attr('href', BASE_URL + '/playlist-manager');
                        }
                    });

                }
                else if (res.error) {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                    $('.playlist__addVideo-submit').removeAttr("disabled");
                    $('.playlist__edit-submit').removeAttr("disabled");
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        $('.playlist__form-edit').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                var href = window.location.href;
                console.log(res);
                if (res.success){
                    $('.playlist__edit-submit').removeAttr('disabled');
                    Swal.fire(
                        'Thành công!',
                        res.success,
                        'success'
                    ).then((value) => {
                        $(location).attr('href', href);
                    });

                }
                else if (res.error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                    $('.playlist__addVideo-submit').removeAttr("disabled");
                    $('.playlist__edit-submit').removeAttr("disabled");
                }
                else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
        // Edit information & video of Playlist
        $(document).on('click','.playlist__edit-submit',function (e) {
            e.preventDefault();

            var video = $('.playlist__videos input[type="checkbox"]').val();
            var item = video ? video : '';

            if (item.length > 0)
            {
                // $('.playlist__videos .video.checked').each(function( index ) {
                //     let img = $(this).find('img').attr('src');
                //     if (index==0){
                //             $('.playlist__form .playlist_thumb_edit').val(img);
                //     }
                // });
                if ($('.playlist__form').valid() == true)
                {
                    $('.footer .overlay').addClass('overlaing');
                    $('.playlist__edit-submit').attr('disabled', 'disabled');
                    $('.playlist__form').submit();
                }
            }else{
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Hãy thêm video vào playlist!',
                })
            }
        });
    })();

    // Suggest hidden playlist (Đề nghị ẩn)
    $(document).on('click', '.suggest-hide-playlist', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Suggest hide playlist',
            text: 'Bạn muốn đề nghị ẩn playlist?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/playlist-manager/suggest-hide',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Hidden video
    $(document).on('click', '.hidden-playlist', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden playlist',
            text: 'Bạn muốn ẩn playlist?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/playlist-manager/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show playlist
    $(document).on('click', '.show-playlist', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show playlist',
            text: 'Bạn muốn hiện playlist?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/playlist-manager/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

    // Approve checkbox playlist
    $(document).on('click', '.approve-playlist', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Aprove playlist',
            text: 'Bạn muốn phê duyệt playlist?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/playlist-manager/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Approve checkbox playlist
    $(document).on('change', '#approve-checkbox-playlist', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var checkbox = '';
        $('input[name="checkbox_playlist[]"]:checked').each(function(){
            checkbox = checkbox + ',' + $(this).val();
        });

        if (checkbox.length)
        {
            Swal.fire({
                title: 'Aprove playlist',
                text: 'Bạn muốn phê duyệt các playlist?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButqtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/playlist-manager/approve-checkbox',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            ids: checkbox,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn playlist để phê duyệt!',
            });
        }
        $('select[name="approve-checkbox-playlist"]').val('');
    });

    $(document).on('click', '.playlist--detail', function (e) {
        e.preventDefault();
        $('#detail-name').html('');
        $('#detail-channel').html('');
        $('#detail-des').html('');
        $('#detail-status').html('');
        $('#show_playlist .modal-header img').attr('src', '');
        $('.list--videos').html('');

        var videos = '';
        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');
        var channel = $(this).attr('data-channel');
        var des = $(this).attr('data-des');
        var status = $(this).attr('data-status');
        var img = $(this).attr('data-img');
        var href = window.location.href;
        if (status == 0){
            var s = 'Đã active';
        }
        else if (status == -4){
            var s = 'Chờ duyệt';
        }
        else if (status == -1){
            var s = 'Vô hiệu hóa';
        }
        else if (status == 1){
            var s = 'Đã lên lịch';
        }
        else if (status == 2){
            var s = 'Đã ẩn';
        }
        else{
            var s = '';
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/playlist-manager/playlist-detail-video',{
            type: 'POST',
            dataType: 'json',
            data: {
                id: id,
            },
            success:function (data){
                console.log(data);
                $('.list--videos').html(data.success);
            }
        });

        $('#detail-name').html(name);
        $('#detail-channel').html(channel);
        $('#detail-des').html(des);
        $('#detail-status').html(s);
        $('#show_playlist .modal-header img').attr('src', img);
        $('.list--videos').html(videos);
    });

    $(document).on('click', '.playlist--name-link', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/playlist-manager/name-link',{
            type: 'POST',
            dataType: 'json',
            data: {
                id: id,
            },
            success:function (data) {
                console.log(data);
                if (data.logged){
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        res.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
                else if (data.id_video){
                    window.open(data.domainEnduser + '/watch/' + data.id_video + '&list=' + data.id_playlist, '_blank');
                }
                else {
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
            }
        });
    });


    // Click button action history playlist
    $(document).on('click', '.btn-action-history-playlist', function (e) {
        e.preventDefault();
        $('#action_history_playlist-title').html('');
        $('.list-action-playlist-history tbody').html('');

        var title = $(this).attr('data-title');
        var history = $(this).attr('data-history');
        var his = JSON.parse(history);
        //Đảo ngược mảng
        his.reverse();
        var html = '';
        $.each( his, function( key, value ) {
            if (value['action'] ==  1){
                var action = 'Duyệt';
            }
            else if (value['action'] ==  2){
                var action = 'Hủy live';
            }
            else if (value['action'] == 3){
                var action = 'Dừng live';
            }
            else if (value['action'] == 4){
                var action = 'Chuyển live thành video';
            }
            else if (value['action'] == 5){
                var action = 'Lên lịch';
            }
            else if (value['action'] == 6){
                var action = 'Cập nhật';
            }
            else if (value['action'] == 7){
                var action = 'Ẩn';
            }
            else if (value['action'] == 8){
                var action = 'Từ chối duyệt';
            }
            else if (value['action'] == 9){
                var action = 'Yêu cầu ẩn kênh';
            }
            else{
                var action = '';
            }
            var date = new Date(value['actiontime']);
            date = date.toLocaleString();
            html += '<tr><td>'+ value['username'] +'</td><td>'+ action +'</td><td>'+ date +'</td></tr>';
        });

        $('#action_history_playlist-title').html(title);
        $('.list-action-playlist-history tbody').html(html);

        $('#action_history_playlist').modal('show');
    });

})(jQuery);
(function ($) {
    $(document).on('click','.video-checkbox input[type=checkbox]',function () {
        let $this = $(this);
        if( $this.is(':checked')){
            $this.closest('.video').addClass('checked');
        }else {
            $this.closest('.video').removeClass('checked');
        }
    });
})(jQuery);

//Loadmore
(function ($) {
    $(document).on('click','.playlist-myvideo-loadmore',function (e) {
        e.preventDefault();
        $('.playlist-myvideo-result').append('<div class="loader"></div>');
        var $this = $(this);
        var id = $this.attr('data-id');
        var start = $this.attr('data-start');
        var limit = $this.attr('data-limit');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type:'POST',
            url: BASE_URL + '/playlist-manager/load-more',
            data:{
                id: id,
                start : start,
                limit : limit,
            },
            beforeSend:function(){

            },
            success:function(data){
                console.log(data);
                if (data.error){
                    $('.loader').remove();
                    $('.playlist-myvideo-result').append('<p class="color-red text-center">'+ data.error +'</p>');
                }
                else if(data.success || data.id) {
                    $('.playlist-myvideo-result .loader').remove();

                    $('.playlist-myvideo-result').append(data.success);
                    if (data.loadmore != 0){
                        $('<button type="button" class="d-none playlist-myvideo-loadmore" data-start="'+ data.start +'" data-limit="'+ data.limit +'" data-id="'+ data.id +'"></button>').insertAfter('.playlist-myvideo-result');
                    }
                }
                else {
                    $('.loader').remove();
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }

            }
        });
    });
    $(document).ready(function(){
        $('.playlist-myvideo-result').scroll(function(){

            var position = $('.playlist-myvideo-result').scrollTop();
            var bottom = $('.playlist-myvideo-result').height();

            if( position > bottom ){
                $('.playlist-myvideo-loadmore').trigger('click');
                $('.playlist-myvideo-loadmore').remove();
            }

        });

    });
})(jQuery);
