(function ($) {
    // Supper App checkbox video
    // $(document).on('change', '#configSupperApp-checkbox-video', function (e) {
    //     e.preventDefault();
    //     let href = window.location.href;
    //     let value = $(this).val();
    //     let checkbox = [];
    //     $('input[name="checkbox_video[]"]:checked').each(function(){
    //         // checkbox = checkbox + ',' + $(this).val();
    //         checkbox.push($(this).val());
    //     });
    //     let title = '';
    //     let text = '';
    //     if (value == 2){
    //         title = 'Hiện trên supper app';
    //         text = 'Bạn muốn hiện các video trên supper app?';
    //     }else if (value == 3){
    //         title = 'Play trên supper app';
    //         text = 'Bạn muốn play các video trên supper app?';
    //     }else if (value == 4){
    //         title = 'Dừng phát trên supper app';
    //         text = 'Bạn muốn dừng phát các video trên supper app?';
    //     }else if (value == 5){
    //         title = 'Hiện trên trang home supper app';
    //         text = 'Bạn muốn hiện các video trên trang home supper app?';
    //     }else if (value == 6){
    //         title = 'Ẩn trên trang home supper app';
    //         text = 'Bạn muốn ẩn các video trên trang home supper app?';
    //     }else if (value == 7){
    //         title = 'Hiện banner đặc sắc supper app';
    //         text = 'Bạn muốn hiện các video trên banner đặc sắc supper app?';
    //     }else if (value == 8){
    //         title = 'Ẩn banner đặc sắc supper app';
    //         text = 'Bạn muốn ẩn các video trên banner đặc sắc supper app?';
    //     }else if (value == 9){
    //         title = 'Hiện banner phim bộ supper app';
    //         text = 'Bạn muốn hiện các video trên banner phim bộ supper app?';
    //     }else if (value == 10){
    //         title = 'Ẩn banner phim bộ supper app';
    //         text = 'Bạn muốn ẩn các video trên banner phim bộ supper app?';
    //     }else if (value == 11){
    //         title = 'Hiện banner phim lẻ supper app';
    //         text = 'Bạn muốn hiện các video trên banner phim lẻ supper app?';
    //     }else if (value == 12){
    //         title = 'Ẩn banner phim lẻ supper app';
    //         text = 'Bạn muốn ẩn các video trên banner phim lẻ supper app?';
    //     }else{
    //         title = 'Ẩn trên supper app';
    //         text = 'Bạn muốn ẩn các video trên supper app?';
    //     }
    //
    //     if (checkbox.length)
    //     {
    //         Swal.fire({
    //             title: title,
    //             text: text,
    //             type: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Yes',
    //             cancelButtonText: 'No',
    //         }).then((result) => {
    //             if (result.value) {
    //                 $.ajaxSetup({
    //                     headers: {
    //                         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //                     }
    //                 });
    //                 $.ajax(BASE_URL + '/supper-app/approve-checkbox',{
    //                     type: 'POST',
    //                     dataType: 'json',
    //                     data: {
    //                         value: value,
    //                         ids: checkbox,
    //                     },
    //                     success:function (data){
    //                         console.log(data);
    //                         if (data.error){
    //                             Swal.fire({
    //                                 type: 'error',
    //                                 title: 'Error',
    //                                 text: data.error,
    //                             })
    //                         }
    //                         else if (data.success) {
    //                             Swal.fire(
    //                                 'Thành công!',
    //                                 data.success,
    //                                 'success'
    //                             ).then((value) => {
    //                                 $(location).attr('href', href);
    //                             });
    //                         }
    //                         else {
    //                             $('.footer .overlay').removeClass('overlaing');
    //                             Swal.fire(
    //                                 'Error!',
    //                                 data.logged,
    //                                 'error'
    //                             ).then((value) => {
    //                                 $(location).attr('href', BASE_URL);
    //                             });
    //                         }
    //                     }
    //                 });
    //             }
    //         });
    //     }else{
    //         Swal.fire({
    //             type: 'error',
    //             title: 'Error',
    //             text: 'Hãy chọn video!',
    //         });
    //     }
    //     $('select[name="configSupperApp-checkbox-video"]').selectpicker('val', '');
    // });
    $(document).on('click', '#btn_configSupperApp-checkbox-video', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let value = $('#configSupperApp-checkbox-video').val();
        let checkbox = [];
        let str = '';
        $('input[name="checkbox_video[]"]:checked').each(function(){
            // checkbox = checkbox + ',' + $(this).val();
            checkbox.push($(this).val());
        });

        if (checkbox.length == 0){
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn video!',
            });
        }else if (value.length == 0){
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn hành động!',
            });
        }else{
            for (var i = 0; i < value.length; i++){
                if (i == 0){
                    str += value[i];
                }else{
                    str += ',' + value[i];
                }
            }
            Swal.fire({
                title: 'Action',
                text: 'Bạn muốn thực hiện các hành động ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Thoát',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/supper-app/approve-checkbox',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            value: str,
                            ids: checkbox,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                                $('select[name="configSupperApp-checkbox-video"]').selectpicker('val', '');
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }
    });
    $(document).on('change', '#cate_supperApp', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let type = $(this).val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/supper-app/category',{
            type: 'POST',
            dataType: 'json',
            data: {
                type: type,
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    $('#cate_supperApp').selectpicker('val', '');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    })
                }
                else if (data.success) {
                    $('#kinds_supperApp').html('');
                    let html = '<select name="kind" class="form-control selectpicker show-tick" data-live-search="true" data-size="10">'+ data.html + '</select>';
                    $('#kinds_supperApp').html(html);
                    $('#kinds_supperApp select').selectpicker();
                }
                else {
                    $('#cate_supperApp').selectpicker('val', '');
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
})(jQuery);
