(function ($) {
    $(document).on('click', '.page-comments .icon-show--comments', function (e) {
        let $this = $(this);
        $('.footer .overlay').addClass('overlaing');
        let id = $this.attr('data-id');
        let hclass = $this.hasClass('fa-plus-circle');
        if (hclass) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/comments/getList', {
                type: 'get',
                dataType: 'json',
                data: {
                    page: 1,
                    id: id
                },
                success: function (data) {
                    console.log(data);
                    $('.footer .overlay').removeClass('overlaing');
                    if (data.error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    } else if (data.success) {
                        if (data.html != '') {
                            // Check delete box old
                            $this.parents('tbody').find('.first').removeAttr('rowspan');
                            $this.parents('tbody').find('.icon-show--comments').each(function( index ) {
                                let has = $(this).hasClass('fa-minus-circle');
                                if (has){
                                    $(this).addClass('fa-plus-circle');
                                    $(this).removeClass('fa-minus-circle');
                                }
                            });
                            $this.parents('tbody').find('.item-comments').remove();
                            //
                            $this.removeClass('fa-plus-circle');
                            $this.addClass('fa-minus-circle');
                            $this.parents('tr').find('.first').attr('rowspan', 2);
                            $(data.html).insertAfter($this.parents('tr'));
                            $this.parents('tbody').find('.item-comments').fadeIn('slow');

                            let pagination = '<div class="paginator"><ul><li ';
                            if (data.count < data.limit){
                                pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-page="" style="pointer-events: none;" href="#">Next</a></li><li ';
                            }else{
                                pagination += '><a class="h-auto icon-paginate-comments" data-id="' + data.id + '" data-page="' + (data.page + 1) + '" href="#">Next</a></li><li ';
                            }
                            if (data.start == 0){
                                pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-page="" style="pointer-events: none;" href="#">Pre</a></li></ul></div>';
                            }else{
                                pagination += '><a class="h-auto icon-paginate-comments" data-id="' + data.id + '" data-page="' + (data.page - 1) + '" href="#">Pre</a></li></ul></div>';
                            }
                            $this.parents('tbody').find('.item-comments td')
                                .append(pagination);
                        }else {
                            Swal.fire({
                                type: 'warning',
                                title: 'Warning',
                                text: 'Không có bình luận.',
                            });
                        }
                    } else {
                        $('.footer .overlay').removeClass('overlaing');
                        Swal.fire(
                            'Error!',
                            data.logged,
                            'error'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL);
                        });
                    }
                }
            });
        }
        else
        {
            $this.removeClass('fa-minus-circle');
            $this.addClass('fa-plus-circle');
            $this.parents('tr').find('.first').removeAttr('rowspan');
            $this.parents('tbody').find('.item-comments').fadeOut('slow');
            $this.parents('tbody').find('.item-comments').remove();
            $('.footer .overlay').removeClass('overlaing');
        }
    });
    $(document).on('click', '.page-comments .icon-paginate-comments', function (e) {
        let $this = $(this);
        $('.footer .overlay').addClass('overlaing');
        let id = $this.attr('data-id');
        let page = $this.attr('data-page');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/comments/getList', {
            type: 'get',
            dataType: 'json',
            data: {
                paginate: 1,
                page: page,
                id: id
            },
            success: function (data) {
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                } else if (data.success) {
                    if (data.html != '') {
                        $this.parents('td').find('ul.list-group').html('');
                        $this.parents('td').find('ul.list-group').css('display', 'none');
                        $this.parents('td').find('ul.list-group').html(data.html);
                        $this.parents('td').find('ul.list-group').fadeIn('slow');

                        let pagination = '<div class="paginator"><ul><li ';
                        if (data.count < data.limit){
                            pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-page="" style="pointer-events: none;" href="#">Next</a></li><li ';
                        }else{
                            pagination += '><a class="h-auto icon-paginate-comments" data-id="' + data.id + '" data-page="' + (data.page + 1) + '" href="#">Next</a></li><li ';
                        }
                        if (data.start == 0){
                            pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-page="" style="pointer-events: none;" href="#">Pre</a></li></ul></div>';
                        }else{
                            pagination += '><a class="h-auto icon-paginate-comments" data-id="' + data.id + '" data-page="' + (data.page - 1) + '" href="#">Pre</a></li></ul></div>';
                        }

                        $this.parents('tbody').find('.item-comments td').append(pagination);
                        $this.parents('tbody').find('.item-comments td .paginator').first().remove();
                    }else {
                        Swal.fire({
                            type: 'warning',
                            title: 'Warning',
                            text: 'Không có bình luận.',
                        });
                    }
                } else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    $(document).on('click', '.page-comments .icon-show--comments-item', function (e) {
        let $this = $(this);
        $('.footer .overlay').addClass('overlaing');
        let postId = $this.attr('data-postId');
        let commentId = $this.attr('data-id');
        $this.parents('li').find('.paginator').remove();
        let hclass = $this.hasClass('fa-plus-circle');
        if (hclass){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/comments/getListItem', {
                type: 'get',
                dataType: 'json',
                data: {
                    page: 1,
                    postId: postId,
                    commentId: commentId
                },
                success: function (data) {
                    console.log(data);
                    $('.footer .overlay').removeClass('overlaing');
                    if (data.error) {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    } else if (data.success) {
                        if (data.html != '') {

                            $this.removeClass('fa-plus-circle');
                            $this.addClass('fa-minus-circle');
                            $this.parents('li').find('.list-sub-comments').css('display', 'block');
                            // $('.li-clone-comments li').clone().appendTo($this.parents('li').find('ul.list-group'));
                            $(data.html).appendTo($this.parents('li').find('ul.list-group'));
                            $this.parents('li').find('ul.list-group').fadeIn('slow');

                            let pagination = '<div class="paginator text-center"><ul class="float-none"><li ';
                            if (data.count < data.limit){
                                pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-postid="" data-page="" style="pointer-events: none;" href="#">Next</a></li><li ';
                            }else{
                                pagination += '><a class="h-auto icon-paginate-comments-item" data-id="' + data.commentId + '" data-postid="' + data.postId + '" data-page="' + (data.page + 1) + '" href="#">Next</a></li><li ';
                            }
                            if (data.start == 0){
                                pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-postid="" data-page="" style="pointer-events: none;" href="#">Pre</a></li></ul></div>';
                            }else{
                                pagination += '><a class="h-auto icon-paginate-comments-item" data-id="' + data.commentId + '" data-postid="' + data.postId + '"  data-page="' + (data.page - 1) + '" href="#">Pre</a></li></ul></div>';
                            }
                            $this.parents('li').find('.list-sub-comments')
                                .append(pagination);
                        }else {
                            Swal.fire({
                                type: 'warning',
                                title: 'Warning',
                                text: 'Không có bình luận.',
                            });
                        }
                    } else {
                        $('.footer .overlay').removeClass('overlaing');
                        Swal.fire(
                            'Error!',
                            data.logged,
                            'error'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL);
                        });
                    }
                }
            });
        }
        else
        {
            $this.removeClass('fa-minus-circle');
            $this.addClass('fa-plus-circle');
            $this.parents('li').find('ul.list-group').fadeOut('slow');
            $this.parents('li').find('ul.list-group').html('');
            $this.parents('li').find('.list-sub-comments').css('display', 'none');
            $('.footer .overlay').removeClass('overlaing');
        }
    });
    $(document).on('click', '.page-comments .icon-paginate-comments-item', function (e) {
        let $this = $(this);
        $('.footer .overlay').addClass('overlaing');
        let postId = $this.attr('data-postId');
        let commentId = $this.attr('data-id');
        let page = $this.attr('data-page');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/comments/getListItem', {
            type: 'get',
            dataType: 'json',
            data: {
                page: page,
                postId: postId,
                commentId: commentId
            },
            success: function (data) {
                console.log(data);
                $('.footer .overlay').removeClass('overlaing');
                if (data.error) {
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                } else if (data.success) {
                    if (data.html != '') {
                        $this.parents('.list-sub-comments').find('ul.list-group').html('');
                        $this.parents('.list-sub-comments').find('ul.list-group').css('display', 'none');
                        $this.parents('.list-sub-comments').find('ul.list-group').html(data.html);
                        $this.parents('.list-sub-comments').find('ul.list-group').fadeIn('slow');

                        let pagination = '<div class="paginator text-center"><ul class="float-none"><li ';
                        if (data.count < data.limit){
                            pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-postid="" data-page="" style="pointer-events: none;" href="#">Next</a></li><li ';
                        }else{
                            pagination += '><a class="h-auto icon-paginate-comments-item" data-id="' + data.commentId + '" data-postid="' + data.postId + '" data-page="' + (data.page + 1) + '" href="#">Next</a></li><li ';
                        }
                        if (data.start == 0){
                            pagination += 'class="pa-disabled"><a class="h-auto" data-id="" data-postid="" data-page="" style="pointer-events: none;" href="#">Pre</a></li></ul></div>';
                        }else{
                            pagination += '><a class="h-auto icon-paginate-comments-item" data-id="' + data.commentId + '" data-postid="' + data.postId + '"  data-page="' + (data.page - 1) + '" href="#">Pre</a></li></ul></div>';
                        }

                        $this.parents('.list-sub-comments').append(pagination);
                        $this.parents('.list-sub-comments').find('.paginator').first().remove();

                        $this.parents('paginator').html(pagination);
                        // $this.parents('ul').remove();
                    }else {
                        Swal.fire({
                            type: 'warning',
                            title: 'Warning',
                            text: 'Không có bình luận.',
                        });
                    }
                } else {
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    // Edit Comment
    $('#form-edit-comment').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $('#edit_video').modal('hide');
                    $(location).attr('href', href);
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $(document).on('click', '.btn-edit-comment', function (e) {
        e.preventDefault();
        $('input[name="commentId"]').val('');
        $('textarea[name="comment"]').html('');
        $('select[name="status"]').val('');

        let id = $(this).attr('data-id');
        let comment = $(this).attr('data-comment');
        let status = $(this).attr('data-status');
        let post = $(this).attr('data-post');

        $('input[name="commentId"]').val(id);
        $('textarea[name="comment"]').html(comment);
        $('select[name="status"]').val(status);
        $('#edit_comment .modal-title').html(post);

        $('#edit_comment').modal('show');
    });

    function formatRepo (repo)
    {
        var markup = "<div class='select2-result-repository clearfix'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title'>" + repo.name + "</div></div></div>";
        return markup;
    }
    function formatRepoSelectionUser (repo) {
        return repo.name || repo.text;
    }
    $('.js-data-user_mobile').select2({
        width: '100%',
        allowClear: true,
        ajax: {
            url: "/report/get-user-use-ajax",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                console.log(data);
                // parse the results into the format expected by Select2
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data, except to indicate that infinite
                // scrolling can be used
                if (!data.url){
                    params.page = params.page || 1;
                    if (data.data){
                        var result = data.data.data;
                        $.each(result, function( index, value ) {
                            value['id'] = value['mobile'];
                            value['name'] = value['displayName'];
                        });
                    }else{
                        var result = '';
                    }
                    return {
                        results: result,
                        pagination: {
                            more: (data.data.data).length > 0
                        }
                    };
                }else{
                    window.location.href = data.url;
                }
            },
            cache: true
        },
        placeholder: 'Chọn tài khoản người sử dụng',
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        // minimumInputLength: 1,
        templateResult: formatRepo,
        templateSelection: formatRepoSelectionUser

    });

    $(document).on('change', '#checkbox_comment', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let item = $(this).val();
        let checkbox = [];
        $('input[name="checkbox_video[]"]:checked').each(function(){
            // checkbox = checkbox + ',' + $(this).val();
            checkbox.push($(this).val());
        });
        let title = '';
        let text = '';
        if (item == 0){
            title = 'Duyệt';
            text = 'Bạn muốn duyệt các bình luận?';
        }else{
            title = 'Vô hiệu hóa';
            text = 'Bạn muốn Vô hiệu hóa các bình luận?';
        }

        if (checkbox.length)
        {
            Swal.fire({
                title: title,
                text: text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/comments/checkbox',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            ids: checkbox,
                            item: item,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn bình luận!',
            });
        }
        $('select[name="checkbox_comment"]').val('');
    });
    $(document).on('click', '.btn-approve-comment', function (e) {
        e.preventDefault();

        let href = window.location.href;
        let id = $(this).attr('data-id');
        let status = $(this).attr('data-status');
        let title = '';
        let text = '';
        if (status == -1){
            title = 'Vô hiệu hóa';
            text = 'Bạn muốn Vô hiệu hóa bình luận?';
        }else{
            title = 'Duyệt';
            text = 'Bạn muốn duyệt bình luận?';
        }
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/comments/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        status: status,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
