(function ($) {
    $('#form-btn-edit-country').click(function (e){
        e.preventDefault();
        if($('#form-edit-country').valid() == true){
            $('#form-btn-edit-country').attr('disabled', 'disabled');
            $('#form-edit-country').submit();
        }

    });
    //Create Country
    $('#form-btn-add-country').click(function (e){
        e.preventDefault();
        if($('#form-add-country').valid() == true) {
            $('#form-btn-add-country').attr('disabled', 'disabled');
            $('#form-add-country').submit();
        }
    });
    $('#form-add-country').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-add-country').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('#addnew_country').modal('hide');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('#form-edit-country').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-edit-country').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('#edit_country').modal('hide');
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Click button edit
    $(document).on('click', '.btn-edit-country', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let name = $(this).attr('data-name');

        $('input[name="id_country"]').val(id);
        $('input[name="edit_country_name"]').val(name);

        $('#edit_country').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-country').click(function (e) {
        e.preventDefault();
        $('#edit_img_country').click();
    });
    // Show Image edit
    $('#edit_img_country').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('edit-show-img-country');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-country').html(e.target.files[0].name);
        $('#edit-show-img-country').css('display', 'block');
    });
    // Click btn xóa Country
    $(document).on('click', '.btn-delete-country', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Country',
            text: 'Bạn muốn xóa quốc gia này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/country/delete',{
                    type: 'post',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

})(jQuery);
