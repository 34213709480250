window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.Swal = require('sweetalert2/dist/sweetalert2.all.min');
    require('bootstrap');
    window.axios = require('axios');
    window.hls = require('hls.js');
    require('bootstrap4-tagsinput-douglasanpa/tagsinput');
    require('jquery-form/dist/jquery.form.min');
    require('image-picker');
    require('dropify/dist/js/dropify.min');
    require('jquery-validation/dist/jquery.validate.min');
    require('jquery-validation/dist/additional-methods.min');
    require('jquery-datetimepicker');
    require('bootstrap-notify');
    // require('select2/dist/js/select2.min');
    require('select2/dist/js/select2.min');
    require('summernote');
    require('smartwizard/dist/js/jquery.smartWizard.min');
} catch (e) {}
/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

