(function ($) {
    // Lock account user
    $(document).on('click', '.lock-user', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Lock account?',
            text: 'Bạn muốn khóa tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/lock-user',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // unLock account user
    $(document).on('click', '.unlock-user', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'unLock account?',
            text: 'Bạn muốn mở khóa tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/unlock-user',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

    // Show account user
    $(document).on('click', '.show-user', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Show account?',
            text: 'Bạn muốn hiện tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/show-user',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
    // Hide account user
    $(document).on('click', '.hide-user', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Hide account?',
            text: 'Bạn muốn ẩn tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/hide-user',{
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        user_id: id
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else if (data.logged){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.logged,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });

    // Click detail user
    $(document).on('click', '.detail-user', function (e) {
        e.preventDefault();
        $('#detail-user-name').html('');
        $('#detail-user-phone').html('');
        $('#detail-user-address').html('');
        $('#detail-user-identifier').html('');
        $('#detail-user-noicap').html('');
        $('#detail-user-ngayCap').html('');
        $('#detail-user-taxCode').html('');

        var id = $(this).attr('data-id');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/account-manager/detail-user',{
            type: 'GET',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    $('#detail-user-name').html(data.realName);
                    $('#detail-user-phone').html(data.mobile);
                    $('#detail-user-address').html(data.address);
                    $('#detail-user-identifier').html(data.identifier);
                    $('#detail-user-noicap').html(data.noiCap);
                    $('#detail-user-ngayCap').html(data.ngayCap);
                    $('#detail-user-taxCode').html(data.taxCode);
                    $('#detail-user-revenueRate').html(data.revenueRate);

                    $('#modal-detail-user').modal('show');
                }
            }
        });
    });
    // Click list channel user revenueRate
    $(document).on('click', '.revenueRate-user', function (e) {
        e.preventDefault();
        $('#account-user-channel-revenueRate').html('');

        var id = $(this).attr('data-id');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/account-manager/user-channel',{
            type: 'GET',
            dataType: 'json',
            data: {
                id : id
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: data.error,
                    });
                }
                else {
                    var html = '';
                    if (data.length > 0)
                    {
                        $.each(data, function(index, value) {

                            var head = '<div class="item-channel">' +
                                        '<form action="" method="POST" class="form-group row form-edit-revene-channel-user">' +
                                        '<input type="hidden" name="id_user' + value.id + '" value="' + value.id + '">' +
                                        '<label class="col-9 m-0">' + value.name + '</label>' +
                                            '<div class="col-3">' +
                                                '<select name="revenueRate' + value.id + '" class="form-control channel-edit-revenueRate" data-rate="' + value.revenueRate + '" data-id="' + value.id + '">' +
                                                    '<option disabled selected>--Chọn--</option>';

                            if (value.revenueRate == 10){
                                var option = '<option value="10" selected>10%</option>' +
                                    '<option value="15">15%</option>' +
                                    '<option value="20">20%</option>' +
                                    '<option value="25">25%</option>'
                            }
                            else if (value.revenueRate == 15){
                                var option = '<option value="10">10%</option>' +
                                    '<option value="15" selected>15%</option>' +
                                    '<option value="20">20%</option>' +
                                    '<option value="25">25%</option>'
                            }
                            else if (value.revenueRate == 20){
                                var option = '<option value="10">10%</option>' +
                                    '<option value="15">15%</option>' +
                                    '<option value="20" selected>20%</option>' +
                                    '<option value="25">25%</option>'
                            }
                            else if (value.revenueRate == 25){
                                var option = '<option value="10">10%</option>' +
                                    '<option value="15">15%</option>' +
                                    '<option value="20">20%</option>' +
                                    '<option value="25" selected>25%</option>'
                            }
                            else{
                                var option = '<option value="10">10%</option>' +
                                    '<option value="15">15%</option>' +
                                    '<option value="20">20%</option>' +
                                    '<option value="25">25%</option>'
                            }

                            var foot = '</select>' +
                                '</div>' +
                                '</form>' +
                                '</div>'
                            html += head + option + foot;
                        });
                    }
                    else{
                        html = '<p class="m-0 text-center">Không có kênh.</p>';
                    }

                    $('#account-user-channel-revenueRate').html(html);

                    $('#modal-channel-user-revenueRate').modal('show');
                }
            }
        });
    });

    // onChange edit revenueRate
    $(document).on('change', '.channel-edit-revenueRate', function () {
        var $this = $(this);
        var id = $(this).attr('data-id');
        var rate = $(this).attr('data-rate');
        Swal.fire({
            title: 'Edit Revenue Rate?',
            text: 'Bạn muốn thay đổi doanh thu phân chia của kênh này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                // $this.closest('form').submit();
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/account-manager/revenueRate-user',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: $('input[name="id_user'+ id +'"]').val(),
                        rate: $('select[name="revenueRate'+ id +'"]').val(),
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                'Thay đổi doanh thu phân chia thành công.',
                                'success'
                            ).then((value) => {
                                // $(location).attr('href', BASE_URL + '/account-manager/user');
                            });
                        }
                    }
                });
            }else{
                $('select[name="revenueRate'+ id +'"]').val(rate)
            }
        });
    });


})(jQuery);
