(function ($) {
    const CancelToken = axios.CancelToken;
    let cancelComingSoonT;
    // Upload Trailer JS thuần sử dụng axios
    $(document).on('change', '.movie-coming-soon .box-upload-file--trailer .upload-video-step-1 .video_file',function (e) {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $('.box-upload-file--trailer .name--file').html($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]['name']);
        $this.attr('disabled', 'disabled');
        $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').addClass('uploading');
        $('.page-add-movie .upload-video-step-2 input[name=originUrl]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=trailerUrl]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=durationTrailer]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=nameFileTrailer]').val('');
        $('.page-add-movie .upload-video-step-2 input[name=uploadIdTrailer]').val('');

        // Get Duration Video
        let file = e.currentTarget.files[0];
        let obUrl = URL.createObjectURL(file);
        document.getElementById('audio').setAttribute('src', obUrl);
        console.log('file');

        let f_duration = 0;
        document.getElementById('audio').addEventListener('canplaythrough', function(f){
            f_duration = Math.round(f.currentTarget.duration);
            console.log(f_duration);
            $('.page-add-movie .upload-video-step-2 input[name=durationTrailer]').val(f_duration);
        });
        //End

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/movie-coming-soon/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').removeClass('uploading');
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if(res.success) {
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    console.log($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelComingSoonT = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').removeClass('uploading');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','100%');
                            $('.box-upload-file--trailer .progress .progress-bar').text('Done!');
                            $('.page-add-movie .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.page-add-movie .upload-video-step-2 input[name=originUrl]').val(item.data.url);
                            $('.page-add-movie .upload-video-step-2 input[name=trailerUrl]').val(item.data.url);
                            $('.page-add-movie .upload-video-step-2 input[name=nameFileTrailer]').val(item.data.fileName);
                            $('.page-add-movie .upload-video-step-2 input[name=uploadIdTrailer]').val(item.data.contentId);
                            $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', item.data.url);
                            $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'block');
                        }else {
                            $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').removeClass('uploading');
                            $this.removeAttr('disabled');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                            $('.box-upload-file--trailer .name--file').html('');
                            $('.box-upload-file--trailer form input').remove();
                            $('.box-upload-file--trailer form').html('<input name="file_trailer" class="video_file" type="file">');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                        $('.box-upload-file--trailer .name--file').html('')
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                        $('.box-upload-file--trailer form input[type="file"]').remove();
                        $('.box-upload-file--trailer form').append('<input name="file_trailer" class="video_file" type="file">');
                    });
                }
                else {
                    $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').removeClass('uploading');
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    $(document).on('click','.movie-coming-soon .box-upload-file--trailer .upload--cancel',function () {
        $('.footer .overlay').addClass('overlaing');
        let contentId = $('.movie-coming-soon .upload-video-step-2 input[name="uploadId"]').val();
        let fileName = $('.movie-coming-soon .upload-video-step-2 input[name="nameFile"]').val();
        console.log(contentId);
        console.log(fileName);
        if (fileName && fileName.length > 0){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/odd-movie/delete-file',{
                type: 'POST',
                dataType: 'json',
                data: {
                    contentId: contentId,
                    fileName: fileName,
                },
                success:function (data){
                    console.log(data);
                    $('.footer .overlay').removeClass('overlaing');
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        });
                    }else{
                        $('.box-upload-file--trailer form input[type="file"]').addClass('cancel');
                        cancelComingSoonT();
                        $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                        $('.box-upload-file--trailer .name--file').html('');
                        $('.box-upload-file--trailer form input[type="file"]').remove();
                        $('.box-upload-file--trailer form').append('<input name="file_video" class="video_file" type="file">');

                        $('.page-add-movie input[name=originUrl]').val('');
                        $('.page-add-movie input[name=trailerUrl]').val('');
                        $('.page-add-movie input[name=durationTrailer]').val('');
                        $('.page-add-movie input[name=nameFileTrailer]').val('');
                        $('.page-add-movie input[name=uploadIdTrailer]').val('');

                        $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', '');
                        $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'none');
                        $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').removeClass('uploading');
                        Swal.fire({
                            type: 'warning',
                            text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
                        });
                    }
                }
            });
        }else{
            $('.footer .overlay').removeClass('overlaing');
            $('.box-upload-file--trailer form input[type="file"]').addClass('cancel');
            cancelComingSoonT();
            $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
            $('.box-upload-file--trailer .progress .progress-bar').html('0%');
            $('.box-upload-file--trailer .name--file').html('');
            $('.box-upload-file--trailer form input[type="file"]').remove();
            $('.box-upload-file--trailer form').append('<input name="file_video" class="video_file" type="file">');

            $('.page-add-movie input[name=originUrl]').val('');
            $('.page-add-movie input[name=trailerUrl]').val('');
            $('.page-add-movie input[name=durationTrailer]').val('');
            $('.page-add-movie input[name=nameFileTrailer]').val('');
            $('.page-add-movie input[name=uploadIdTrailer]').val('');

            $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', '');
            $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'none');
            $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').removeClass('uploading');
            Swal.fire({
                type: 'warning',
                text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
            });
        }
    });

    // Delete file video uploaded when load
    window.onbeforeunload = function(){
        let hasClass = document.getElementsByClassName("unload--delMovie");
        if(hasClass.length){
            let contentId = $('.movie-coming-soon .upload-video-step-2 input[name="uploadIdTrailer"]').val();
            let fileName = $('.movie-coming-soon .upload-video-step-2 input[name="nameFileTrailer"]').val();
            console.log(contentId);
            if (fileName && fileName.length > 0){
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/odd-movie/delete-file',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        contentId: contentId,
                        fileName: fileName,
                    },
                    success:function (data){
                        console.log(data);
                        $('.footer .overlay').removeClass('overlaing');
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }else{
                            $('.box-upload-file--trailer form input[type="file"]').addClass('cancel');
                            cancelComingSoonT();
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                            $('.box-upload-file--trailer .name--file').html('');
                            $('.box-upload-file--trailer form input[type="file"]').remove();
                            $('.box-upload-file--trailer form').append('<input name="file_video" class="video_file" type="file">');

                            $('.page-add-movie input[name=originUrl]').val('');
                            $('.page-add-movie input[name=trailerUrl]').val('');
                            $('.page-add-movie input[name=durationTrailer]').val('');
                            $('.page-add-movie input[name=nameFileTrailer]').val('');
                            $('.page-add-movie input[name=uploadIdTrailer]').val('');

                            $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', '');
                            $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'none');
                            $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').removeClass('uploading');
                            Swal.fire({
                                type: 'warning',
                                text: 'Luồng upload Trailer đã bị hủy. Vui lòng tạo luồng mới!',
                            });
                        }
                    }
                });
            }
        }
    };

    $('.movie-coming-soon .form-create-movie-coming-soon').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('.btn-create-movie').removeAttr('disabled');
            $('.btn-edit-movie-coming-soon').removeAttr('disabled');
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                $('.movie-coming-soon .upload-video-step-2 input[name=originUrl]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name=trailerUrl]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name=durationTrailer]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name=nameFileTrailer]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name=uploadIdTrailer]').val('');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    //Create Movie Coming Soon
    $('.movie-coming-soon .btn-create-movie').click(function (e){
        e.preventDefault();
        let hClass = $('.movie-coming-soon .upload-video-step-2 .btn-create-movie').hasClass('uploading');
        if(!hClass){
            let date = $('.movie-coming-soon .upload-video-step-2 input[name="coming_soon"]').val();
            let now = new Date();
            let trailerUrl = $('.movie-coming-soon .upload-video-step-2 input[name="trailerUrl"]').val();
            if (trailerUrl != '')
            {
                if($('.form-create-movie-coming-soon').valid() == true) {
                    if (date)
                    {
                        let news = new Date(date);
                        if (now.getTime() < news.getTime())
                        {
                            $('.btn-create-movie').attr('disabled', 'disabled');
                            $('.form-create-movie-coming-soon').submit();
                        }else{
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Ngày ra mắt phải lớn hơn hiện tại.',
                            });
                        }
                    }
                }
            }else{
                $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                $('.box-upload-file--trailer .name--file').html('');
                $('.movie-coming-soon .upload-video-step-2 input[name="originUrl"]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name="trailerUrl"]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name="durationTrailer"]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name="nameFileTrailer"]').val('');
                $('.movie-coming-soon .upload-video-step-2 input[name="uploadIdTrailer"]').val('');
                // $('.box-upload-file--trailer form input[type="file"]').remove();
                // $('.box-upload-file--trailer form').append('<input name="file_trailer" class="video_file" type="file">');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: 'Hãy chọn tệp trailer.',
                });
            }
        }else{
            Swal.fire({
                type: 'warning',
                title: 'Warning',
                text: 'Đang upload file',
            });
        }
    });
    //Edit Movie Coming Soon
    $('.movie-coming-soon .btn-edit-movie-coming-soon').click(function (e){
        e.preventDefault();
        let $this = $(this);
        let date = $('.movie-coming-soon .upload-video-step-2 input[name="coming_soon"]').val();
        let now = new Date();
        if($('.form-create-movie-coming-soon').valid() == true) {
            if (date)
            {
                let news = new Date(date);
                if (now.getTime() < news.getTime())
                {
                    $this.attr('disabled', 'disabled');
                    $('.form-create-movie-coming-soon').submit();
                }else{
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: 'Ngày ra mắt phải lớn hơn hiện tại.',
                    });
                }
            }
        }
    });
})(jQuery);
