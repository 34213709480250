(function ($) {
    // Create role
    $('#create-role').click(function (e) {
        e.preventDefault();
        // Check name role
        if($('#name-role').val().length == 0)
        {
            $('.error-name-role').text('Bắt buộc nhập.!');
        }
        else
        {
            $('.error-name-role').text('');
        }

        if($('#name-role').val().length > 0)
        {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/account-manager/role/create',{
                type: 'POST',
                dataType: 'json',
                data: {
                    name_role: $('#name-role').val(),
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        })
                    }
                    else {
                        Swal.fire(
                            'Thành công!',
                            data.success,
                            'success'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL + '/account-manager/role');
                        });
                    }
                }
            });

        }
    });
    // Edite role
    $('#edit-role').click(function (e) {
        e.preventDefault();
        // Check name role
        if($('input[name="edit_nameRole"]').val().length == 0)
        {
            $('.error-name-role').text('Bắt buộc nhập.!');
        }
        else
        {
            $('.error-name-role').text('');
        }

        if($('input[name="edit_nameRole"]').val().length > 0)
        {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/account-manager/role/edit',{
                type: 'PUT',
                dataType: 'json',
                data: {
                    id_role: $('input[name="id_role"]').val(),
                    name_role: $('input[name="edit_nameRole"]').val(),
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: data.error,
                        })
                    }
                    else {
                        Swal.fire(
                            'Thành công!',
                            data.success,
                            'success'
                        ).then((value) => {
                            $(location).attr('href', BASE_URL + '/account-manager/role');
                        });
                    }
                }
            });

        }
    });
    $(document).on('click', '.edit_role', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var name = $(this).attr('data-name');

        $('input[name="id_role"]').val(id);
        $('input[name="edit_nameRole"]').val(name);

        $('#edit_role').modal('show');
    });
})(jQuery);
