(function ($) {
    //Create Odd Movie Copyright
    $('.add-odd-movie-copyright .btn-create-movie').click(function (e){
        e.preventDefault();
        let trailerUrl = $('.add-odd-movie-copyright .upload-video-step-2 input[name="trailerUrl"]').val();
        if (trailerUrl != '')
        {
            if($('.form-create-movie').valid() == true) {
                $('.btn-create-movie').attr('disabled', 'disabled');
                $('.form-create-movie').submit();
            }
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Trailer chưa được chọn hoặc Đang tải trailer.',
            });
        }
    });
    // Upload Trailer JS thuần sử dụng axios
    const CancelToken = axios.CancelToken;
    let cancelOddMovieT;
    $(document).on('change', '.add-odd-movie-copyright .box-upload-file--trailer .upload-video-step-1 .video_file',function () {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $('.box-upload-file--trailer .name--file').html($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]['name']);
        $this.attr('disabled', 'disabled');
        $('.page-add-movie input[name="trailerUrl"]').val('');
        $('.page-add-movie input[name="durationTrailer"]').val('');
        $('.page-add-movie input[name="nameFileTrailer"]').val('');
        $('.page-add-movie input[name="uploadIdTrailer"]').val('');
        // Get Duration Video
        let file = e.currentTarget.files[0];
        let obUrl = URL.createObjectURL(file);
        document.getElementById('audio').setAttribute('src', obUrl);
        console.log('file');

        let f_duration = 0;
        document.getElementById('audio').addEventListener('canplaythrough', function(f){
            f_duration = Math.round(f.currentTarget.duration);
            console.log(f_duration);
            $('.page-add-movie .upload-video-step-2 input[name=durationTrailer]').val(f_duration);
        });
        //End

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/getToken',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if(res.success) {
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    console.log($(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $(".box-upload-file--trailer .upload-video-step-1 .video_file")[0].files[0]);
                    axios.post( res.api_upload,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCSS = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                // console.log(percentCompletedCSS);
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompletedCSS+'%');
                                $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box-upload-file--trailer').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                // An executor function receives a cancel function as a parameter
                                cancelOddMovieT = c;
                                return false;
                            })
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            // $this.removeAttr('disabled');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','100%');
                            $('.box-upload-file--trailer .progress .progress-bar').text('Done!');
                            $('.page-add-movie .upload-video-step-2 .yc-btn--sm').addClass('upload-done');
                            $('.page-add-movie .upload-video-step-2 input[name=trailerUrl]').val(item.data.url);
                            $('.page-add-movie .upload-video-step-2 input[name=nameFileTrailer]').val(item.data.fileName);
                            $('.page-add-movie .upload-video-step-2 input[name=uploadIdTrailer]').val(item.data.contentId);
                            $('.box-upload-file--trailer .btn-upload-show-video').attr('data-originalUrl', item.data.url);
                            $('.box-upload-file--trailer .btn-upload-show-video').css('display', 'block');
                        }else {
                            $this.removeAttr('disabled');
                            $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                            $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                            $('.box-upload-file--trailer .name--file').html('');
                            $('.box-upload-file--trailer form input').remove();
                            $('.box-upload-file--trailer form').html('<input name="file_trailer" class="video_file" type="file">');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                        $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                        $('.box-upload-file--trailer .name--file').html('')
                        let hClass = $this.hasClass('cancel');
                        if(!hClass){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: 'Lỗi kết lối server!',
                            });
                            console.log('FAILURE!!');
                        }
                        $('.box-upload-file--trailer form input[type="file"]').remove();
                        $('.box-upload-file--trailer form').append('<input name="file_trailer" class="video_file" type="file">');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    $('.box-upload-file--trailer .progress .progress-bar').css('width','0%');
                    $('.box-upload-file--trailer .progress .progress-bar').html('0%');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
    // Upload Track JS thuần sử dụng axios
    $(document).on('change', '.add-odd-movie-copyright .box--track .upload-video-step-1 .video_file',function () {
        // $('.footer .overlay').addClass('overlaing');
        let $this = $(this);
        $this.attr('disabled', 'disabled');
        $this.parents('.box-upload-file--track').find('.name--file').html($this[0].files[0]['name']);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/odd-movie/getTokenTrack',{
            type: 'GET',
            dataType: 'json',
            data: {
            },
            success:function (res){
                console.log(res);
                $('.footer .overlay').removeClass('overlaing');
                if (res.error){
                    $this.removeAttr('disabled');
                    Swal.fire({
                        type: 'error',
                        title: 'Error',
                        text: res.error,
                    });
                }
                else if(res.success) {
                    let token = res.success;
                    $this.parents('.box-upload-file--track').find('.progress .progress-bar').css('width','0%');
                    console.log($this[0].files[0]);
                    let formData = new FormData();
                    formData.append("file", $this[0].files[0]);
                    axios.post( res.api_upload,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': token
                            },
                        }
                    ).then(function(data){
                        $('.footer .overlay').removeClass('overlaing');
                        console.log('SUCCESS!!');
                        console.log(data);
                        let item = data.data;
                        if (item.code == 200){
                            $this.parents('.box-upload-file--track').find('input[name="urlTrack"]').val(item.data);
                            $this.parents('.box-upload-file--track').find('.btn-add-track').attr('data-id', item.data);
                            $this.parents('.box-upload-file--track').find('.icon__delete_box_track_odd').attr('data-id', item.data);
                        }else {
                            $this.removeAttr('disabled');
                            $this.parents('.box-upload-file--track').find('.name--file').html('');
                            $this.parents('.box-upload-file--track').find('form input[type="file"]').val('');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: item.msg,
                            });
                        }
                    }).catch(function(){
                        $this.removeAttr('disabled');
                        $this.parents('.box-upload-file--track').find('.name--file').html('');
                        $this.parents('.box-upload-file--track').find('form input[type="file"]').remove();
                        $this.parents('.box-upload-file--track').find('form').append('<input name="file_track" class="video_file" type="file">');
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Lỗi kết lối server!',
                        });
                        console.log('FAILURE!!');
                    });
                }
                else {
                    $this.removeAttr('disabled');
                    $('.footer .overlay').removeClass('overlaing');
                    Swal.fire(
                        'Error!',
                        data.logged,
                        'error'
                    ).then((value) => {
                        $(location).attr('href', BASE_URL);
                    });
                }
            }
        });
    });
})(jQuery);
