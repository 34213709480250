(function ($) {
    $('#form-btn-edit-directors').click(function (e){
        e.preventDefault();
        if($('#form-edit-directors').valid() == true){
            $('#form-btn-edit-directors').attr('disabled', 'disabled');
            $('.footer .overlay').addClass('overlaing');
            $('#form-edit-directors').submit();
        }

    });
    //Create Directors
    $('#form-btn-add-directors').click(function (e){
        e.preventDefault();
        if($('#form-add-directors').valid() == true) {
            $('#form-btn-add-directors').attr('disabled', 'disabled');
            $('.footer .overlay').addClass('overlaing');
            $('#form-add-directors').submit();
        }
    });
    $('#form-add-directors').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-add-directors').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                document.getElementById('form-add-directors').reset();
                $('#addnew_directors').modal('hide');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $('#form-edit-directors').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            $('#form-btn-edit-directors').removeAttr('disabled');
            $('.footer .overlay').removeClass('overlaing');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                document.getElementById('form-edit-directors').reset();
                $('#edit_directors').modal('hide');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Click button edit
    $(document).on('click', '.btn-edit-directors', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let name = $(this).attr('data-name');
        let img = $(this).attr('data-img');
        let birthday = $(this).attr('data-birthday');
        let description = $(this).attr('data-description');
        let nation = $(this).attr('data-nation');
        let blood = $(this).attr('data-blood');
        let height = $(this).attr('data-height');
        let weight = $(this).attr('data-weight');

        $('input[name="id_directors"]').val(id);
        $('input[name="edit_directors_name"]').val(name);
        $('input[name="edit_directors_birth"]').val(birthday);
        $('select[name="edit_directors_nation"]').val(nation);
        $('input[name="edit_directors_blood"]').val(blood);
        $('input[name="edit_directors_height"]').val(height);
        $('input[name="edit_directors_weight"]').val(weight);
        $('textarea[name="edit_directors_story"]').html(description);
        $('.select-edit-nation .filter-option .filter-option-inner .filter-option-inner-inner').html(nation);
        $('.select-edit-nation .btn.dropdown-toggle.btn-light').removeClass('bs-placeholder');

        // let output_o = document.getElementById('edit-show-img-directors');
        // output_o.src = img;
        $('#edit_directors .thumb-upload').html('');
        $('#edit_directors .thumb-upload').html('<input name="edit_directors_img" type="file" class="video-thumb-upload" data-show-remove="true" data-default-file="' + img + '" data-allowed-file-extensions="png jpg gif tiff jpeg bmp">');
        $('.video-thumb-upload').dropify({
            messages: {
                'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                'remove':  'Hủy',
                'error':   'Xin lỗi có lỗi đã xảy ra'
            },
            error: {
                'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                'minWidth': 'The image width is too small ({{ value }}}px).',
                'maxWidth': 'The image width is too big ({{ value }}}px).',
                'minHeight': 'The image height is too small ({{ value }}}px).',
                'maxHeight': 'The image height is too big ({{ value }}px).',
                'imageFormat': 'Định dạng không đúng ({{ value }}).',
                'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
            },
        });

        $('#edit_directors').modal('show');
    });
    // Click input upload image edit
    $('#btn-upload-img-edit-directors').click(function (e) {
        e.preventDefault();
        $('#edit_img_directors').click();
    });
    // Show Image edit
    $('#edit_img_directors').change(function (e) {
        e.preventDefault();
        let output = document.getElementById('edit-show-img-directors');
        output.src = URL.createObjectURL(e.target.files[0]);
        $('#btn-upload-img-edit-directors').html(e.target.files[0].name);
        $('#edit-show-img-directors').css('display', 'block');
    });
    // Click btn xóa Country
    $(document).on('click', '.btn-delete-directors', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Delete Directors',
            text: 'Bạn muốn xóa đạo diễn này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/directors/delete',{
                    type: 'post',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            });
                        }
                        else {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                    }
                });
            }
        });
    });
})(jQuery);
