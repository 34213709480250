(function ($) {
    $('#btn-insert_config').click(function (e){
        e.preventDefault();
        if($('#form-create-config').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('#btn-insert_config').attr('disabled', 'disabled');
            $('#form-create-config').submit();
        }else{
            Swal.fire({
                type: 'warning',
                title: 'Warning',
                text: 'Hãy nhập đầy đủ thông tin',
            })
        }
    });
    $('#form-create-config').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            let href = window.location.href;
            $('.footer .overlay').removeClass('overlaing');
            $('#btn-insert_config').removeAttr('disabled', 'disabled');
            console.log(res);
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $('#edit_video').modal('hide');
                    $(location).attr('href', href);
                });
            }
            else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });

    $('#form-btn-edit-config').click(function (e){
        e.preventDefault();
        if($('#form-edit-config').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('#form-btn-edit-config').attr('disabled', 'disabled');
            $('#form-edit-config').submit();
        }else{
            Swal.fire({
                type: 'warning',
                title: 'Warning',
                text: 'Hãy nhập đầy đủ thông tin',
            })
        }
    });
    $('#form-edit-config').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            let href = window.location.href;
            $('.footer .overlay').removeClass('overlaing');
            $('#form-btn-edit-config').removeAttr('disabled');
            console.log(res);
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $('#edit_video').modal('hide');
                    $(location).attr('href', href);
                });
            }
            else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    $(document).on('click', '.btn-edit-config', function (e) {
        e.preventDefault();

        let id = $(this).attr('data-id');
        let version = $(this).attr('data-version');
        let configSignUp = $(this).attr('data-configSignUp');
        let comment = $(this).attr('data-comment');
        let upload = $(this).attr('data-upload');
        let livestream = $(this).attr('data-livestream');
        let newversionios = $(this).attr('data-newversionios');
        let newversionandroid = $(this).attr('data-newversionandroid');
        let isforceupdateios = $(this).attr('data-isforceupdateios');
        let isforceupdateandroid = $(this).attr('data-isforceupdateandroid');
        let channel = $(this).attr('data-channel');

        $('input[name="edit_configSignUp"]').prop("checked", false);
        $('input[name="edit_comment"]').prop("checked", false);
        $('input[name="edit_upload"]').prop("checked", false);
        $('input[name="edit_livestream"]').prop("checked", false);
        $('input[name="edit_isforceupdateios"]').prop("checked", false);
        $('input[name="edit_isforceupdateandroid"]').prop("checked", false);
        $('input[name="edit_channel"]').prop("checked", false);
        if(configSignUp == 'true'){
            $('#edit_configSignUp1').prop("checked", true);
        }else{
            $('#edit_configSignUp2').prop("checked", true);
        }
        if(comment == 'true'){
            $('#edit_comment1').prop("checked", true);
        }else{
            $('#edit_comment2').prop("checked", true);
        }
        if(upload == 'true'){
            $('#edit_upload1').prop("checked", true);
        }else{
            $('#edit_upload2').prop("checked", true);
        }
        if(livestream == 'true'){
            $('#edit_livestream1').prop("checked", true);
        }else{
            $('#edit_livestream2').prop("checked", true);
        }
        if(isforceupdateios == 'true'){
            $('#edit_isforceupdateios1').prop("checked", true);
        }else{
            $('#edit_isforceupdateios2').prop("checked", true);
        }
        if(isforceupdateandroid == 'true'){
            $('#edit_isforceupdateandroid1').prop("checked", true);
        }else{
            $('#edit_isforceupdateandroid2').prop("checked", true);
        }
        if(channel == 'true'){
            $('#edit_channel1').prop("checked", true);
        }else{
            $('#edit_channel2').prop("checked", true);
        }

        $('input[name="edit_id"]').val(id);
        $('input[name="edit_version"]').val(version);
        $('input[name="edit_newversionios"]').val(newversionios);
        $('input[name="edit_newversionandroid"]').val(newversionandroid);

        $('#edit_config').modal('show');
    });
})(jQuery);
