(function ($) {

    $('#form-edit-review').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            let href = window.location.href;
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
        }
    });
    // Click button edit
    $(document).on('click', '.btn-edit-review', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let titleEnglish = $(this).attr('data-titleEnglish');
        let sort = $(this).attr('data-sort');
        let free = $(this).attr('data-free');
        $('input[name="supperApp"]').prop("checked", false);
        if(free == 'true'){
            $('#supperApp1').prop("checked", true);
        } else{
            $('#supperApp2').prop("checked", true);
        }

        $('input[name="id_review"]').val(id);
        $('input[name="edit_review_title"]').val(title);
        $('input[name="edit_review_titleEnglish"]').val(titleEnglish);
        $('input[name="edit_review_sort"]').val(sort);

        $('#edit_review').modal('show');
    });

    // Approve checkbox video
    $(document).on('click', '.approve-review-movie', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Aprove Review',
            text: 'Bạn muốn phê duyệt review?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/approve',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Reject review movie
    $('#form-reject-review-movie').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-review-movie').removeAttr('disabled', 'disabled');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-review-movie').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
            else {
                $('.footer .overlay').removeClass('overlaing');
                $('.form-btn-reject-review-movie').removeAttr('disabled', 'disabled');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $(document).on('click', '.form-btn-reject-review-movie', function (e){
        e.preventDefault();
        if($('#form-reject-review-movie').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.form-btn-reject-review-movie').attr('disabled', 'disabled');
            $('#form-reject-review-movie').submit();
        }
    });
    // Click button reject video
    $(document).on('click', '.btn-reject-review-movie', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        $('#reject_video .modal-title').html('');

        $('#reject_video .modal-title').html(title);
        $('#reject_video input[name="id_video_reject"]').val(id);

        $('#reject_video').modal('show');
    });

    // Suggest Hidden Review Movie (Đề nghị ẩn)
    $(document).on('click', '.suggest-hide-review-movie', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Suggest hide Review',
            text: 'Bạn muốn đề nghị ẩn review?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/suggest-hide',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // CP đăng ký lại Review
    $(document).on('click', '.re-register-review-movie', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Re-Register Review',
            text: 'Bạn muốn đăng ký lại review?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/re-register',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Hidden Review
    $(document).on('click', '.hidden-review-movie', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Hidden Review',
            text: 'Bạn muốn ẩn review?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/hidden',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Show Review
    $(document).on('click', '.show-review-movie', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var href = window.location.href;

        Swal.fire({
            title: 'Show Review',
            text: 'Bạn muốn hiện review?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/video-manager/show',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });

///////////// User Review /////////////////
    // Approve checkbox video
    $(document).on('click', '.approve-user-review', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Duyệt',
            text: 'Bạn muốn phê duyệt user review?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/review/user/update',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        status: 0
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Hide checkbox video
    $(document).on('click', '.hidden-user-review', function (e) {
        e.preventDefault();
        var href = window.location.href;
        var id = $(this).attr('data-id');

        Swal.fire({
            title: 'Ẩn',
            text: 'Bạn muốn ẩn user review?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                $('.footer .overlay').addClass('overlaing');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/review/user/update',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        id: id,
                        status: 2
                    },
                    success:function (data){
                        console.log(data);
                        if (data.error){
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire({
                                type: 'error',
                                title: 'Error',
                                text: data.error,
                            })
                        }
                        else if (data.success) {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Thành công!',
                                data.success,
                                'success'
                            ).then((value) => {
                                $(location).attr('href', href);
                            });
                        }
                        else {
                            $('.footer .overlay').removeClass('overlaing');
                            Swal.fire(
                                'Error!',
                                data.logged,
                                'error'
                            ).then((value) => {
                                $(location).attr('href', BASE_URL);
                            });
                        }
                    }
                });
            }
        });
    });
    // Reject review movie
    $('#form-reject-user-review').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            var href = window.location.href;
            console.log(res);
            $('.footer .overlay').removeClass('overlaing');
            $('.form-btn-reject-user-review').removeAttr('disabled', 'disabled');
            if (res.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                })
            }
            else if (res.success) {
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }
            else {
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });
    $(document).on('click', '.form-btn-reject-user-review', function (e){
        e.preventDefault();
        if($('#form-reject-user-review').valid() == true) {
            $('.footer .overlay').addClass('overlaing');
            $('.form-btn-reject-user-review').attr('disabled', 'disabled');
            $('#form-reject-user-review').submit();
        }
    });
    // Click button reject video
    $(document).on('click', '.btn-reject-user-review', function (e) {
        e.preventDefault();
        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        $('#reject_user_review .modal-title').html('');

        $('#reject_user_review .modal-title').html(title);
        $('#reject_user_review input[name="id"]').val(id);

        $('#reject_user_review').modal('show');
    });
    // Supper App checkbox Review
    $(document).on('change', '#configSupperApp-checkbox-review', function (e) {
        e.preventDefault();
        let href = window.location.href;
        let value = $(this).val();
        let checkbox = [];
        $('input[name="checkbox_video[]"]:checked').each(function(){
            // checkbox = checkbox + ',' + $(this).val();
            checkbox.push($(this).val());
        });
        let title = '';
        let text = '';
        if (value == 2){
            title = 'Hiện trên supper app';
            text = 'Bạn muốn hiện các review trên supper app?';
        }else if (value == 3){
            title = 'Play trên supper app';
            text = 'Bạn muốn play các review trên supper app?';
        }else if (value == 4){
            title = 'Dừng phát trên supper app';
            text = 'Bạn muốn dừng phát các review trên supper app?';
        }else if (value == 5){
            title = 'Set New';
            text = 'Bạn muốn set new các review?';
        }else{
            title = 'Ẩn trên supper app';
            text = 'Bạn muốn ẩn các review trên supper app?';
        }

        if (checkbox.length)
        {
            Swal.fire({
                title: title,
                text: text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        }
                    });
                    $.ajax(BASE_URL + '/review/checkbox',{
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            value: value,
                            ids: checkbox,
                        },
                        success:function (data){
                            console.log(data);
                            if (data.error){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error',
                                    text: data.error,
                                })
                            }
                            else if (data.success) {
                                Swal.fire(
                                    'Thành công!',
                                    data.success,
                                    'success'
                                ).then((value) => {
                                    $(location).attr('href', href);
                                });
                            }
                            else {
                                $('.footer .overlay').removeClass('overlaing');
                                Swal.fire(
                                    'Error!',
                                    data.logged,
                                    'error'
                                ).then((value) => {
                                    $(location).attr('href', BASE_URL);
                                });
                            }
                        }
                    });
                }
            });
        }else{
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hãy chọn video!',
            });
        }
        $('select[name="configSupperApp-checkbox-review"]').selectpicker('val', '');
    });


    // Click button Play Supper App review
    $(document).on('click', '.btn-play-review-supperApp', function (e) {
        e.preventDefault();
        $('#review_play_supperApp .custom-control-input').prop("checked", false);

        let id = $(this).attr('data-id');
        let title = $(this).attr('data-title');
        let play = $(this).attr('data-play');
        let showSupperApp = $(this).attr('data-show-supper-app');

        //Play
        if (play == true){
            $('#review_play_supperApp #playSupperApp1').prop("checked", true);
        }else{
            $('#review_play_supperApp #playSupperApp2').prop("checked", true);
        }

        if (showSupperApp == true){
            $('#review_play_supperApp #showSupperApp1').prop("checked", true);
        }else{
            $('#review_play_supperApp #showSupperApp2').prop("checked", true);
        }

        $('#review_play_supperApp input[name="videoID"]').val(id);
        $('#review_play_supperApp .modal-title').html(title);

        $('#review_play_supperApp').modal('show');
    });
    $('.form-play-supper-app').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            let href = window.location.href;
            console.log(res);
            if (res.error){
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire({
                    type: 'error',
                    title: 'Error',
                    text: res.error,
                });
            }
            else if (res.success) {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Thành công!',
                    res.success,
                    'success'
                ).then((value) => {
                    $(location).attr('href', href);
                });
            }else {
                $('.footer .overlay').removeClass('overlaing');
                Swal.fire(
                    'Error!',
                    res.logged,
                    'error'
                ).then((value) => {
                    $(location).attr('href', BASE_URL);
                });
            }
        }
    });

})(jQuery);
